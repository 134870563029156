<template>
  <Modal :isOpen="isOpen">
    <div class="text-center">
      <div class="mb-4">
        <h3 class="mx-3 text-2xl">
          {{ String(exercice?.title || "") }}
        </h3>
      </div>
      <div class="flex flex-col gap-2">
        <button @click="$emit('pause')" class="btn btn-sm">
          Ralonger la pause
        </button>
        <button @click="jumpExercice" class="btn btn-warning btn-sm">
          Passer l'exercice
        </button>
        <button @click="quitPage" class="btn btn-warning btn-sm">
          Quitter la séance
        </button>
        <button @click="quitSequence" class="btn btn-error btn-sm">
          Abandonner la séance
        </button>
        <button @click="$emit('close')" class="btn btn-success btn-sm mt-4">
          Reprendre
        </button>
      </div>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import { getExerciceTrad } from "@/helpers/exercice";
import { getDoSet, rejectWorkout } from "@/services/api";
import { useSportStore } from "@/store";
import { useEventsStore } from "@/store/eventsStore";
import { useSetsStore } from "@/store/setsStore";
import { RouteName } from "@/types/route";
import { computed } from "vue";
import { useRouter } from "vue-router";
import type { Set } from "../../../../backend/src/types/Set";
import {
  EventName,
  EventStatus,
  type UserEvent,
} from "../../../../backend/src/types/UserEvent";
import Modal from "../ui/Modal.vue";

defineEmits(["pause", "close"]);
const props = defineProps<{ set: Set; isOpen: boolean }>();

const router = useRouter();
const sportStore = useSportStore();
const eventsStore = useEventsStore();
const setsStore = useSetsStore();
const doSet = getDoSet();

const exercice = computed(() => getExerciceTrad(props.set.exercice));

// Drop sequence
const quitSequence = async () => {
  const isConfirmed = confirm("Abandonner la séance");
  if (isConfirmed) {
    const { workout } = props.set;
    await rejectWorkout(workout, sportStore);
    await router.push({
      name: RouteName.SessionResults,
      params: {
        workout,
        // program: program,
        // sequence: sequence,
        // description:
      },
    });
  }
};

const jumpExercice = async () => {
  const isConfirmed = confirm("Passer l'exercice");
  if (isConfirmed) {
    const newSet = Object.assign({}, props.set) as Set;
    newSet.value = 0;
    newSet.time = 0;
    await doSet(newSet);
  }
};

const quitPage = async () => {
  const { workout } = props.set;
  const program =
    setsStore.workouts.find((wo) => wo.id === workout)?.program || 0;

  const event: UserEvent = {
    name: EventName.SequenceQuit,
    id: Math.round(Math.random() * Number.MAX_SAFE_INTEGER),
    data: {
      program,
    },
    status: EventStatus.New,
    date: new Date().toString(),
  };
  eventsStore.addEvent(event);
  await router.push({ name: RouteName.Home });
};
</script>

<style lang="scss" scoped></style>
