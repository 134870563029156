import { defineStore } from "pinia";

export type GlobalState = {
  serverTime: number;
  clientTime: number;
};

export const useGlobalStore = defineStore({
  // name of the store
  // it is used in devtools and allows restoring state
  id: "global",

  // a function that returns a fresh state
  state: (): GlobalState => ({
    serverTime: 0,
    clientTime: 0,
  }),

  // optional getters
  getters: {
    // current(this: GlobalStore) {
    //   if (this.currentID > -1) {
    //     return this.list.find(
    //       (event: UserEvent) => event.id === this.currentID
    //     );
    //   }
    //   return null;
    // },
  },
  actions: {
    setServerTime(timestamp: number) {
      this.$patch({
        serverTime: timestamp,
        clientTime: Date.now(),
      });
    },
  },
});
