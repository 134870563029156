import type { Equipment } from "../types/Equipment"
import type { Exercice } from "../types/Exercice"
import { ExerciceModel } from "../types/Exercice"
import { filterConditionByEquipments } from "./equipments"

const isExercice = (exercice: Exercice) => exercice.model !== ExerciceModel.None // && exercice.enable

export class TreeExercices {

  public variantGroups: Exercice[][][] = []

  private exercices: Exercice[]
  private userEquipment: { count: number; name: Equipment; }[]
  private banned: number[]

  constructor (exercices: Exercice[], userEquipment: { count: number; name: Equipment; }[] = [], banned: number[] = []) {
    this.exercices = exercices
      .filter(isExercice)

    this.userEquipment = userEquipment
    this.banned = banned

    this.updateTree()
  }

  getVariants () {
    return this.variantGroups.map(group => group.map(subgroup => subgroup.map(ex => ex.id)))
  }

  getVariantsByExercice (exercice: Exercice) {
    return this.variantGroups.find(varGroup => varGroup.find(seq => seq.find(ex => ex.id === exercice.id))) || [[]]
  }

  getFlatVariantsByExercice (exercice: Exercice) {
    const list = this.getVariantsByExercice(exercice).flat(2)
    list.sort((a, b) => a.difficulty - b.difficulty)
    return list
  }

  getNextExercice (exercice: Exercice): Exercice | null {
    const group = this.getFlatVariantsByExercice(exercice)
    const i = group.findIndex(({ id }) => id === exercice.id) 
    return group[i + 1] || null
  }

  private filterExercice (exercice: Exercice) {
    return filterConditionByEquipments(exercice.condition, this.userEquipment)
      && this.banned.indexOf(exercice.id) === -1
  }

  private static removeFromList (removed: Exercice[], list: Exercice[]) {
    removed.forEach(exercice => {
      const i = list.findIndex(ex => ex.id === exercice.id)
      if (i > -1) {
        list.splice(i, 1)
      }
    })
  }

  private static getSequence (exercice: Exercice, exercices: Exercice[]): Exercice[] {
    const harder = exercices.find(ex => ex.id === exercice.links?.find(harder => harder.replace)?.id)
    if (harder) {
      return [exercice, ...TreeExercices.getSequence(harder, exercices)]
    } else {
      return [exercice]
    }
  }

  private static extractVariantGroup (exercice: Exercice, exercices: Exercice[]) {
    if (exercice.variantsGroup) {
      const list = [exercice, ...exercices.filter(ex => ex.variantsGroup === exercice.variantsGroup)]
      TreeExercices.removeFromList(list, exercices)

      const variantGroup: Exercice[][] = []
      let ex: Exercice | undefined
      while (ex = list.shift()) {
        const sequence = TreeExercices.getSequence(ex, list)
        TreeExercices.removeFromList(sequence, exercices)
        TreeExercices.removeFromList(sequence, list)
        variantGroup.push(sequence)
      }
      return variantGroup
    } else {
      const sequence = TreeExercices.getSequence(exercice, exercices)
      TreeExercices.removeFromList(sequence, exercices)
      return [sequence]
    }
  }

  private updateTree () {
    const list = [...this.exercices]
    list.sort((a, b) => a.difficulty - b.difficulty)
  
    const variantGroups: Exercice[][][] = []
    let exercice: Exercice | undefined
    while (exercice = list.shift()) {
      variantGroups.push(TreeExercices.extractVariantGroup(exercice, list))
    }

    this.variantGroups = variantGroups
      .map(sequences => sequences.map(sequence => sequence.filter(this.filterExercice.bind(this))))
      // Remove empty groups
      .map(sequences => sequences.filter(sequence => sequence.length > 0))
      .filter(sequences => sequences.length > 0)
  }
}

export const getTree = (exercices: Exercice[], userEquipment: { count: number; name: Equipment; }[], banned: number[] ) => new TreeExercices(exercices, userEquipment, banned)
