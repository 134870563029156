import type { getNotifs } from "@/services/api";
import { NotifName } from "../../../../backend/src/types/Notif";
import type { ProgramId } from "../../../../backend/src/types/Program";
import type { User } from "../../../../backend/src/types/User";
import programs from "./programs";

const displayName = (user: User) =>
  `<strong>${user.nickname}${
    user.emoji
      ? ` <img src="/img/emojis/${user.emoji}" width="16" height="16" class="inline-block"/>`
      : ""
  }</strong>`;

export type NotifParams = {
  [NotifName.RankUp]: {
    name: NotifName.RankUp;
    user: { avatar: string; nickname: string; emoji: string };
    data: { rank: { from: number; to: number } };
    date: number;
  };
  [NotifName.SequenceEnded]: {
    name: NotifName.SequenceEnded;
    user: { avatar: string; nickname: string; emoji: string };
    data: { program: ProgramId };
    date: number;
  };
};

export type Notif<Name extends NotifName> = {
  name: Name;
  text: (options: NotifParams[Name]) => string;
};

// const a:Notif<NotifName.RankUp> = {
//   name: NotifName.RankUp,
//   text: (options) => JSON.stringify(options)
// }

export const notifs = [
  {
    name: NotifName.RankUp,
    text: (options) =>
      `${displayName(options.user)} est passé au niveau <strong>${
        options.data.rank.to
      }</strong>`,
  } as Notif<NotifName.RankUp>,
  {
    name: NotifName.SequenceEnded,
    text: (options) =>
      `${displayName(options.user)} a terminé une séance <strong>${
        programs.find((program) => program.id === options.data.program)?.title
      }</strong>`,
  } as Notif<NotifName.SequenceEnded>,
];

export const getNotifText = (
  src: Awaited<ReturnType<typeof getNotifs>>["notifs"][number]
) => {
  type Name = typeof src["name"];
  const notif = notifs.find((trad) => src.name === trad.name);
  if (notif) {
    return (notif as Notif<Name>).text(src as unknown as NotifParams[Name]);
  }
  return "";
};
