/*
  rank 1: 101 xp
  rank 2: 208 xp
  rank 3: 327 xp
  rank 4: 464 xp
  rank 5: 625 xp
  rank 6: 816 xp
  rank 7: 1043 xp
  rank 8: 1312 xp
*/

export const xpToRank = (xp: number) => 
  Math.round(((Math.sqrt(27*xp**2+4000000)/(2*3**(3/2))+xp/2)**(1/3)-100/(3*(Math.sqrt(27*xp**2+4000000)/(2*3**(3/2))+xp/2)**(1/3)))*1e14)/1e14
  // Math.log(xp / RANK_SLOW + 1) / Math.log(FIRST_RANK_XP / RANK_SLOW + 1)

// https://fr.numberempire.com/inversefunctioncalculator.php
export const rankToXp = (rank: number) =>
  rank * 100 + rank ** 3
  // (Math.exp(rank * Math.log(FIRST_RANK_XP / RANK_SLOW + 1)) - 1) * RANK_SLOW

