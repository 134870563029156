<template>
  <div class="avatar" :style="{ width: `${size}px`, height: `${size}px` }">
    <div>
      <img
        :src="`${avatar}`"
        :width="`${size}`"
        :height="`${size}`"
        :alt="alt"
        loading="lazy"
      />
      <!-- <span
        v-if="realRank"
        class="absolute bg-neutral-focus text-neutral-content rounded-3xl px-[0.5em] font-bold left-1/2 bottom-0 -translate-x-1/2 translate-y-1/2"
        :style="{ fontSize }"
      >{{ realRank }}</span> -->
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps({
  size: {
    type: Number,
    default: 60,
  },
  src: {
    type: String,
    required: true,
  },
  alt: {
    type: String,
    default: "",
  },
  rank: {
    type: Number,
    default: 0,
  },
});

const avatar = computed(() => `/img/avatars/${props.src || "default.svg"}`);
// const fontSize = computed(() => `${Math.min(14, Math.max(12, Math.round(props.size / 5)))}px`)
// const realRank = computed(() => Math.floor(props.rank))
</script>

<style lang="scss" scoped>
.avatar img {
  width: revert-layer;
  height: revert-layer;
}
</style>
