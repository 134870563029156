<template>
  <div class="card bg-base-300 text-base-content image-full border-2">
    <!-- Background -->
    <figure class="absolute top-0 left-0 w-full h-full">
      <div
        :style="{ backgroundImage: `url(${programTrad?.cover})` }"
        class="bg-cover bg-center top-0 left-0 w-full h-full absolute"
      ></div>
    </figure>

    <div
      class="card-body relative transition-opacity"
      :style="{ opacity: `${opacity}` }"
    >
      <!-- Title -->
      <h2 class="card-title block text-[#FFF]">
        <span class="first-letter:uppercase inline-block mr-2">{{
          programTrad?.title
        }}</span>

        <span v-if="reals.length" class="badge badge-info">
          {{ reals.length }}&nbsp;exercices
        </span>
        <!-- <span class="badge ml-2"> nX </span> -->
        <span v-if="duration >= 1" class="badge ml-2"
          >{{ Math.round(duration / 60) }}min</span
        >
      </h2>

      <!-- Paragraph -->
      <p class="mb-5">
        {{
          programTrad?.resume.replace(
            "{author}",
            workoutData?.author || "Un inconu"
          )
        }}
      </p>

      <!-- Data -->
      <ProgressStats
        v-if="displayValues"
        :stats="stats"
        class="w-full"
      ></ProgressStats>

      <!-- CTA -->
      <div class="card-actions justify-center">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import programsTrad from "../../trads/fr/programs";
import { ProgramId } from "../../../../backend/src/types/Program";
import { useSetsStore } from "@/store/setsStore";
import { getExercice } from "@/helpers/exercice";
import { ExerciceModel } from "../../../../backend/src/types/Exercice";
import ProgressStats from "../ui/ProgressStats.vue";
import { getProgramStats, getWorkoutDuration } from "@/helpers/workout";

const props = withDefaults(
  defineProps<{
    program: ProgramId;
    workout: number;
    opacity?: number;
  }>(),
  {
    opacity: 1,
  }
);

const setsStore = useSetsStore();
const sets = computed(
  () =>
    setsStore.setsByProgramsWorkouts
      .find((d) => d.program === props.program)
      ?.workouts.find((workout) => workout.workout === props.workout)?.sets ||
    []
);

const stats = computed(() => getProgramStats(sets.value));

const duration = computed(() => getWorkoutDuration(sets.value));

const reals = computed(() =>
  sets.value.filter(
    (set) => getExercice(set.exercice)?.model !== ExerciceModel.None
  )
);
const programTrad = computed(() =>
  programsTrad.find((p) => p.id === props.program)
);
const workoutData = computed(() =>
  setsStore.workouts.find((wo) => wo.id === props.workout)
);
const displayValues = computed(
  () => props.program !== ProgramId.Custom
  // programTrad.value && (programTrad.value.strength + programTrad.value.cardio + programTrad.value.duration + programTrad.value.flexibility) > -4
);
</script>

<style lang="scss" scoped></style>
