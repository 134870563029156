import beepCountdown from "../assets/sounds/beeps/tri-tone-up-beep.mp3";
import beep1 from "../assets/sounds/beeps/tone-beep.mp3";
import beep2 from "../assets/sounds/beeps/tone-beep-lower-slower.mp3";
import beepStart from "../assets/sounds/win/mixkit-fantasy-game-success-notification-270.mp3";
import win from "../assets/sounds/win/mixkit-achievement-bell-600.mp3";
import silence from "../assets/sounds/silence.mp3";

const IS_IOS = /iPad|iPhone|iPod/.test(navigator.platform);

export const enum SoundName {
  BeepCountdown,
  Beep1,
  Beep2,
  BeepStart,
  Win,
  Silence,
}

const audioSrcList = {
  [SoundName.BeepCountdown]: beepCountdown,
  [SoundName.Beep1]: beep1,
  [SoundName.Beep2]: beep2,
  [SoundName.BeepStart]: beepStart,
  [SoundName.Win]: win,
  [SoundName.Silence]: silence,
} as { [key in SoundName]: string };

class AudioManager {
  context = new AudioContext();
  list: { [key in SoundName]?: AudioBuffer } = {};

  constructor() {
    this.init();
  }

  async init() {
    for (const name in audioSrcList) {
      const key = name as unknown as SoundName;
      const src = audioSrcList[key];
      const response = await fetch(src);
      const buffer = await response.arrayBuffer();
      this.context.decodeAudioData(
        buffer,
        (audioBuffer) => {
          if (buffer) {
            this.list[key] = audioBuffer;
          }
        },
        (error) => console.error(error)
      );
    }
  }

  play(name: SoundName) {
    const src = this.context.createBufferSource();
    src.buffer = this.list[name] || null;
    src.connect(this.context.destination);
    src.start(0);
  }
}

const audioManager = new AudioManager();

if (IS_IOS) {
  // https://curtisrobinson.medium.com/how-to-auto-play-audio-in-safari-with-javascript-21d50b0a2765
  const playSilence = () => {
    audioManager.play(SoundName.Silence);
    document.body.removeEventListener("touchstart", playSilence, false);
  };
  document.body.addEventListener("touchstart", playSilence, false);
}

export default audioManager;
