import { defineStore } from "pinia";
import type { Lootbox } from "../../../backend/src/types/Lootbox";

export const useLootboxesStore = defineStore({
  // name of the store
  // it is used in devtools and allows restoring state
  id: "lootboxes",

  // a function that returns a fresh state
  state: (): { list: Lootbox[] } => ({
    list: [],
  }),

  // optional getters
  getters: {
    // displayed(this): UserEvent | null {
    //   return (
    //     this.list.filter((event) => event.status === EventStatus.New)[0] || null
    //   );
    // },
  },
});
