import type { Ref } from "vue";
import emitter from "../services/emitter";

export default async <Payload>(
  promise: Promise<Payload>,
  {
    isLoading,
    isFail,
    isSuccess,
    isEnded,
  }: {
    isLoading?: Ref<boolean>;
    isFail?: Ref<boolean>;
    isSuccess?: Ref<boolean>;
    isEnded?: Ref<boolean>;
  } = {}
) => {
  const setRefs = (success: boolean) => {
    if (isSuccess) {
      isSuccess.value = success;
    }
    if (isFail) {
      isFail.value = !success;
    }
    if (isLoading) {
      isLoading.value = false;
    }
    if (isEnded) {
      isEnded.value = true;
    }
    emitter.emit("loading:stop");
  };

  if (isLoading) {
    isLoading.value = true;
  }
  if (isEnded) {
    isEnded.value = false;
  }
  emitter.emit("loading:start");

  try {
    const payload = await promise;
    setRefs(true);
    return payload;
  } catch (error: unknown) {
    setRefs(false);
    throw error;
  }
};
