import type { Avatar } from "./Avatar";
import type { Emoji } from "./Emoji";
import type { Exercice } from "./Exercice";
import type { ProgramId } from "./Program";
import type { Set } from "./Set";

export enum EventInterval {
  Once,
  OncePerDay,
  OncePerWeek,
  None
}

export type EventRow = {
  ev_id?: number;
  us_id: number;
  ev_name: EventName;
  ev_data: unknown;
  ev_status: EventStatus;
  ev_created_at: string;
};

export const enum EventStatus {
  New = "NEW",
  Seen = "SEEN",
  Done = "DONE",
}

export const enum EventName {
  UnlockHarder = "unlock-harder",
  UnlockEndSeries = "unlock-end-series",
  UnlockBonus = "unlock-bonus",
  UnlockChallengeProgram = "unlock-challenge-program",
  FirstDisplayBonus = "first-display-bonus",
  FirstDisplayHome1 = "first-display-home-1",
  FirstDisplayHome2 = "first-display-home-2",
  FirstProgramSubscribed = "first-program-subscribed",
  SequenceOvertime = "sequence-overtime",
  SequenceQuit = "sequence-quit",
  AvatarRevealed = "avatar-revelead",
  EmojiRevealed = "emoji-revelead",
}

type UserEventBase = {
  id: number;
  status: EventStatus;
  date: string;
}

export type UserEvents = {
  [EventName.AvatarRevealed]: UserEventBase & {
    name: EventName.AvatarRevealed;
    data: { avatar: Avatar };
  },
  [EventName.AvatarRevealed]: UserEventBase & {
    name: EventName.AvatarRevealed;
    data: { avatar: Avatar };
  },
  [EventName.EmojiRevealed]: UserEventBase & {
    name: EventName.EmojiRevealed;
    data: { emoji: Emoji };
  },
  [EventName.FirstDisplayBonus]: UserEventBase & {
    name: EventName.FirstDisplayBonus;
    data: { set: Set };
  },
  [EventName.FirstDisplayHome1]: UserEventBase & {
    name: EventName.FirstDisplayHome1;
    data: { };
  },
  [EventName.FirstDisplayHome2]: UserEventBase & {
    name: EventName.FirstDisplayHome2;
    data: { };
  },
  [EventName.FirstProgramSubscribed]: UserEventBase & {
    name: EventName.FirstProgramSubscribed;
    data: { };
  },
  [EventName.SequenceOvertime]: UserEventBase & {
    name: EventName.SequenceOvertime;
    data: { };
  },
  [EventName.SequenceQuit]: UserEventBase & {
    name: EventName.SequenceQuit;
    data: { program: ProgramId };
  },
  [EventName.UnlockBonus]: UserEventBase & {
    name: EventName.UnlockBonus;
    data: { exercice: Exercice };
  },
  [EventName.UnlockEndSeries]: UserEventBase & {
    name: EventName.UnlockEndSeries;
    data: { exercice: Exercice };
  },
  [EventName.UnlockHarder]: UserEventBase & {
    name: EventName.UnlockHarder;
    data: { exercice: Exercice };
  },
  [EventName.UnlockChallengeProgram]: UserEventBase & {
    name: EventName.UnlockChallengeProgram;
    data: { };
  }
}

export type UserEvent = UserEvents[keyof UserEvents]
