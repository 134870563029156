<template>
  <div
    v-if="/* offlineReady || */ needRefresh"
    class="fixed top-0 left-0 right-0 bottom-0 p-3 bg-neutral-content text-center flex flex-col items-center justify-center z-50"
  >
    <div class="max-w-sm my-5 text-neutral">
      <span v-if="offlineReady"> L'application est téléchargée </span>
      <span v-else>
        Du nouveau contenu est disponible, clique sur le bouton ci-dessous pour
        actualiser.
      </span>
    </div>
    <button v-if="needRefresh" @click="update()" class="btn">
      Mettre à jour
    </button>
    <!-- <button @click="close" class="cta">
      Continuer
    </button> -->
  </div>
</template>

<script lang="ts" setup>
// eslint-ignore-next-line
import { useRegisterSW } from "virtual:pwa-register/vue";

const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW();

const update = async () => {
  await updateServiceWorker(true);
};
</script>

<style lang="scss" scoped></style>
