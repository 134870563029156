class InsomniacScreen {
  private wakeLock: { release: () => void } | null = null;

  normalMode() {
    if (this.wakeLock) {
      this.wakeLock.release();
      this.wakeLock = null;
      document.removeEventListener("visibilitychange", this.onVisibilityChange);
    }
  }

  async awakeMode() {
    const wl = (
      navigator as unknown as {
        wakeLock?: { request: () => Promise<{ release: () => void }> };
      }
    ).wakeLock;
    if (wl) {
      try {
        this.normalMode();
        this.wakeLock = await wl.request();
        document.addEventListener("visibilitychange", this.onVisibilityChange);

        // eslint-disable-next-line no-empty
      } catch (err) {}
    }
  }

  private async onVisibilityChange() {
    if (this.wakeLock !== null && document.visibilityState === "visible") {
      await this.awakeMode();
    }
  }
}

const insomniacScreen = new InsomniacScreen();

export default insomniacScreen;
