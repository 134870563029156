<template>
  <main class="flex flex-col justify-around items-center min-h-screen">
    <Logo></Logo>

    <form
      @submit="
        (event) => {
          submit();
          event.preventDefault();
        }
      "
      class="card flex-shrink-0 w-full max-w-sm"
    >
      <div class="card-body">
        <div class="form-control">
          <label class="label">
            <span class="label-text">Email</span>
          </label>
          <input
            v-model="email"
            type="email"
            placeholder="email"
            class="input input-bordered"
          />
        </div>
        <div class="form-control">
          <label class="label">
            <span class="label-text">Mot de passe</span>
          </label>
          <input
            v-model="password"
            type="password"
            placeholder="mote de passe"
            class="input input-bordered"
          />
          <!-- <label class="label">
            <a href="#" class="label-text-alt link link-hover">Forgot password?</a>
          </label> -->
        </div>
        <div v-if="error" class="form-control">
          <p class="label-text-alt text-error text-center">
            {{ error }}
          </p>
        </div>
        <div class="form-control mt-6">
          <button class="btn btn-primary">Connexion</button>
        </div>
      </div>
    </form>
  </main>
</template>

<script lang="ts" setup>
import { useSportStore } from "../store";
import { ref, watch } from "vue";
import { getLogin } from "../services/api";
import { useRouter } from "vue-router";
import Logo from "../components/ui/Logo.vue";
import { RouteName } from "../types/route";

const router = useRouter();

const email = ref("");
const password = ref("");
const error = ref("");

const store = useSportStore();
const login = getLogin();

watch(email, () => (error.value = ""));
watch(password, () => (error.value = ""));

watch(
  store.me,
  () => {
    if (store.me.email) {
      router.push({ name: RouteName.Home });
    }
  },
  { immediate: true, deep: true }
);

const submit = () => {
  login(email.value, password.value).then(({ response }) => {
    if (response.status === 401) {
      error.value = "Vos identifiants ne sont pas reconnus";
    } else if (response.status !== 200) {
      error.value =
        "Une erreur est survenue, veuillez rééssayer ultérieurement";
    }
  });
};
</script>

<style lang="scss" scoped></style>
