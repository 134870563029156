import { getExerciceFull } from "@/helpers/exercice";
import type { ExerciceTrad } from "@/trads/fr/exercices";
import { defineStore } from "pinia";
import type {
  ExerciceId,
  ExerciceSmall,
} from "../../../backend/src/types/Exercice";

export type ExercicesState = {
  all: Array<ExerciceSmall>;
  list: Array<number>;
};
// export type CardsStore = Store<"exercices", ExercicesState, any, { update: () => Promise<Exercice[]> }>;

export const useExercicesStore = defineStore<
  "exercices",
  { list: ExerciceId[] },
  { fullExercices: () => (ExerciceTrad & ExerciceSmall)[] },
  Record<string, never>
>({
  // name of the store
  // it is used in devtools and allows restoring state
  id: "exercices",

  // a function that returns a fresh state
  state: () =>
    ({
      // all: exercices as ExerciceSmall[],
      list: [],
    } as { list: Array<number> }),

  // optional getters
  getters: {
    fullExercices(this) {
      const list = this.list
        .map(getExerciceFull)
        .filter((ex) => ex !== undefined) as (ExerciceTrad & ExerciceSmall)[];

      list.sort((a, b) => a.title.localeCompare(b.title));

      return list;
    },
    // // use getters in other getters
    // doubleCountPlusOne() {
    //   return this.doubleCount * 2 + 1
    // },
  },

  // optional actions
  actions: {
    // async update(force = false): Promise<ExerciceSmall[]> {
    //   const response = await fetch(API_URL + `/exercices/public`, setOptions());
    //   authHandler(response);
    //   const payload = await response.json();
    //   const exercices: ExerciceSmall[] = payload.exercices || [];
    //   exercices.sort((a: ExerciceSmall, b: ExerciceSmall) =>
    //     a.name.localeCompare(b.name, "en", { sensitivity: "base" })
    //   );
    //   this.all = exercices.map((ex: ExerciceSmall) => ({
    //     ...ex,
    //     level: Math.round(ex.level || 0) || 1,
    //   }));
    //   if (force) {
    //     window.location.reload();
    //   }
    //   return exercices;
    // },
    // reset() {
    //   // `this` is the store instance
    //   this.counter = 0
    // },
  },
});
