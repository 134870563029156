<template>
  <div class="flex flex-col">
    <div class="flex items-end mb-1">
      <p v-if="Math.floor(rank)" class="text-sm">{{ Math.floor(rank) }}</p>
      <p class="mx-auto font-black">Niveau {{ Math.floor(rank) }}</p>
      <p class="text-sm">{{ Math.floor(rank) + 1 }}</p>
    </div>
    <progress
      class="progress progress-secondary w-full"
      :value="(rank % 1) * 10000"
      :max="10000"
    ></progress>
    <div class="flex mt-1 items-start">
      <p class="flex-1"></p>
      <p class="mx-auto mb-5 opacity-70">
        {{ Math.floor(realXp) }} <small>XP</small>
        <span v-if="from !== -1" class="ml-2 badge badge-success">
          +{{ Math.floor(realXp - from) }} <small>XP</small>
        </span>
      </p>
      <p class="flex-1 text-right opacity-40 text-sm">
        {{ xpInRank }}/{{ rankXp }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onUnmounted, ref, watch } from "vue";
import { rankToXp, xpToRank } from "../../../../backend/src/helpers/user";

const props = defineProps({
  from: {
    type: Number,
    default: -1,
  },
  experience: {
    type: Number,
    default: 0,
  },
});

let rafID = 0;
let startTime = 0;
const TIME = 1000;

const realXp = ref(props.from || props.experience);
const rank = computed(() => xpToRank(realXp.value));
const xpInRank = computed(() =>
  Math.floor(realXp.value - rankToXp(Math.floor(rank.value)))
);
const rankXp = computed(() =>
  Math.ceil(
    rankToXp(Math.floor(rank.value) + 1) - rankToXp(Math.floor(rank.value))
  )
);

const update = () => {
  const now = Date.now();
  const time = now - startTime;
  cancelAnimationFrame(rafID);

  if (time < TIME) {
    const x = time / TIME;
    const s = Math.cos(x * Math.PI + Math.PI) / 2 + 0.5;
    realXp.value = s * (props.experience - props.from) + props.from;
    rafID = requestAnimationFrame(update);
  } else {
    realXp.value = props.experience;
  }
};

watch(
  () => `${props.from}-${props.experience}`,
  () => {
    if (
      props.from > -1 &&
      props.from !== props.experience &&
      Math.floor(props.from) < Math.floor(props.experience)
    ) {
      startTime = Date.now();
      update();
    } else {
      realXp.value = props.experience;
    }
  },
  { immediate: true }
);

onUnmounted(() => {
  cancelAnimationFrame(rafID);
});
</script>

<style lang="scss" scoped></style>
