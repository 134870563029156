<template>
  <tr ref="line">
    <td class="whitespace-normal appear-1">
      <!-- https://www.google.com/imgres?imgurl=https%3A%2F%2Finterfaceingame.com%2Fwp-content%2Fuploads%2Foverwatch%2Foverwatch-gold-medal-500x281.jpg&imgrefurl=https%3A%2F%2Finterfaceingame.com%2Fgames%2Foverwatch%2F&tbnid=k-f-c5FfsvlPMM&vet=12ahUKEwjMl8yUqev1AhUGaRoKHaHtD8EQMygBegUIARC-AQ..i&docid=9diHy_rPH6SWeM&w=500&h=281&q=overwatch%20ui%20win&ved=2ahUKEwjMl8yUqev1AhUGaRoKHaHtD8EQMygBegUIARC-AQ -->

      <!-- Main content -->
      <div class="flex flex-col md:flex-row appear-2">
        <div
          class="flex sm:flex-row sm:items-center space-x-4 mr-auto items-center"
        >
          <!-- Avatar (desktop) -->
          <div class="block">
            <div class="avatar">
              <div class="w-20 h-20">
                <img :src="trad?.cover" class="mask mask-hexagon" />
              </div>
            </div>
          </div>

          <!-- Main text -->
          <div class="block sm:flex">
            <div>
              <h2 class="card-title flex-col items-start leading-6">
                <!-- Title -->
                <span class="text-2xl leading-6">
                  {{ trad?.title }}
                  <em class="font-medium opacity-70">{{ afterTitle }}</em>
                </span>
                <span class="flex gap-2">
                  <!-- Tags -->
                  <Level class="level" :value="exercice?.level" />
                  <span class="badge">
                    {{ type }}
                  </span>
                </span>
              </h2>
              <!-- <p class="text-base-content text-opacity-40">
                {{ exerciceText.resume }}
              </p> -->
            </div>
          </div>
        </div>

        <div class="flex flex-1 justify-end items-center gap-5">
          <!-- Reps -->
          <div>
            <div
              class="badge badge-secondary badge-lg text-lg whitespace-nowrap"
              v-html="valueStr"
            ></div>
          </div>

          <!-- Score -->
          <div class="text-4xl font-black">
            {{ bonus }}<small class="text-sm">PTS</small>
          </div>

          <!-- Stars -->
          <div class="relative box-border">
            <div class="relative w-[30px] h-[30px]">
              <transition name="star-appear">
                <span v-if="displays[0]" class="absolute">
                  <BonusStar :bonus="Bonus.Min" width="30px" height="30px" />
                </span>
              </transition>
              <transition name="star-appear">
                <span
                  v-if="displays[1]"
                  class="absolute top-[-1px] left-[-1px]"
                >
                  <BonusStar :bonus="Bonus.Goal" width="32px" height="32px" />
                </span>
              </transition>
              <transition name="star-appear">
                <span
                  v-if="displays[2]"
                  class="absolute top-[-2px] left-[-2px]"
                >
                  <BonusStar :bonus="Bonus.Great" width="34px" height="34px" />
                </span>
              </transition>
              <transition name="star-appear">
                <span v-if="displays[3]" class="absolute mt-[-3px] ml-[-3px]">
                  <BonusStar
                    :bonus="Bonus.Impressive"
                    width="36px"
                    height="36px"
                  />
                </span>
              </transition>
            </div>
          </div>
        </div>
      </div>

      <!-- Unlock progress -->
      <div
        v-if="unlockGoal !== undefined"
        class="h-20 p-4 flex flex-col justify-center relative appear-3"
      >
        <div
          class="unlock-appear absolute top-0 left-0 h-full w-full flex justify-center items-center opacity-0"
          :class="{ 'opacity-100': value >= unlockGoal && !unlockSuccess }"
        >
          <h3 class="font-bold text-center ml-4 relative">
            <span class="block leading-3 opacity-70 text-sm"
              >Prochain exercice déjà débloqué</span
            >
          </h3>
        </div>

        <div
          class="unlock-appear absolute top-0 left-0 h-full w-full opacity-0 flex flex-col justify-center items-center"
          :class="{
            'opacity-100':
              value * unlockProgress < unlockGoal &&
              !(value >= unlockGoal && !unlockSuccess),
          }"
        >
          <h3 class="font-bold mt-2 leading-3 text-center opacity-50 text-xs">
            Déblocage du prochain exercice
          </h3>
          <div class="flex items-center w-11/12">
            <ProgressBar
              :value="Math.min(unlockGoal, value * unlockProgress)"
              :max="unlockGoal"
              color="#009485"
              class="h-5 flex-1 rounded-r-none"
            >
              <!-- <span class="text-[white] uppercase ml-2 font-bold text-xs md:text-base">Déblocage du prochaine exercice</span> -->
            </ProgressBar>
            <div
              class="bg-success w-10 h-10 flex justify-center items-center relative rounded-box"
            >
              <Lock color="white" class="w-6 h-6" />
            </div>
          </div>
        </div>

        <div
          class="unlock-appear absolute top-0 left-0 h-full w-full flex justify-center items-center opacity-0"
          :class="{
            'opacity-100':
              value * unlockProgress >= unlockGoal && unlockSuccess,
          }"
        >
          <!-- <div
            class="absolute top-0 left-0 w-full h-full overflow-hidden opacity-40"
          >
            <img
              :src="tradUnlocked?.cover"
              class="object-cover w-full h-full"
            />
          </div> -->

          <h3 class="font-bold text-center ml-4 relative">
            <strong class="text-success leading-5 uppercase font-black">{{
              tradUnlocked?.title
            }}</strong>
            <span class="block leading-3 opacity-70 text-sm">Débloqué !</span>
          </h3>

          <!-- Avatar (desktop) -->
          <div class="block ml-8">
            <div class="avatar">
              <div class="w-20 h-20">
                <img :src="tradUnlocked?.cover" class="mask mask-hexagon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script lang="ts" setup>
import type { Set } from "../../../../backend/src/types/Set";
import { SetData } from "../../../../backend/src/types/Set";
import {
  Bonus,
  BonusMultiplicators,
} from "../../../../backend/src/helpers/bonus";
import type { PropType } from "vue";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import BonusStar from "./BonusStar.vue";
import exerciceTexts from "../../trads/fr/exercices";
import Lock from "../icons/Lock.vue";
import { isIsometric } from "../../../../backend/src/helpers/exercices";
import { getChronoStringUnit } from "../../helpers/time";
import {
  ExerciceSubject,
  type ExerciceSmall,
} from "../../../../backend/src/types/Exercice";
import { useTween } from "../../use/useTween";
import ProgressBar from "../ProgressBar.vue";
import Level from "../exercices/Level.vue";
import { exerciceAlterTrad } from "../../trads/fr/exerciceSubjectsAlters";
import { getExercice } from "@/helpers/exercice";

const rythm = 0.25;

const props = defineProps({
  set: {
    type: Object as PropType<Set>,
    default() {
      return [];
    },
  },
  unlockGoal: {
    type: Number,
    required: false,
  },
  unlockSuccess: {
    type: Boolean,
    default: false,
  },
  unlockExercice: {
    type: Number,
    required: false,
  },
  display: {
    type: Boolean,
    default: true,
  },
});

const line = ref<HTMLTableRowElement | null>(null);

const emit = defineEmits(["onReady"]);

const exercice = computed<ExerciceSmall | null>(() => {
  return getExercice(props.set.exercice) || null;
});

const trad = computed(() => {
  return exerciceTexts.find((text) => text.id === props.set.exercice) || null;
});

const tradUnlocked = computed(() => {
  return exerciceTexts.find((text) => text.id === props.unlockExercice) || null;
});

const bonus = computed(() => {
  return props.set.bonus;
});

const afterTitle = computed(() => {
  switch (props.set.data) {
    case SetData.Left:
      return " (gauche)";
    case SetData.Right:
      return " (droite)";
  }
  return "";
});

const value = computed(() => {
  if (exercice.value !== null && isIsometric(exercice.value)) {
    return props.set.time;
  }

  return props.set.value;
});

const valueStr = computed(() => {
  if (exercice.value !== null && isIsometric(exercice.value)) {
    return getChronoStringUnit(props.set.time * 1000);
  }

  return `${props.set.value || 0}&nbsp;reps`;
});

// const max = computed(() => Math.max( value.value * 1.1, props.set.goal * BonusMultiplicators.Goal * 1.1, (props.unlockGoal || 0) * 1.1 ))

const displayPoints = computed(() => value.value > 0);
const displayGoal = computed(
  () => value.value >= props.set.goal * BonusMultiplicators.Goal
);
const displayImpressive = computed(
  () => value.value >= props.set.goal * BonusMultiplicators.Impressive
);
const displayGreat = computed(
  () => value.value >= props.set.goal * BonusMultiplicators.Great
);

const timeMultiplicator = computed(() => {
  if (displayImpressive.value) {
    return 5;
  } else if (displayGreat.value) {
    return 4;
  } else if (displayGoal.value) {
    return 3;
  } else if (displayPoints.value) {
    return 2;
  } else {
    return 1;
  }
});

const timings = ref([false, false, false, false]);

const startAnimations = () => {
  if (props.display) {
    nextTick(() => {
      timings.value[0] = true;
      setTimeout(() => (timings.value[1] = true), rythm * 1000);
      setTimeout(() => (timings.value[2] = true), rythm * 2000);
      setTimeout(() => (timings.value[3] = true), rythm * 3000);
    });
  }
};

onMounted(() => {
  // requestAnimationFrame(() => startAnimations())
  line.value?.scrollIntoView({ behavior: "smooth" });
});

const displays = computed(() => [
  displayPoints.value &&
    timings.value[0] &&
    !(displayGoal.value && timings.value[1]),
  displayGoal.value &&
    timings.value[1] &&
    !(displayGreat.value && timings.value[1]),
  displayGreat.value &&
    timings.value[1] &&
    !(displayImpressive.value && timings.value[1]),
  displayImpressive.value && timings.value[1],
]);

const {
  start: startTween,
  stop: stopTween,
  value: unlockProgress,
} = useTween({
  onFinish: () => emit("onReady"),
  duration: rythm * timeMultiplicator.value * 1000,
});

watch(
  () => props.display,
  (display) => {
    stopTween();
    if (display) {
      startTween();
      startAnimations();
    }
  },
  { immediate: true }
);

const type = computed(() => {
  const type = exercice.value?.infos?.find(
    (info) => info.subject === ExerciceSubject.MuscleGlobal
  )?.alter;
  if (type) {
    return exerciceAlterTrad[type];
  }

  return "";
});
</script>

<style lang="scss" scoped>
.appear-1 {
  animation: 0.5s cubic-bezier(0.07, 0.2, 0.17, 0.99) appear-1;
}
.appear-2 {
  animation: 0.75s cubic-bezier(0.07, 0.2, 0.17, 0.99) appear-2;
}
.appear-3 {
  animation: 1s cubic-bezier(0.07, 0.2, 0.17, 0.99) appear-3;
}

.star-rotate {
  animation: star-rotate 30s linear infinite;
}

.bg-gradient-radial {
  background-image: radial-gradient(circle at 50% 25%, white, deeppink);
}

.unlock-win {
  filter: drop-shadow(0 0 8px white) drop-shadow(0 0 16px gold);
}

@keyframes star-rotate {
  from {
    transform: translateX(-50%) translateY(-33%);
  }
  to {
    transform: translateX(-50%) translateY(-33%) rotate(360deg);
  }
}

@keyframes appear-1 {
  from {
    transform: translateY(25%);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
}

@keyframes appear-2 {
  from {
    transform: translateY(25%);
    opacity: 0;
  }
  25% {
    transform: translateY(25%);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
}

@keyframes appear-3 {
  from {
    transform: translateY(25%);
    opacity: 0;
  }
  50% {
    transform: translateY(25%);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
}

.is-left,
.is-right {
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    display: block;
    background-color: #fff;
    width: 0.5em;
    height: 0.5em;
    top: calc(50% - 0.25em);
    position: absolute;
    opacity: 0.3;
    border-radius: 2em;
  }
}

.is-left {
  padding-left: 1.15em;
  &::after {
    left: 0.35em;
  }
}

.is-right {
  padding-right: 1.15em;
  &::after {
    right: 0.35em;
  }
}

.apear-enter-active,
.apear-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.apear-enter-from,
.apear-leave-to {
  opacity: 0;
  transform: translateX(-50%) translateY(-100%);
}

.unlock-appear {
  transition: opacity 0.5s ease;
}

// .unlock-appear-enter-active, .unlock-appear-leave-active {
//   transition: opacity 0.5s ease;
// }

// .unlock-appear-enter-from, .unlock-appear-leave-to {
//   opacity: 0;
// }

.star-appear-enter-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
  transform-origin: center center;
}
.star-appear-leave-active {
  transition: opacity 0s 0.5s ease;
}

.star-appear-enter-from {
  opacity: 0;
  transform: scale(2);
}
.star-appear-leave-to {
  opacity: 0;
}

.bg-appear-enter-active,
.bg-appear-leave-active {
  transition: opacity 0.5s ease;
}

.bg-appear-enter-from,
.bg-appear-leave-to {
  opacity: 0;
}

.bonus-none {
  &::-moz-progress-bar,
  &::-webkit-progress-value {
    background-color: #333c4d;
  }
}
.bonus-goal {
  &::-moz-progress-bar,
  &::-webkit-progress-value {
    background-color: goldenrod;
  }
}
.bonus-great {
  &::-moz-progress-bar,
  &::-webkit-progress-value {
    background-color: deeppink;
  }
}
.bonus-impressive {
  &::-moz-progress-bar,
  &::-webkit-progress-value {
    background-color: white;
  }
}
</style>
