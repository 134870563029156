type Step = { y: number, x: number }

type ArrayTwoOrMore<T> = {
  0: T
  1: T
} & Array<T>

export const lerp = (fromX: number, x: number, toX: number, fromY: number, toY: number) : number => {
  return (x - fromX) * (toY - fromY) / (toX - fromX) + fromY
}

const cosAcc = (x: number, minX: number, maxX: number, minY: number, maxY: number) => {
  if (x < minX) {
    return minY
  }
  if (x > maxX) {
    return maxY
  }
  const val = (x - minX) / (maxX - minX)
  return (Math.cos(val * Math.PI + Math.PI) / 2 + 0.5) * (maxY - minY) + minY
}

/**
 * Linear with accelerations
 */
export const lwa = ({
  x,
  baseEquation,
  accelerations
}: {
  x: number,
  baseEquation: { x1: number, y1: number, x2: number, y2: number },
  accelerations: { x1: number, x2: number, mult: number }[]
}) => {
  let y = (x - baseEquation.x1) * (baseEquation.y2 - baseEquation.y1) / (baseEquation.x2 - baseEquation.x1) + baseEquation.y1
  accelerations.forEach(add => y *= cosAcc(x, add.x1, add.x2, 1, add.mult))
  return y
}

/**
 * multiple linear interpolation
 */
export const mli = ({
  min = Number.NEGATIVE_INFINITY,
  max = Number.POSITIVE_INFINITY,
  steps,
  x
}: {
  min?: number,
  max?: number,
  steps: ArrayTwoOrMore<Step>,
  x: number
}) => {
  const minSteps = steps.filter(step => step.x <= x)
  const maxSteps = steps.filter(step => step.x > x)
  let minStep = minSteps.find((step) => minSteps.filter(step2 => step2 !== step && step.x < step2.x).length === 0)
  let maxStep = maxSteps.find((step) => maxSteps.filter(step2 => step2 !== step && step.x > step2.x).length === 0)

  if (!minStep && maxStep) {
    minStep = maxStep
    maxSteps.splice(maxSteps.indexOf(minStep), 1)
    maxStep = maxSteps.find((step) => maxSteps.filter(step2 => step2 !== step && step.x > step2.x).length === 0)
  }

  if (!maxStep && minStep) {
    maxStep = minStep
    minSteps.splice(minSteps.indexOf(maxStep), 1)
    minStep = minSteps.find((step) => minSteps.filter(step2 => step2 !== step && step.x < step2.x).length === 0)
  }

  if (!minStep || !maxStep) {
    throw new Error('Need more than 1 steps')
  }

  return Math.max(
    Math.min(lerp(minStep.x, x, maxStep.x, minStep.y, maxStep.y), max), min
  )
}
