import { RouteName, Roles } from "../types/route";

export default [
  {
    path: "/admin",
    name: RouteName.Admin,
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/admin/Menu.vue"),
    meta: {
      menu: false,
      auth: Roles.Admin,
    },
  },
  {
    path: "/admin/sponsor",
    name: RouteName.AdminSponsor,
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/admin/Sponsor.vue"),
    meta: {
      menu: false,
      auth: Roles.Admin,
    },
  },
  {
    path: "/admin/series",
    name: RouteName.AdminSeries,
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/admin/Series.vue"),
    meta: {
      menu: false,
      auth: Roles.Admin,
    },
  },
  {
    path: "/admin/exercices/:exercice?/:description?",
    name: RouteName.AdminExercices,
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/admin/Exercices.vue"),
    meta: {
      menu: false,
      auth: Roles.Admin,
    },
  },
  {
    path: "/admin/tree/:name?/:description?",
    name: RouteName.AdminExercicesTree,
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/admin/AdminExercicesTree.vue"
      ),
    meta: {
      menu: false,
      auth: Roles.Admin,
    },
  },
  {
    path: "/admin/skills/:exercice?/:description?",
    name: RouteName.AdminExercicesSkills,
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/admin/AdminExercicesSkills.vue"
      ),
    meta: {
      menu: false,
      auth: Roles.Admin,
    },
  },
  {
    path: "/admin/reps/:exercice?",
    name: RouteName.AdminExercicesReps,
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/admin/AdminExercicesReps.vue"
      ),
    meta: {
      menu: false,
      auth: Roles.Admin,
    },
  },
  {
    path: "/admin/exercice-similarities",
    name: RouteName.AdminExerciceSimilarity,
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/admin/ExerciceSimilarity.vue"
      ),
    meta: {
      menu: false,
      auth: Roles.Admin,
    },
  },
  {
    path: "/admin/exercice-unlock",
    name: RouteName.AdminExerciceUnlock,
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/admin/ExerciceUnlock.vue"
      ),
    meta: {
      menu: false,
      auth: Roles.Admin,
    },
  },
  {
    path: "/admin/exercice-search/:name?",
    name: RouteName.AdminExerciceSearch,
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/admin/ExerciceSearch.vue"
      ),
    meta: {
      menu: false,
      auth: Roles.Admin,
    },
  },
  {
    path: "/admin/style",
    name: RouteName.AdminStyle,
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/admin/Style.vue"),
    meta: {
      menu: false,
      auth: Roles.Admin,
    },
  },
  {
    path: "/admin/avatars",
    name: RouteName.AdminAvatars,
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/admin/Avatars.vue"),
    meta: {
      menu: false,
      auth: Roles.Admin,
    },
  },
  {
    path: "/admin/try/:exercice",
    name: RouteName.AdminExerciceTry,
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/admin/ExerciceTry.vue"),
    meta: {
      menu: false,
      auth: Roles.Admin,
    },
  },
];
