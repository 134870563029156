<template>
  <teleport to="body">
    <div
      class="fixed top-0 left-0 w-full h-full flex justify-center items-center z-10 bg-neutral text-neutral-content bg-opacity-90"
    >
      <div
        class="w-10/12 max-w-md bg-neutral-focus text-neutral-content p-5 card text-center"
      >
        <div class="flex items-center gap-5 justify-center">
          <!-- Less -->
          <button
            @click="decrease()"
            :disabled="Number(model) <= 0"
            class="btn btn-circle btn-secondary"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 12L18 12"
              />
            </svg>
          </button>

          <!-- Time / Reps -->
          <div class="flex justify-center text-center">
            <div
              class="relative text-neutral-content bg-neutral flex flex-col py-2 px-3 card mb-5 my-3"
            >
              <span class="font-mono">
                <span class="text-6xl">{{ isTime ? timeDisplay : model }}</span>
              </span>
              <span v-if="!isTime">reps</span>
            </div>
          </div>

          <!-- More -->
          <button
            @click="increase()"
            :disabled="Number(model) >= max"
            class="btn btn-circle btn-secondary"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 12L18 12M12 6l0 12"
              />
            </svg>
          </button>
        </div>

        <!-- Range -->
        <input
          type="range"
          min="0"
          :max="String(max)"
          v-model="model"
          class="range range-primary"
          step="1"
        />

        <!-- Description -->
        <p class="mt-2 mb-6 italic">
          <span v-if="isTime">Ajuster les temps réalisé</span>
          <span v-else>Ajuster les répétitions réalisés</span>
        </p>

        <!-- CTA -->
        <div class="my-3 text-center">
          <button @click="submit()" class="btn btn-wide btn-accent">
            Valider
          </button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { getChronoString } from "../../helpers/time";
import { ExerciceModel } from "../../../../backend/src/types/Exercice";

export type RepsHistory = { time: number; now: number; reps: number };

const emit = defineEmits<{
  (e: "submit", values: RepsHistory): void;
}>();

const props = defineProps<{
  exerciceModel?: ExerciceModel;
  history: RepsHistory[];
}>();

const isTime = computed(() => {
  switch (props.exerciceModel) {
    case ExerciceModel.Isometric:
    case ExerciceModel.IsometricLeftRight:
      return true;
    default:
      return false;
  }
});

const model = ref(String(props.history[props.history.length - 1]?.reps || 0));
const max = computed(() => props.history[props.history.length - 1]?.reps || 0);
const timeDisplay = computed(() => getChronoString(Number(model.value) * 1000));

const increase = () =>
  (model.value = String(Math.min(Number(model.value) + 1, max.value)));
const decrease = () =>
  (model.value = String(Math.max(Number(model.value) - 1, 0)));

const getHistory = (): RepsHistory => {
  const value = Number(model.value);
  let history = props.history.find((hist) => hist.reps === value);
  if (history) {
    return history;
  }

  const more = props.history.filter((h) => h.reps > value).shift();
  const less = props.history.filter((h) => h.reps < value).pop();
  if (more && less) {
    const v = (value - less.reps) / (more.reps - less.reps);
    return {
      time: v * (more.time - less.time) + less.time,
      now: v * (more.now - less.now) + less.now,
      reps: value,
    };
  }

  history = more ||
    less ||
    props.history[props.history.length - 1] || {
      value,
      time: value,
      now: Date.now(),
    };
  return {
    time: history.time,
    now: history.now,
    reps: value,
  };
};

const submit = () => emit("submit", getHistory());
</script>

<style lang="scss" scoped></style>
