import { ref } from "vue";
import type { Payload } from "../../../backend/src/types/Payload";
import type {
  SetStats,
  Set,
  SetAbstract,
} from "../../../backend/src/types/Set";
import { doActions } from "./payloadData";
import { useSportStore } from "../store";
import type { ProgramId } from "../../../backend/src/types/Program";
import emitter from "../services/emitter";
import type { Friend } from "../../../backend/src/types/Friend";
import type { Equipment } from "../../../backend/src/types/Equipment";
import type { HistoryWeek } from "../../../backend/src/types/Set";
import type { Avatar } from "../../../backend/src/types/Avatar";
import type { NotifName } from "../../../backend/src/types/Notif";
import type { Emoji } from "../../../backend/src/types/Emoji";
import type { Lootbox } from "../../../backend/src/types/Lootbox";
import type { NotifParams } from "@/trads/fr/notifs";
import type { ExerciceId } from "../../../backend/src/types/Exercice";

export const authHandler = (response: Response) => {
  if (response.status === 401) {
    emitter.emit("api:disconnected");
  }
  return response;
};

export const API_URL = ((host) => {
  // dev
  if (host.indexOf("localhost") === 0) {
    return "/api/v1";
  }

  // staging
  if (host.indexOf("sport-staging.namide.com") === 0) {
    return "https://api.sport-staging.namide.com/api/v1";
  }

  // prod
  return "https://api.sport.namide.com/api/v1";
})(window.location.host);

const USE_TOKEN = false;
let token: string = localStorage.getItem("token") || "empty";

export function setOptions(
  data: { [key: string]: string | unknown | number } | null = null
) {
  const options: {
    [key: string]: string | unknown;
    headers: { [key: string]: string };
  } = { headers: {} };

  if (USE_TOKEN) {
    options.withCredentials = "include";
    options.headers["x-token"] = token;
  } else {
    options.credentials = "include";
  }

  if (data) {
    options.method = "POST";
    options.headers["Content-Type"] = "application/json";
    options.body = JSON.stringify(data);
  }

  return options as RequestInit;
}

export function fetchApi(
  store: ReturnType<typeof useSportStore>,
  endpoint: string,
  payload: { [key: string]: unknown } | null = null
) {
  return fetch(API_URL + "/" + endpoint, setOptions(payload))
    .then(authHandler)
    .then((response) => response.json())
    .then((payload) => doActions(payload, store));
}

export function refresh(
  sportStore: ReturnType<typeof useSportStore>
): Promise<Payload> {
  return fetch(API_URL + "/state", setOptions())
    .then(authHandler)
    .then((response) => response.json())
    .then((payload) => doActions(payload, sportStore));
}

export function getLogin() {
  const store = useSportStore();
  const login = (email: string, password: string) => {
    const promise: Promise<{ response: Response; data: Payload }> = fetch(
      API_URL + "/users/login",
      setOptions({ email, password })
    ).then((response) => {
      if (USE_TOKEN) {
        token = response.headers.get("x-token") as string;
        localStorage.setItem("token", token);
      }

      return response.json().then((data: Payload) => {
        const payload = doActions(data, store);
        return { data: payload, response };
      });
    });

    return promise;
  };

  return login;
}

export function getDoSet() {
  const store = useSportStore();
  const doSet = (set: Set) => {
    const promise: Promise<Payload> = fetch(
      API_URL + "/sets/do",
      setOptions(set)
    )
      .then(authHandler)
      .then((response) => response.json())
      .then((payload) => doActions(payload, store));
    return promise;
  };

  return doSet;
}

export async function rejectWorkout(
  workoutID: number,
  sportStore: ReturnType<typeof useSportStore>,
  byTime = false
) {
  return fetch(
    API_URL + "/workouts/reject",
    setOptions({ workout: workoutID, byTime })
  )
    .then(authHandler)
    .then((response) => response.json())
    .then((payload) => doActions(payload, sportStore));
}

export async function createWorkout(sets: SetAbstract[], users: string[]) {
  return fetch(API_URL + "/workouts/create", setOptions({ sets, users }))
    .then(authHandler)
    .then((response) => response.json())
    .then((payload) => payload.workout);
}

export function getRequestFriend() {
  const store = useSportStore();
  const requestFriend = (nickname: string) => {
    const promise: Promise<Payload> = fetch(
      API_URL + "/friends/request",
      setOptions({ user: { nickname } })
    )
      .then(authHandler)
      .then((response) => response.json())
      .then((payload) => doActions(payload, store));
    return promise;
  };

  return requestFriend;
}

export function getAcceptFriend() {
  const store = useSportStore();
  const acceptFriend = (nickname: string) => {
    const promise: Promise<Payload> = fetch(
      API_URL + "/friends/accept",
      setOptions({ user: { nickname } })
    )
      .then(authHandler)
      .then((response) => response.json())
      .then((payload) => doActions(payload, store));
    return promise;
  };

  return acceptFriend;
}
export function getDeclineFriend() {
  const store = useSportStore();
  const friend = (nickname: string) => {
    const promise: Promise<Payload> = fetch(
      API_URL + "/friends/decline",
      setOptions({ user: { nickname } })
    )
      .then(authHandler)
      .then((response) => response.json())
      .then((payload) => doActions(payload, store));
    return promise;
  };

  return friend;
}

export function getDeleteFriend() {
  const store = useSportStore();
  const deleteFriend = (nickname: string) => {
    const promise: Promise<Payload> = fetch(
      API_URL + "/friends/delete",
      setOptions({ user: { nickname } })
    )
      .then(authHandler)
      .then((response) => response.json())
      .then((payload) => doActions(payload, store));
    return promise;
  };

  return deleteFriend;
}

export function getRejectSet() {
  const store = useSportStore();
  const rejectSet = (set: Set) => {
    const promise: Promise<Payload> = fetch(
      API_URL + "/sets/reject",
      setOptions(set)
    )
      .then(authHandler)
      .then((response) => response.json())
      .then((payload) => doActions(payload, store));
    return promise;
  };

  return rejectSet;
}

export function getSubscribeProgram() {
  const store = useSportStore();
  const doSet = (programId: ProgramId) => {
    const promise: Promise<Payload> = fetch(
      API_URL + "/programs/subscribe",
      setOptions({ program: { id: programId } })
    )
      .then(authHandler)
      .then((response) => response.json())
      .then((payload) => doActions(payload, store));
    return promise;
  };

  return doSet;
}

export function getUnsubscribeProgram() {
  const store = useSportStore();
  const doSet = (programId: ProgramId) => {
    const promise: Promise<Payload> = fetch(
      API_URL + "/programs/unsubscribe",
      setOptions({ program: { id: programId } })
    )
      .then(authHandler)
      .then((response) => response.json())
      .then((payload) => doActions(payload, store));
    return promise;
  };

  return doSet;
}

export async function getRanking() {
  const list = await fetch(API_URL + "/users/ranking", setOptions())
    .then(authHandler)
    .then((response) => response.json());

  return list;
}

export async function getSetsHistoryWeek(date: Date): Promise<HistoryWeek[]> {
  return await fetch(
    API_URL + "/workouts/history/week",
    setOptions({ date: date.toISOString() })
  )
    .then(authHandler)
    .then((response) => response.json())
    .then(({ historyWeek }) => historyWeek || []);
}

export async function getEquipments(): Promise<Equipment[]> {
  const list = await fetch(API_URL + "/equipment/user", setOptions())
    .then(authHandler)
    .then((response) => response.json());

  return list.equipments;
}

export async function setEquipments(
  equipments: Equipment[],
  store: ReturnType<typeof useSportStore>
) {
  await fetch(API_URL + "/equipment/user", setOptions({ equipments }))
    .then(authHandler)
    .then((response) => response.json())
    .then((data) => doActions(data, store));
}

export function getFriends(list: Friend[]) {
  return fetch(API_URL + "/friends/experience", setOptions())
    .then(authHandler)
    .then((response) => response.json())
    .then((payload) => {
      list.splice(0, list.length, ...payload);
      list.sort((a, b) =>
        a.nickname.localeCompare(b.nickname, "en", { sensitivity: "base" })
      );
      return list;
    });
}

export function useFriends() {
  const list = ref<Friend[]>([]);
  getFriends(list.value);
  return list;
}

export function getBlockedExercices(): Promise<{ banned: ExerciceId[] }> {
  return fetch(API_URL + "/exercices/blocked", setOptions())
    .then(authHandler)
    .then((response) => response.json());
}

export function getExercicesTree(): Promise<{ variants: ExerciceId[][][] }> {
  return fetch(API_URL + "/exercices/tree", setOptions())
    .then(authHandler)
    .then((response) => response.json());
}

export function blockExercice(
  id: ExerciceId,
  store: ReturnType<typeof useSportStore>
) {
  return fetch(API_URL + "/exercices/block", setOptions({ exercice: { id } }))
    .then(authHandler)
    .then((response) => response.json())
    .then((data) => data as Payload & { banned: ExerciceId[] })
    .then((payload) => doActions<{ banned: ExerciceId[] }>(payload, store));
}

export function unblockExercice(
  id: ExerciceId,
  store: ReturnType<typeof useSportStore>
) {
  return fetch(API_URL + "/exercices/unblock", setOptions({ exercice: { id } }))
    .then(authHandler)
    .then((response) => response.json())
    .then((data) => data as Payload & { banned: ExerciceId[] })
    .then((payload) => doActions<typeof payload>(payload, store));
}

export function useOpenLootbox() {
  const store = useSportStore();
  const openLootbox = async (lootbox: Lootbox) => {
    const avatar: Avatar = await fetch(
      API_URL + `/${lootbox.type}/reveal`,
      setOptions({ id: lootbox.id })
    )
      .then(authHandler)
      .then((response) => response.json());

    await refresh(store);
    return avatar;
  };

  return openLootbox;
}

export function useDisableAvatar() {
  const store = useSportStore();
  const disableAvatar = async () => {
    await fetch(API_URL + "/avatar/disable", setOptions({}))
      .then(authHandler)
      .then((response) => response.json())
      .then((payload) => doActions<typeof payload>(payload, store));
  };

  return disableAvatar;
}

export function useEnableAvatar() {
  const store = useSportStore();
  const disableAvatar = async (id: number) => {
    await fetch(API_URL + "/avatar/enable", setOptions({ id }))
      .then(authHandler)
      .then((response) => response.json())
      .then((payload) => doActions<typeof payload>(payload, store));
  };

  return disableAvatar;
}

export function useDisableEmoji() {
  const store = useSportStore();
  const disableEmoji = async () => {
    await fetch(API_URL + "/emoji/disable", setOptions({}))
      .then(authHandler)
      .then((response) => response.json())
      .then((payload) => doActions<typeof payload>(payload, store));
  };

  return disableEmoji;
}

export function useEnableEmoji() {
  const store = useSportStore();
  const disableEmoji = async (id: number) => {
    await fetch(API_URL + "/emoji/enable", setOptions({ id }))
      .then(authHandler)
      .then((response) => response.json())
      .then((payload) => doActions<typeof payload>(payload, store));
  };

  return disableEmoji;
}

export function getStats(
  workoutID: number
): Promise<{ sets: Set[]; stats: SetStats[] }> {
  return fetch(API_URL + `/workouts/stats/${workoutID}`, setOptions())
    .then(authHandler)
    .then((response) => response.json());
}

export function getHistoryScoreAndBonus(): Promise<{
  from: number;
  to: number;
  list: { score: number; bonus: number; date: number }[];
}> {
  return fetch(API_URL + `/workouts/history/score-bonus`, setOptions())
    .then(authHandler)
    .then((response) => response.json());
}

export function getAvatars(): Promise<{ avatars: Avatar[] }> {
  return fetch(API_URL + `/avatar/list`, setOptions())
    .then(authHandler)
    .then((response) => response.json());
}

export function getEmojis(): Promise<{ emojis: Emoji[] }> {
  return fetch(API_URL + `/emoji/list`, setOptions())
    .then(authHandler)
    .then((response) => response.json());
}

export function getNotifs(): Promise<{
  notifs: (
    | {
        user: {
          avatar: string;
          nickname: string;
          emoji: string;
        };
        name: NotifName.RankUp;
        data: NotifParams[NotifName.RankUp];
        date: number;
      }
    | {
        user: {
          avatar: string;
          nickname: string;
          emoji: string;
        };
        name: NotifName.SequenceEnded;
        data: NotifParams[NotifName.SequenceEnded];
        date: number;
      }
  )[];
}> {
  return fetch(API_URL + `/notifs/list`, setOptions())
    .then(authHandler)
    .then((response) => response.json());
}
