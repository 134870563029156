import {
  isAerobic,
  isFlexibility,
  isIsometric,
  isStrength,
} from "../../../backend/src/helpers/exercices";
import {
  ExerciceModel,
  type ExerciceSmall,
} from "../../../backend/src/types/Exercice";
import type { Set } from "../../../backend/src/types/Set";
import { getExercice } from "./exercice";

export const getActiveSets = (sets: Set[]) =>
  sets.filter((set) => getExercice(set.exercice)?.model !== ExerciceModel.None);

export const getWorkoutStrength = (activeSets: Set[]) => {
  const count = activeSets.filter(
    (set) =>
      isStrength(getExercice(set.exercice) as ExerciceSmall) ||
      isIsometric(getExercice(set.exercice) as ExerciceSmall)
  ).length;
  return (100 * count) / activeSets.length;
};

export const getWorkoutFlexibility = (activeSets: Set[]) => {
  const count = activeSets.filter((set) =>
    isFlexibility(getExercice(set.exercice) as ExerciceSmall)
  ).length;
  return 10 + (90 * count) / activeSets.length;
};

export const getWorkoutDuration = (sets: Set[]) =>
  sets.reduce((time, set) => time + set.time, 0);

export const getWorkoutCardio = (sets: Set[]) => {
  const activeSets = getActiveSets(sets);
  const duration = getWorkoutDuration(sets);
  const aerobicCount = activeSets.filter((set) =>
    isAerobic(getExercice(set.exercice) as ExerciceSmall)
  ).length;
  const ratioAerobicOthers = 100 * (aerobicCount / activeSets.length);
  const pauses = sets.filter(
    (set) => getExercice(set.exercice)?.model === ExerciceModel.None
  );
  const pausesDuration = pauses.reduce((time, set) => time + set.time, 0);
  const ratioPauseErxercice = Math.min(
    (100 * (duration / 60) * (1 - pausesDuration / duration)) / 90
  );
  return Math.max(ratioAerobicOthers, ratioPauseErxercice);
};

export const getProgramStats = (sets: Set[], maxDuration = 60 * 60) => {
  const activeSets = getActiveSets(sets);
  return [
    { label: "Renforcement", value: getWorkoutStrength(activeSets) },
    { label: "Souplesse", value: getWorkoutFlexibility(activeSets) },
    { label: "Cardio", value: getWorkoutCardio(activeSets) },
    {
      label: "Durée",
      value: Math.min(100, (100 * getWorkoutDuration(sets)) / maxDuration),
    },
  ];
};
