import type { Component } from "vue";
import { defineAsyncComponent } from "vue";
import {
  ExerciceId,
  type Exercice,
} from "../../../../backend/src/types/Exercice";

export const enum ExercicePropertyType {
  distance = "distance",
  repetition = "repetition",
  time = "time",
  disabled = "disabled",
}

// export const exerciceProperties = [
//   {
//     unit: ExerciceUnit.Reps,
//     model: ExerciceModel.Isometric,
//   },
//   {
//     unit: ExerciceUnit.Mn,
//     model: ExerciceModel.Isometric,
//   },
//   {
//     unit: ExerciceUnit.Sec,
//     model: ExerciceModel.Isometric,
//   },
// ];

export type ExerciceTrad = {
  id: ExerciceId;
  title: string;
  cover: string;
  resume: string;
  description?: Component;
};

export type ExerciceFull = Exercice & ExerciceTrad;

const trads: ExerciceTrad[] = [
  {
    id: ExerciceId.Pause,
    title: "Pause forcée",
    cover: "/img/exercice/pause.jpg",
    resume: `Petite pause`,
  },
  {
    id: ExerciceId.KneePushUps,
    title: "Pompes sur les genoux",
    cover: "/img/exercice/knee-push-up.jpg",
    resume: `Mettez-vous à genoux sur un tapis d’exercice. Penchez-vous lentement vers l’avant pour placer vos paumes à plat sur le tapis avec les doigts écartés et tournés vers l’avant. Vos mains sont placées à la largeur des épaules ou un peu plus.
      
      Déplacez lentement votre poids vers l’avant jusqu’à ce que vos épaules soient placées directement au-dessus de vos mains et vos bras doivent être complètement tendus.
      
      Il vous faudra maintenant repositionner vos mains pour qu’il y ait une ligne droite entre vos genoux et votre tête, sans fléchir les hanches. Contractez vos muscles abdominaux et vos fessiers au maximum pour garder votre corps rigide.`,
  },
  {
    id: ExerciceId.DiamondPushUps,
    title: "Pompes diamant",
    cover: "/img/exercice/diamond-push-up.jpg",
    resume: `En position haute de pompe, bras tendus et corps gainé : Les mains sont sous votre poitrine et se touchent au niveau des index et des pouces; Elles forment un « diamant » ; Faites une pompe complète en gardant les mains dans cette position; Répétez. Notes : N’écartez pas les coudes, gardez-les proches de votre corps. Contractez les fessiers et les abdominaux et ne baissez pas les hanches en premier. Le corps est droit et gainé des pieds à la tête (jambes, hanches, dos et cou alignés).`,
  },
  {
    id: ExerciceId.KneeDiamondPushUps,
    title: "Pompes diamant sur les genous",
    cover: "/img/exercice/knee-diamond-push-up.jpg",
    resume:
      "Mettez-vous à genoux sur un tapis d’exercice. En position haute de pompe, bras tendus et corps gainé : Les mains sont sous votre poitrine et se touchent au niveau des index et des pouces; Elles forment un « diamant » (ou plutôt un quadrilatère en forme de cerf-volant pour être plus précis 😉 ); Faites une pompe complète en gardant les mains dans cette position; Répétez. Notes : N’écartez pas les coudes, gardez-les proches de votre corps. Contractez les fessiers et les abdominaux et ne baissez pas les hanches en premier. Le corps est droit et gainé des pieds à la tête (jambes, hanches, dos et cou alignés).",
  },
  {
    id: ExerciceId.PushUps,
    title: "Pompes",
    cover: "/img/exercice/push-up.jpg",
    resume:
      "Les pompes musclent les pectoraux, les triceps, les faisceaux antérieurs des deltoïdes et les grands dentelés.",
    description: defineAsyncComponent(() => import("./push-ups/push-ups.vue")),
  },
  {
    id: ExerciceId.HinduPushUps,
    title: "pompes indiennes",
    cover: "/img/exercice/hindu-push-ups.jpg",
    resume: "",
    description: defineAsyncComponent(
      () => import("./push-ups/hindu-push-ups.vue")
    ),
  },
  {
    id: ExerciceId.DiveBomberPushUps,
    title: "pompes dive bomber",
    cover: "/img/exercice/dive-bomber-push-ups.jpg",
    resume: "",
    description: defineAsyncComponent(
      () => import("./push-ups/dive-bomber-push-ups.vue")
    ),
  },
  {
    id: ExerciceId.ElevatedPushUps,
    title: "Pompes mains surélevées",
    cover: "/img/exercice/elevated-push-ups.jpg",
    resume: "",
    description: defineAsyncComponent(
      () => import("./push-ups/elevated-push-ups.vue")
    ),
  },
  {
    id: ExerciceId.WideDeclinePushUps,
    title: "Pompes surélevées",
    cover: "/img/exercice/wide-decline-push-up.jpg",
    resume:
      "Poser les pieds sur une chaine et placer les mains de deux fois la largeur des épaules.",
  },
  {
    id: ExerciceId.ArcherPushUps,
    title: "Pompes Archer",
    cover: "/img/exercice/archer-push-ups.jpg",
    resume:
      "Placez vos bras tendus et écartés sur les côtés d’environ 2 fois la largeur des épaules. Tendez un bras et pliez l’autre en alternance. Le coude du bras tendu doit toujours rester légèrement plié.",
    description: defineAsyncComponent(
      () => import("./push-ups/archer-push-ups.vue")
    ),
  },
  {
    id: ExerciceId.WallPushUps,
    title: "Pompes contre un mur",
    cover: "/img/exercice/wall-push-ups.jpg",
    resume: `Gardez vos pieds fermement ancrés au sol. Ne soulevez pas et ne trainez pas vos pieds pendant que vous faites une pompe. Fléchissez lentement vos coudes et abaissez le haut de votre corps vers le mur (sans vous heurter au mur, bien sûr).`,
  },
  {
    id: ExerciceId.WideKneePushUp,
    title: "Pompes larges sur les genoux",
    cover: "/img/exercice/wide-knee-push-up.jpg",
    resume: `Mettez-vous à genoux sur un tapis d’exercice. Penchez-vous lentement vers l’avant pour placer vos paumes à plat sur le tapis avec les doigts écartés et tournés vers l’avant. Vos mains sont placées à deux fois la largeur des épaules.`,
  },
  {
    id: ExerciceId.OneArmPushUps,
    title: "Pompes à une main",
    cover: "/img/exercice/one-arm-push-ups.jpg",
    resume: `La position de départ est assez différente de celle d’une pompe classique. Vos pieds doivent être placés de manière beaucoup plus large, d’environ 2 fois la largeur des épaules.
      Placez la main du bras que vous souhaitez travailler au niveau de votre poitrine. La main sera orientée à 45°, vers l’intérieur, plutôt que vers l’avant comme une pompe classique. Si vous faites pivoter le bras vers l’extérieur, cela exercera une pression excessive sur votre coude.
      Lorsque vous descendez pendant la pompe, gardez votre coude serré et tournez légèrement vos épaules vers le bras qui appuie. Descendez aussi bas que possible, puis revenez à la position initiale.`,
  },
  {
    id: ExerciceId.InvertedRows,
    title: "Tractions obliques",
    cover: "/img/exercice/inverted-rows.jpg",
    resume:
      "Placez-vous sous la table et attrapez chaque rebord opposés de la table avec vos mains. Tendez les jambes et posez vos deux pieds joints au sol. Suspendez le haut de votre corps tout en gardant les pieds au sol avec les jambes tendues",
  },
  {
    id: ExerciceId.Running,
    title: "Course à pied",
    cover: "/img/exercice/running.jpg",
    resume:
      "S’il y a une chose à comprendre dans l’entraînement course à pied, c’est que la patience est notre meilleur ami. Un entraînement constant produit de petits progrès parfois invisibles.",
  },
  {
    id: ExerciceId.Squats,
    title: "Flexion sur jambes",
    cover: "/img/exercice/squats.jpg",
    description: defineAsyncComponent(() => import("./squat/squat.vue")),
    resume: "",
  },
  {
    id: ExerciceId.WallSit,
    title: "La chaise",
    cover: "/img/exercice/wall-sit.jpg",
    resume:
      "On baisse les fesses comme si on allait s’asseoir et on colle son dos droit au mur. Les jambes forment un angle droit et les cuisses sont parallèles au sol. On pose les pieds bien à plat. Il faut tenir le plus longtemps possible.",
  },
  {
    id: ExerciceId.HipThrust,
    title: "Lever des hanches",
    cover: "/img/exercice/hip-thrust.jpg",
    resume:
      "Allongez-vous sur le dos, les bras sur les côtés, les genoux fléchis et les pieds sur le sol. Levez les hanches vers le plafond.",
    description: defineAsyncComponent(
      () => import("./hip-thrust/hip-thrust.vue")
    ),
  },
  {
    id: ExerciceId.GluteBridge,
    title: "Petit pont",
    cover: "/img/exercice/glute-bridge.jpg",
    resume: `Allonge-toi sur ton tapis, dos bien à plat sur le sol. Écarte tes pieds de la largeur de tes hanches et rapproche-les de tes fesses de sorte que tes mollets soient perpendiculaires au sol. Ta tête repose à plat sur le tapis et tes yeux fixent le plafond. Ton cou est dans le prolongement de ta colonne vertébrale. Tendus, tes bras reposent sur le tapis, de part et d’autre de ton corps.
      Soulève ton bassin jusqu’à ce que le bas de ton dos, tes fesses et tes cuisses forment une ligne droite. Pour bien travailler le muscle érecteur du rachis, soulève également légèrement tes omoplates. Si tes genoux ne doivent pas se toucher durant le mouvement, ils ne doivent pas non plus être trop éloignés l’un de l’autre. Le mieux serait de les écarter de la largeur d’un poing. Veille à bien pousser tes fesses vers le haut avec les muscles de tes jambes. Ne prends pas appui sur tes bras.
      `,
  },
  {
    id: ExerciceId.SplitSquat,
    title: "Fente",
    cover: "/img/exercice/split-squat.jpg",
    resume:
      "Pour faire des fentes correctement, on met un pied devant, et l’autre loin derrière. Le talon du pied à l’arrière doit être toujours décollé du sol. Une fois que l'on est bien placé, on descend le plus bas possible, de manière à avoir un angle de 90° au niveau du genou. Pensez à bien inspirer à la descente, et expirer à la montée.",
  },
  {
    id: ExerciceId.BulgarianSplitSquat,
    title: "Squat bulgare",
    cover: "/img/exercice/bulgarian-split-squat.jpg",
    resume: "Squat en ayant la jambe arrière en appui sur un support.",
    description: defineAsyncComponent(
      () => import("./squat/bulgarian-split-squat.vue")
    ),
  },
  {
    id: ExerciceId.PistolSquat,
    title: "Pistol squat",
    cover: "/img/exercice/pistol-squat.jpg",
    resume: "Exercice de squat qui désigne des squats effectués sur une jambe.",
    description: defineAsyncComponent(() => import("./squat/pistol-squat.vue")),
  },
  {
    id: ExerciceId.ChairPistolSquat,
    title: "Pistol squat sur chaise",
    cover: "/img/exercice/chair-pistol-squat.jpg",
    resume:
      "Exercice de squat qui désigne des squats effectués sur une jambe en s'aidant d'une chaise.",
    description: defineAsyncComponent(
      () => import("./squat/chair-pistol-squat.vue")
    ),
  },
  {
    id: ExerciceId.WallPushUps,
    title: "Pompes au mur",
    cover: "/img/exercice/wall-push-up.jpg",
    resume:
      "Tenez-vous debout devant un mur, les pieds à la largeur des épaules. Placez vos mains sur le mur à la hauteur et à la largeur des épaules. Les pieds au sol, les abdominaux contractés et votre dos bien droit, fléchissez les bras pour approcher lentement et le plus possible votre corps du mur. Terminez le mouvement avec extension complète des bras.",
  },
  // {
  //   id: ExerciceId.FrontChairPushUp,
  //   title: "Pompes sur une chaise",
  //   cover: "/img/exercice/front-chair-push-up.jpg",
  //   resume:
  //     "Sur une chaise solide, placez-vous en position de pompe, allongez les bras, les paumes à plat sur le siège et les jambes bien étendues derrière vous. Votre corps devrait former une ligne droite de la tête aux talons. Tout en maintenant les abdominaux contractés, votre dos droit et vos coudes près du corps, fléchissez les bras jusqu’à ce que la poitrine touche presque à la chaise. Allongez les bras en extension complète pour terminer le mouvement",
  // },
  {
    id: ExerciceId.SeatedOverheadDumbbellTriceps,
    title: "L’extension nuque avec haltères",
    cover: "/img/exercice/seated-overhead-dumbbell-triceps.jpg",
    resume:
      "Sur une chaise, prenez un haltère dans chaque main et tendez les bras vers le haut, les paumes se faisant face, les bras contre la tête. En regardant droit devant vous, fléchissez les coudes vers l’arrière. Tendez les bras vers le haut, en sollicitant vos triceps.",
  },
  {
    id: ExerciceId.Plank,
    title: "Planche",
    cover: "/img/exercice/plank.jpg",
    resume: `Positionner les coudes sur le sol, les pieds ancrés au sol et les jambes tendues, on essaie de tenir le plus longtemps possible.
      Il y a quelques règles à respecter :
      Stabiliser les surfaces d'appui (les pieds et les coudes, par exemple),
      Tendre la ou les jambes d'appuis,
      Rentrer le ventre,
      Contracter les fessiers pour ne pas cambrer.`,
  },
  {
    id: ExerciceId.WallPlank,
    title: "planche murale",
    cover: "/img/exercice/wall-plank.jpg",
    resume: `En effet, positionné les coudes sur le sol, les pieds ancrés au sol et les jambes tendues, on essaie de tenir le plus longtemps possible. Il y a quelques règles à respecter :
      Stabiliser les surfaces d'appui (les pieds et les coudes, par exemple),
      Tendre la ou les jambes d'appuis,
      Rentrer le ventre,
      Contracter les fessiers pour ne pas cambrer.`,
  },
  {
    id: ExerciceId.SidePlank,
    title: "Planche latérale",
    cover: "/img/exercice/side-plank.jpg",
    resume:
      "Allongez-vous sur le côté, le corps en appui sur l’avant-bras et le pied. Le bras en appui doit former un angle de 90 degrés au niveau du coude. Laissez le second reposer le long du corps, ou levez-le verticalement pour vous aider à maintenir votre équilibre. Les pieds sont l’un sur l’autre et les jambes sont tendues, sans verrouiller les genoux. Rentrez le ventre et gardez le corps droit, la tête dans le prolongement du dos. Maintenez la position aussi longtemps que dure votre série. Répétez la planche latérale de l’autre côté.",
  },
  {
    id: ExerciceId.ReversePlank,
    title: "Planche inversée",
    cover: "/img/exercice/reverse-plank.jpg",
    resume: `Asseyez-vous sur le sol avec les jambes tendues devant vous. Placez les bras sur les côtés, légèrement derrière vous. Si vous le pouvez, dirigez les doigts vers l’avant, sinon, tournez-vous légèrement pour plus de confort. Engagez votre tronc pour vous mettre en position.
      Gardez les jambes droites, redressez les bras et poussez lentement les hanches jusqu’à ce que tout le corps soit droit : les chevilles, les genoux, les hanches, la colonne vertébrale, les épaules et la tête doivent tous être en ligne droite. Enfoncez légèrement les talons dans le sol pour aider l’extension des hanches, en levant la poitrine et en gardant le tronc contracté.
      Maintenez la position pour un temps.`,
    description: defineAsyncComponent(
      () => import("./plank/reverse-plank.vue")
    ),
  },
  {
    id: ExerciceId.ProneSnowAngel,
    title: "Ange des neiges inversé",
    cover: "/img/exercice/prone-snow-angel.jpg",
    resume: `Sur le ventre, cet exercice consiste à tendre les bras de chaque côté du corps. Vous les décollez à une dizaine de centimètres. Puis bougez vos bras latéralement comme des ailes d’ange depuis le dessus de votre tête jusqu’à vos hanches avec les paumes tournées vers le bas.`,
    description: defineAsyncComponent(
      () => import("./back/prone-snow-angel.vue")
    ),
  },
  {
    id: ExerciceId.LyingReversePlank,
    title: "Planche inversée allongée",
    cover: "/img/exercice/lying-reverse-plank.jpg",
    resume: `1. Commencez allongé sur le dos, jambes tendues devant vous et avant-bras le long du corps sur le sol.
      2. Appuyez vos avant-bras et coudes dans le sol et relevez-vous de façon à ce que vos avant-bras et vos talons soutiennent tout le poids de votre corps. Tenez la position.`,
  },
  {
    id: ExerciceId.ChairDips,
    title: "Dips avec chaise",
    cover: "/img/exercice/chair-dips.jpg",
    resume:
      "Asseyez-vous sur une chaise solide, sans appuie-bras, les pieds au sol. Placez les mains sur les bords du siège à côté des hanches et allongez les jambes jusqu’à ce que seuls vos talons touchent le sol. Avancez les talons pour que vos fesses se trouvent devant la chaise en soutenant le poids de votre corps par la seule force de vos bras. En pliant les coudes derrière vous, fléchissez les bras pour descendre votre corps d’environ 15 cm. Allongez les bras en extension complète pour terminer le mouvement. Répétez.",
  },
  {
    id: ExerciceId.ChairDipsKneeBent,
    title: "Dips avec chaise genoux fléchis",
    cover: "/img/exercice/chair-dips-knee-bent.jpg",
    resume:
      "Les mains placées derrière vous sur le rebord d'une chaise ou d'un banc ; bras tendues et jambes pliées, vous soutenez votre poids sur vos pieds et vos mains : Fléchissez vos bras en contrôlant la descente. Descendez jusqu'à ce que vos triceps soient parallèles au sol. Remontez en tendant complètement vos bras.",
  },
  {
    id: ExerciceId.SidePlankLift,
    title: "Planche latérale en étoile",
    cover: "/img/exercice/side-plank-lift.jpg",
    resume: `Placez votre main tendue au niveau de votre épaule.
      Pensez à toujours contracter les abdominaux pendant toute la durée de l’exercice.
      Tendez votre deuxième bras à la verticale et levez votre deuxième jambe tendue pour former une étoile.
      Gardez un alignement tête-bassin-pied (celui au sol)`,
  },
  {
    id: ExerciceId.SingleLegHipThrust,
    title: "Relevé de bassin sur une jambe",
    cover: "/img/exercice/single-leg-hip-thrust.jpg",
    resume: `Placez vous en position de départ au sol.
      Levez une jambe en l’air tendue en gardant le poids sur le talon de la jambe d’appui.
      Si cela devient trop compliqué, vous pouvez fléchir la jambe et posez votre cheville sur la cuisse de la jambe d’appui.
      Réalisez le mouvement du hip thrust classique en conservant vos hanches face au plafond pendant tout le mouvement.`,
  },
  {
    id: ExerciceId.Crunch,
    title: "Crunch",
    cover: "/img/exercice/crunch.jpg",
    resume: `Le crunch doit être pratiqué les pieds au sol ou relevés, le dos à plat sur le sol. Il suffit donc dans une position allongée sur le dos les genoux pliés, de contracter vos abdominaux pour lever légèrement vos épaules du sol en vous redressant mais sans jamais vouloir toucher les genoux, et sans jamais décoller le bas du dos du sol.`,
    description: defineAsyncComponent(() => import("./crunch/crunch.vue")),
  },
  {
    id: ExerciceId.HangingKneeRaises,
    title: "Levées de genoux suspendues",
    cover: "/img/exercice/hanging-knee-raises.jpg",
    resume: ``,
    description: defineAsyncComponent(
      () => import("./crunch/hanging-knee-raises.vue")
    ),
  },
  {
    id: ExerciceId.ReverseCrunch,
    title: "Crunch inversé",
    cover: "/img/exercice/reverse-crunch.jpg",
    resume: `Allongé sur le dos, les mains le long du corps avec les paumes contre le sol. Décollez le bassin du sol en amenant les genoux vers la poitrine en expirant. Tout en inspirant, baissez doucement le bassin vers le sol en continuant de contracter vos abdominaux.`,
  },
  {
    id: ExerciceId.Sprawls,
    title: "Sprawls",
    cover: "/img/exercice/sprawls.jpg",
    resume: `Enchaînement à répéter en boucle : une flexion des jambes les mains au sol, suivie d'un lancer des pieds en arrière pour arriver en position de planche, tout en gardant les bras tendus, puis d'un retour en position de squat avant de finalement se relever.`,
  },
  {
    id: ExerciceId.JumpingJack,
    title: "Jumping Jack",
    cover: "/img/exercice/jumping-jack.jpg",
    resume: `Commencez l’exercice en vous positionnant droit·e comme un i. En d’autres mots, collez vos pieds et positionnez vos bras le long de votre corps. Sautez vers le haut, et simultanément, écartez vos jambes sur les côtés tout en fléchissant légèrement les genoux, et montez vos bras (également par les côtés) au-dessus de votre tête. Faites ensuite le mouvement inverse, toujours au cours d’un saut, afin de revenir dans votre posture de départ.`,
  },
  {
    id: ExerciceId.SquatJump,
    title: "Squats sautés",
    cover: "/img/exercice/squat-jump.jpg",
    resume: ``,
    description: defineAsyncComponent(() => import("./squat/squat-jump.vue")),
  },
  {
    id: ExerciceId.HighKneeRun,
    title: "Monté de genoux",
    cover: "/img/exercice/high-knee-run.jpg",
    resume: "",
    description: defineAsyncComponent(
      () => import("./cardio/high-knee-run.vue")
    ),
  },
  {
    id: ExerciceId.MountainClimber,
    title: "Mouvement du grimpeur",
    cover: "/img/exercice/mountain-climber.jpg",
    resume: `Positionnez vous face au sol, en appui sur vos mains et sur la pointe de vos pieds. Vos bras et vos jambes doivent être tendus. Fléchissez une jambe, et ramenez votre genou au niveau de votre poitrine, avant de revenir en position initiale. Réalisez le même mouvement avec l’autre jambe, puis la première, et ainsi de suite, le plus rapidement possible.`,
    description: defineAsyncComponent(
      () => import("./cardio/mountain-climber.vue")
    ),
  },
  {
    id: ExerciceId.GoodMorning,
    title: "Good morning",
    cover: "/img/exercice/good-morning.jpg",
    resume: `Debout, les genoux légèrement fléchis. Le buste est droit, le regard horizontal. Posez les doigts sur les tempes. Les coudes doivent être tirés en arrière de manière à ce que les bras soient dans le plan frontal (si vous étiez le dos contre un mur, votre occiput, vos épaules et vos coudes devraient le toucher).
    1. En reculant vos fesses, penchez votre buste en avant. Le mouvement par des hanches. Le dos reste bien droit. 2. Descendez-le jusqu'à ce qu'il soit à l'horizontale. Si cette position vous est impossible, arrêtez la descente avant (à 45 degrés, voire moins).
    3. En contractant les abdos, remontez pour retrouver la position de départ.`,
  },
  // {
  //   name: "superman-a-arms-only",
  //   title: "Cobra",
  //   cover: "/img/exercice/superman-a-arms-only.jpg",
  //   resume: `Allongé sur le ventre avec bras tendus devant votre tête : Redressez légèrement votre buste en écartant les bras sur les côtés, pouces tournés vers le haut. Contractez les fessiers. Serrez les omoplates et sortez la poitrine. Tenez la position le plus longtemps possible. Ne cambrez pas excessivement le dos en levant trop haut le buste (pensez au travail des fessiers). Vous pouvez regarder le sol pendant l’exercice en rentrant légèrement le menton pour éviter une tension au niveau du cou. Ne décollez pas trop le buste, il ne s’agit pas de la posture du cobra en Yoga.`,
  // },
  // {
  //   name: "superman-w-arms-only",
  //   title: "Superman W buste relevé",
  //   cover: "/img/exercice/superman-w-arms-only.jpg",
  //   resume: ``,
  // },
  // {
  //   name: "superman-y-arms-only",
  //   title: "Superman Y buste relevé",
  //   cover: "/img/exercice/superman-y-arms-only.jpg",
  //   resume: ``,
  // },
  // {
  //   name: "superman-hands-neck-arms-only",
  //   title: "Superman buste relevé mains sur la nuque",
  //   cover: "/img/exercice/superman-hands-neck-arms-only.jpg",
  //   resume: ``,
  // },
  {
    id: ExerciceId.SupermanA,
    title: "Superman A",
    cover: "/img/exercice/superman-a.jpg",
    resume: ``,
  },
  {
    id: ExerciceId.SupermanW,
    title: "Superman W",
    cover: "/img/exercice/superman-w.jpg",
    resume: ``,
  },
  {
    id: ExerciceId.SupermanY,
    title: "Superman Y",
    cover: "/img/exercice/superman-y.jpg",
    resume: `Allongé sur le ventre, bras tendus devant soi au sol, paumes vers le sol, jambes tendues vers l'arrière. Monter les bras et les jambes les plus hauts possible et tenir la position.`,
  },
  {
    id: ExerciceId.SupermanHandsNeck,
    title: "Superman mains sur la nuque",
    cover: "/img/exercice/superman-hands-neck.jpg",
    resume: ``,
  },
  {
    id: ExerciceId.SupermanLegOnly,
    title: "Superman pieds relevés",
    cover: "/img/exercice/superman-leg-only.jpg",
    resume: ``,
  },
  {
    id: ExerciceId.WidePushUps,
    title: "Pompes larges",
    cover: "/img/exercice/wide-push-ups.jpg",
    resume: `Allongé face au sol, les pieds au niveau de la largeur d’épaules, les mains écartées d’une largeur supérieure à celle des épaules, le dos bien droit dans l’alignement des jambes, il s’agit d’effectuer une extension des coudes associée à une abduction des épaules après être plus ou moins descendu.
    Il est aussi bon de noter que comme lors de tous les exercices pour les pectoraux, votre cage thoracique doit être “sortie”. À aucun moment, les épaules ne doivent se retrouver en avant des pectoraux.`,
  },
  {
    id: ExerciceId.WidePullUps,
    title: "Tractions large pronation",
    cover: "/img/exercice/wide-pull-ups.jpg",
    resume: `Pour exécuter une traction prise large, il faut agripper une barre fixe avec les mains en pronation et se laisser suspendre sans forcément avoir les bras totalement tendu. Au fur et à mesure de la traction, projetez votre buste en avant tout en vous cambrant afin d'optimiser le travail des dorsaux.`,
  },
  {
    id: ExerciceId.SlidingFloorPullup,
    title: "Tractions au sol",
    cover: "/img/exercice/sliding-floor-pullup.jpg",
    resume: ``,
    description: defineAsyncComponent(
      () => import("./pull-ups/sliding-floor-pullup.vue")
    ),
  },
  {
    id: ExerciceId.ChinUp,
    title: "Tractions Chin-up",
    cover: "/img/exercice/chin-up.jpg",
    resume: `La traction Chin-up ou en supination: conçue pour travailler les muscles du bras et du dos et donner ainsi de l'épaisseur au dos. La paume de la main doit être dirigée vers le visage et le pouce vers l'extérieur.`,
  },
];

export default trads;
