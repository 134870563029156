import emitter from "@/services/emitter";

export const getTheme = () => localStorage.getItem("theme") || "";

export const updateTheme = () => {
  const theme = getTheme();
  if (theme) {
    document.body.setAttribute("data-theme", theme);
    emitter.emit("theme:changed", theme);
  }
};

export const changeTheme = (themeName = "") => {
  document.body.setAttribute("data-theme", themeName);
  localStorage.setItem("theme", themeName);
  emitter.emit("theme:changed", themeName);
};
