import Triangulr from "triangulr";

const width = screen.width;
const height = screen.height;
const lerp = (a: number, b: number, val: number) => (a - b) * val + b;
const cache: { [key: string]: SVGViewElement } = {};

const colorRender = function (
  path: { x: number; y: number; cols: number; lines: number },
  color1: string,
  color2: string,
  accent: number
) {
  const random = 0.25;
  const ratio =
    accent *
      (1 - random) *
      ((path.x * 0.5) / path.cols + 0.5 - (path.y * 0.5) / path.lines) +
    Math.random() * random;

  const c1 = color1.match(/(\d+) (\d+)% (\d+)%/) as [
    string,
    string,
    string,
    string
  ];
  const c2 = color2.match(/(\d+) (\d+)% (\d+)%/) as [
    string,
    string,
    string,
    string
  ];
  const [c1h, c1s, c1l] = [
    Number(c1[1] || 0),
    Number(c1[2] || 0),
    Number(c1[3] || 0),
  ];
  const [c2h, c2s, c2l] = [
    Number(c2[1] || 0),
    Number(c2[2] || 0),
    Number(c2[3] || 0),
  ];

  const col = `hsl(${Math.floor(lerp(c1h, c2h, ratio))} ${Math.floor(
    lerp(c1s, c2s, ratio)
  )}% ${Math.floor(lerp(c1l, c2l, ratio))}%)`;

  return col;
};

export const getTriangleBackground = (
  color1: string,
  color2: string,
  accent = 1
) => {
  const key = `${color1}-${color2}-${accent}`;
  if (cache[key]) {
    return cache[key];
  }

  const svg: SVGViewElement = new Triangulr(width, height, 100, 50, (path) =>
    colorRender(path, color1, color2, accent)
  );
  svg.setAttribute("width", String(width));
  svg.setAttribute("height", String(height));
  svg.setAttribute("preserveAspectRatio", "xMidYMid slice");
  cache[key] = svg;
  return svg;
};
