<template>
  <div v-if="trad" class="relative overflow-y-hidden">
    <!-- <div class="absolute top-0 left-0 h-full w-full bg-center bg-cover" :style="{ backgroundImage: `url('${ trad.cover }')` }"></div> -->

    <!-- Progress -->
    <teleport to="#bg-container">
      <div
        v-if="time >= 0"
        class="absolute top-0 left-0 h-full w-full overflow-x-hidden"
      >
        <div
          class="absolute top-0 left-0 h-full w-full bg-gradient-to-br from-primary to-primary-focus"
        >
          <BgTriangles color1="--pf" color2="--p" />
        </div>
        <div
          class="absolute top-0 left-0 h-full w-full overflow-x-hidden"
          :style="{ transform: `translateX(${progress * 100 - 100}%)` }"
        >
          <div
            class="absolute top-0 left-0 h-full w-full bg-gradient-to-br from-secondary to-secondary-focus"
            :style="{ transform: `translateX(${100 - progress * 100}vw)` }"
          >
            <BgTriangles color1="--sf" color2="--s" />
          </div>
        </div>
      </div>
    </teleport>

    <!-- <teleport to="body">
      <div class="absolute top-0 left-0 h-full w-full bg-base-100"></div>
    </teleport> -->

    <!-- <div class="absolute top-0 left-0 h-full w-full bg-success" :style="{ transform: `translateY(${ 100 - progress * 100 }%)` }"></div>
    <div class="absolute top-0 left-0 h-full w-full bg-success" :style="{ transform: `translateY(${ 100 - progress * 100 }%)` }"></div>

    <div class="gradiant absolute top-0 left-0 h-full w-full"></div> -->

    <div
      class="px-6 relative flex flex-col justify-between items-center text-neutral-content min-h-screen"
    >
      <h2 class="title-2">
        <span class="text-primary-content">{{ trad.title }}</span>
        <span v-if="set.data === SetData.Left"> (gauche)</span>
        <span v-else-if="set.data === SetData.Right"> (droite)</span>
        <sup>
          <Level :value="exercice?.level" class="ml-4"></Level>
        </sup>
      </h2>

      <div class="text-center">
        <figure class="mb-5 relative">
          <!-- Cover image -->
          <img :src="trad.cover" class="rounded-box max-h-[50vh]" />

          <!-- Objective -->
          <div class="absolute bottom-2 right-2">
            <ObjectiveBadge :set="set"></ObjectiveBadge>
          </div>
        </figure>

        <!-- Delay -->
        <AdditionalPause
          v-if="time < 0"
          :duration="pause.time * 1000"
          :freePause="isFreePause"
          :isUserPause="isUserPause"
          @play="play"
          @start="onStart"
          @overtime="onOvertime"
          @cancel="openCancelModal"
        >
        </AdditionalPause>
      </div>

      <template v-if="time >= 0">
        <!-- Chrono -->
        <div v-if="isTimerDisplay" class="flex justify-center text-center">
          <div
            class="flex flex-col py-2 px-3 bg-neutral card text-neutral-content"
          >
            <span class="font-mono">
              <span class="text-6xl">{{ timeDisplay }}</span
              ><span v-if="props.set.time" class="opacity-50">
                / {{ timeMaxDisplay }}</span
              >
            </span>
            time
          </div>
        </div>

        <!-- Reps -->
        <div class="flex justify-center text-center">
          <div
            v-if="isRepsDisplay"
            class="flex flex-col py-2 px-3 bg-neutral card text-neutral-content"
          >
            <span class="font-mono">
              <span class="text-6xl">{{ reps }}</span
              ><span v-if="set.value" class="opacity-50"> / {{ goal }}</span>
            </span>
            reps
          </div>
        </div>

        <button
          @click="endSet()"
          class="btn btn-wide"
          :class="{ 'btn-success': isSuccess, 'btn-accent': !isSuccess }"
        >
          Terminé, suivant !
        </button>
      </template>

      <SessionProgress
        :sets="realSets"
        class="w-full text-primary-content"
      ></SessionProgress>
    </div>

    <FitReps
      v-if="isEnded"
      :exerciceModel="model"
      :history="history"
      @submit="submit"
    ></FitReps>

    <SetCancel
      :is-open="isCancelModalOpen"
      :set="set"
      @close="closeCancelModal"
      @pause="addUserPause"
    ></SetCancel>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, onUnmounted, watch } from "vue";
import { useSportStore } from "../../store";
import type { Set } from "../../../../backend/src/types/Set";
import { SetData } from "../../../../backend/src/types/Set";
import Level from "../exercices/Level.vue";
import { getExercice, getExerciceTrad } from "../../helpers/exercice";
import { getChronoString } from "../../helpers/time";
import {
  ExerciceAlter,
  ExerciceModel,
  ExerciceSubject,
} from "../../../../backend/src/types/Exercice";
import { getDoSet } from "../../services/api";
import SessionProgress from "../../views/session/SessionProgress.vue";
import AdditionalPause from "../session/AdditionalPause.vue";
import router from "../../router";
import audioManager, { SoundName } from "../../services/audioManager";
import { checkOvertime } from "../../helpers/set";
import { lwa } from "../../../../backend/src/helpers/curve";
import type { RepsHistory } from "./FitReps.vue";
import FitReps from "./FitReps.vue";
import BgTriangles from "../ui/BgTriangles.vue";
import ObjectiveBadge from "./ObjectiveBadge.vue";
import SetCancel from "./SetCancel.vue";

const props = withDefaults(
  defineProps<{
    set: Set;
    double?: Set | null;
    pause: Set | { time: number };
  }>(),
  {
    double: null,
  }
);

const sportStore = useSportStore();

const goal = computed(() => props.double?.value || props.set.goal);

const model = computed(() => getExercice(props.set.exercice)?.model);

const history: RepsHistory[] = [];

const isUserPause = ref(false);

// Cancel modal
const isCancelModalOpen = ref(false);
const openCancelModal = () => {
  isUserPause.value = true;
  isCancelModalOpen.value = true;
};
const closeCancelModal = () => {
  isUserPause.value = false;
  isCancelModalOpen.value = false;
};
const addUserPause = () => {
  isUserPause.value = true;
  isCancelModalOpen.value = false;
};
const play = () => {
  isUserPause.value = false;
  isCancelModalOpen.value = false;
};

// Set session
const sets = computed(() =>
  sportStore.sets.sets.filter(({ workout }) => workout === props.set.workout)
);
const realSets = computed(() =>
  sets.value.filter(
    (set) => getExercice(set.exercice)?.model !== ExerciceModel.None
  )
);

// Trads
const trad = computed(() => getExerciceTrad(props.set.exercice));

// Exercice
const exercice = computed(() => getExercice(props.set.exercice));

// Start
const FRAME_MS = 16;
let to: number;
const startTime = ref(Date.now());
const time = ref(-1);
const onStart = async (pauseTime: number) => {
  startTime.value = Date.now();
  to = setTimeout(updateTimer, FRAME_MS);

  if (!isFreePause.value) {
    const newSet: Set = Object.assign({}, props.pause) as Set;
    newSet.value = pauseTime / 1000;
    newSet.time = pauseTime / 1000;
    await doSet(newSet);
  }
};
const updateTimer = () => {
  clearTimeout(to);
  to = setTimeout(updateTimer, FRAME_MS);
  time.value = Date.now() - startTime.value;
};
onUnmounted(() => clearTimeout(to));

// Timer
const reps = computed(() => {
  const goalTime = exercice.value?.time || 1;
  const goalValue = exercice.value?.value || 1;
  // const rythm = (exercice.value?.value || 1) / goalTime;
  const t = time.value / 1000;
  const reps = lwa({
    x: t,
    baseEquation: { x1: 0, x2: goalTime, y1: 0, y2: goalValue },
    accelerations: [
      // { x1: goalTime * 2, x2: goalTime * 4, mult: 2 }, // 2x faster
      // { x1: goalTime * 4, x2: goalTime * 6, mult: 3 / 2 }, // 3x faster
    ],
  });

  return Math.floor(reps);
});
const timeDisplay = computed(() => getChronoString(time.value));
const timeMaxDisplay = computed(() => getChronoString(goal.value * 1000));
const isFreePause = computed(() => !(props.pause as Set).id);
const progress = computed(
  () =>
    ((time.value / 1000) * (exercice.value?.value || 1)) /
    (exercice.value?.time || 1) /
    props.set.value
);

watch(reps, (val) => {
  history.push({ time: time.value, now: Date.now(), reps: val });
  if (reps.value === goal.value) {
    return audioManager.play(SoundName.Win);
  }

  switch (model.value) {
    case ExerciceModel.RepsAlter: {
      const soundName = val % 2 ? SoundName.Beep1 : SoundName.Beep2;
      return audioManager.play(soundName);
    }
    case ExerciceModel.Reps:
    case ExerciceModel.RepsLeftRight:
      return audioManager.play(SoundName.Beep1);
    case ExerciceModel.Isometric:
    case ExerciceModel.IsometricLeftRight: {
      // 17 - 70
      const stateTime = Math.max(5, Math.floor(props.set.goal / 4));
      if (
        val % stateTime === 0 &&
        val > 0 &&
        val !== Math.floor(goal.value / 4) * 4
      ) {
        return audioManager.play(SoundName.Beep1);
      }
    }
  }
});

// Success
const isSuccess = computed(() => reps.value >= goal.value);

// Display
const isTimerDisplay = computed(
  () =>
    !!exercice.value?.infos.find(
      (info) =>
        info.subject === ExerciceSubject.MuscleGlobal &&
        info.alter === ExerciceAlter.WorkIsometric
    )
);
const isRepsDisplay = computed(() => !isTimerDisplay.value);

// Submit
const doSet = getDoSet();
const isEnded = ref(false);
const endSet = () => {
  clearTimeout(to);
  isEnded.value = true;
};
const submit = async (selectedHistory: RepsHistory) => {
  const newSet: Set = Object.assign({}, props.set) as Set;
  newSet.value = selectedHistory.reps;
  newSet.time = selectedHistory.time / 1000;

  await doSet(newSet);
};

// Overtime
const onOvertime = (ms: number) => {
  checkOvertime({
    more: ms,
    router,
    sets: sets.value,
    sportStore,
  });
};
</script>

<style lang="scss" scoped>
.gradiant {
  background-image: radial-gradient(
    circle,
    rgba(0, 0, 0, 0),
    rgba(82, 25, 2, 0.25)
  );
}
</style>
