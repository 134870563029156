import type { Equipment } from "../types/Equipment"
import type { Exercice, ExerciceSmall, ExerciceInfo } from "../types/Exercice"
import { ExerciceAlter, ExerciceModel, ExerciceSubject, exerciceSubjectMuscles } from "../types/Exercice"
import { getTree } from "./exercicesTree"

export type ExerciceDistance = {
  exercice: Exercice;
  distance: number;
}

function round (num: number, unit: number = 5) : number {
  return Math.ceil(num / unit) * unit
}

export const formatSmallExercice = (exercice: Exercice): ExerciceSmall => ({
  id: exercice.id,
  model: exercice.model,
  value: exercice.value,
  time: exercice.time,
  level: Math.floor(exercice.level),
  infos: exercice.infos,
  // links: exercice.links
})

export const isAerobic = (ex: ExerciceSmall) => !!ex.infos?.find(info =>
  info.subject === ExerciceSubject.MuscleGlobal && info.alter === ExerciceAlter.WorkAerobic
)

export const isStrength = (ex: ExerciceSmall) => !!ex.infos?.find(info =>
  info.subject === ExerciceSubject.MuscleGlobal && info.alter === ExerciceAlter.WorkStrength
)

export const isIsometric = (ex: ExerciceSmall) => !!ex.infos?.find(info =>
  info.subject === ExerciceSubject.MuscleGlobal && info.alter === ExerciceAlter.WorkIsometric
)

export const isFlexibility = (ex: ExerciceSmall) => !!ex.infos?.find(info =>
  info.subject === ExerciceSubject.MuscleGlobal && info.alter === ExerciceAlter.WorkFlexibility
)

export const isModelLeftRight = (model?: ExerciceModel) => model === ExerciceModel.IsometricLeftRight || model === ExerciceModel.RepsLeftRight

export const getMainMuscles = (exercice: Exercice, min: number = 0.8): ExerciceInfo[] => {
  return exercice.infos?.filter(info => (
    exerciceSubjectMuscles.indexOf(info.subject) > -1
    && info.subject !== ExerciceSubject.MuscleGlobal
    // && [ExerciceAlter.WorkStrength, ExerciceAlter.WorkIsometric, ExerciceAlter.WorkInterval, ExerciceAlter.WorkFlexibility, ExerciceAlter.WorkRelaxation].indexOf(info.alter) > -1
    && info.value >= min
    )) || []
}

export const searchExercicesByWorkMuscle = (exercices: Exercice[], work: ExerciceAlter, muscle: ExerciceSubject, power: number ) => {
  const hasMuscle = (exercice: Exercice) => exercice.infos
    ?.find(info => info.alter === work && info.subject === muscle && info.value >= power)

  const list = exercices.filter(hasMuscle)
  list.sort((a, b) => a.level - b.level)
  return list
}

export const getSequences = (exercices:Exercice[]):Exercice[][] => {
  const maxLevel = exercices.reduce((max, exercice) => Math.max(max, exercice.level), 0)
  const rest = [...exercices]
  const sequences:Exercice[][] = []

  const addExerciceToSequence = (exercice: Exercice, sequence: Exercice[] = []) => {
    sequence.push(exercice)

    if (exercice.links && exercice.links.length > 0) {
      const harderId = exercice.links[0].id
      const harder = rest.find(({ id }) => id === harderId)
      if (harder) {
        rest.splice(rest.indexOf(harder), 1)
        addExerciceToSequence(harder, sequence)
      }
    }

    return sequence
  }

  for (let level = 0; level <= maxLevel; level++) {
    rest
      .filter(exercice => exercice.level === level)
      .forEach(exercice => {
        if (exercice.links && exercice.links.length > 0) {
          sequences.push(addExerciceToSequence(exercice, []))
        }
        rest.splice(rest.indexOf(exercice), 1)
      })
  }

  return sequences
}

/**
 * Get links exercices for each sequences
 */
export const getHarderSequenced = (list:Exercice[], all:Exercice[]):Exercice[] => {
  const rest = [...list]
  const links: Exercice[] = []
  const sequences = getSequences(all)

  sequences.forEach(exercices => {
    let found = false
    for (let i = exercices.length - 1; i > -1; i--) {
      const exercice = exercices[i]
      const restId = rest.findIndex(ex => ex.id === exercice.id)
      if (restId > -1) {
        if (!found) {
          links.push(exercice)
          found = true
        }
        rest.splice(restId, 1)
      }
    }
  })

  return links
}

export const exercicesEasier = (exercices:Exercice[], allExercices:Exercice[] = exercices):Exercice[] => {
  const isEasier = (exercice:Exercice):boolean => 
    exercice.level < 3 && !allExercices.find(allEx => allEx.links?.find(harder => harder.id === exercice.id && harder.replace))
  
  return exercices.filter(isEasier)
}

// /**
//  * All links exercices per sequence unlocked for the user
//  */
// export const getUserHarders = (userExercices:Exercice[], allExercices:Exercice[]):Exercice[] => {
//   return getAllHarders(userExercices, allExercices)
//     .filter(ex => userExercices.find(({ name }) => name === ex.name))
// }

/**
 * All links exercices for a user (unlocked and unlockables)
 */
export const removeEasiers = (userExercices:Exercice[], allExercices:Exercice[]) => {
  
  const hasHarder = (ex: Exercice): boolean => {
    const harderId = ex.links?.find(harder => harder.replace)?.id
    const harderEx = allExercices.find(ex2 => ex2.id === harderId)
    if (harderId && userExercices.find(ex2 => ex2.id === harderId)) {
      return true
    }
    if (harderEx) {
      return hasHarder(harderEx)
    }
    return false
  }

  const hasNotHarder = (ex: Exercice) => !hasHarder(ex)

  return userExercices.filter(hasNotHarder)
}

export function getGoalValueToUnlock(exercice: Exercice, harder: Exercice): number {
  const difficulty = harder.difficulty / exercice.difficulty
  switch (exercice.model) {
    case ExerciceModel.Isometric :
      return exercice.value * difficulty
    case ExerciceModel.IsometricLeftRight :
      return exercice.value * difficulty
    case ExerciceModel.Reps :
      return exercice.value * difficulty
    case ExerciceModel.RepsAlter :
      return exercice.value * difficulty
    case ExerciceModel.RepsLeftRight :
      return exercice.value * difficulty
    case ExerciceModel.Run :
    case ExerciceModel.None :
      return 0
  }
}

export function canUnlock(exercice: Exercice, value: number, harder: Exercice): boolean {
  // medium = minimum de répétition faisable pour l'exercice plus difficile
  const difficulty = harder.difficulty / exercice.difficulty
  switch (exercice.model) {
    case ExerciceModel.Isometric :
      return value / difficulty >= exercice.value
    case ExerciceModel.IsometricLeftRight :
      return value / difficulty >= exercice.value
    case ExerciceModel.Reps :
      return value / difficulty >= exercice.value
    case ExerciceModel.RepsLeftRight :
      return value / difficulty >= exercice.value
    case ExerciceModel.RepsAlter :
      return value / difficulty >= exercice.value
    // case ExerciceModel.Aerobic :
    //   return value / difficulty >= exercice.medium[0] && values[1] / difficulty >= exercice.medium[1]
    case ExerciceModel.Run :
    case ExerciceModel.None :
      return false
      // TODO

      // const context = {
      //   values,
      //   count
      // }
      // isHarderUnlockable = await parseExpression(exercice.exh_condition, context)
  }
}

export function getNextExercice(currentEx: Exercice, allExercices: Exercice[], userEquipment: { count: number; name: Equipment; }[], banned: number[]) {
  const tree = getTree(allExercices, userEquipment, banned)
  return tree.getNextExercice(currentEx)
}

export function getUnlockable(currentEx: Exercice, value: number, userExercices: Exercice[], allExercices: Exercice[], userEquipment: { count: number; name: Equipment; }[], banned: number[]) {
  const next = getNextExercice(currentEx, allExercices, userEquipment, banned)
  if (next && !userExercices.find(ex => ex.id === next.id) && canUnlock(currentEx, value, next)) {
    return next
  }
  return null
}

/**
 * All unlockables exercices for a user.
 * An unlockable exercice are:
 * - not already in the user exercice list
 * - same level of 
 *    - the highter unlocked level for each sequence and highter unlocked level -1
 *    - if no unlocked level in the list: level 1 and 2
 */
export const getUnlockables = (userExercices:Exercice[], allExercices:Exercice[], userEquipment: { count: number; name: Equipment; }[], banned: number[]):Exercice[] => {
  const tree = getTree(allExercices, userEquipment, banned)

  const unlockables = tree.variantGroups
    .map(group => {
      const groupExercices = group.flat(2)
        
      const userGroupExercices = groupExercices
        .filter(exercice => userExercices.find(({ id }) => id === exercice.id))

      userGroupExercices.sort((a, b) => Math.floor(a.level) - Math.floor(b.level))

      const maxLevel = Math.floor((userGroupExercices.pop() || { level: 1 }).level)

      const groupUnlockables = groupExercices
        .filter(ex => Math.floor(ex.level) >= maxLevel - 1 && Math.floor(ex.level) <= maxLevel)

      return groupUnlockables
    })
    .flat(2)
    .filter(exercice => !userExercices.find(({ id }) => id === exercice.id))

  return unlockables
    // Remove links
    .filter(exercice =>
      !unlockables.find(ex => ex.links?.find(harder => harder.replace && exercice.id === harder.id))
    )
}

/**
 * Sort exercices by muscular similarities
 */
export const exercicesDistanceByMuscles = (exercice: Exercice, exercices:Exercice[]): ExerciceDistance[] => {

  // Extract infos if info is about muscles
  const getMusclesInfos = (ex:Exercice):ExerciceInfo[] => ex.infos?.filter(info => exerciceSubjectMuscles.find(muscle => muscle === info.subject)).filter(info => info.subject !== ExerciceSubject.MuscleGlobal) || []

  const getSameMuscleWork = (info: ExerciceInfo, list: ExerciceInfo[]) => list.find(bInfo => info.subject === bInfo.subject && info.alter === bInfo.alter)

  const distance = (a: Exercice, b:Exercice) => {
    const aInfos = getMusclesInfos(a)
    const bInfos = getMusclesInfos(b)

    const same = aInfos.filter(aInfo => getSameMuscleWork(aInfo, b.infos || []))
    const notSameMuscleCount = aInfos.length + bInfos.length - (2 * aInfos.filter(aInfo => b.infos?.find(bInfo => aInfo.subject === bInfo.subject)).length)
    const notSameWorkCount = aInfos.filter(aInfo => b.infos?.find(bInfo => aInfo.subject === bInfo.subject && aInfo.alter !== bInfo.alter)).length

    const sameScores = same.map(aInfo => 1 - Math.abs(aInfo.value - (getSameMuscleWork(aInfo, b.infos || [])?.value || 0)))
    const score = (sameScores.reduce((prev: number, curr: number) => prev + curr, 0) + same.length) / (same.length * 2 + notSameMuscleCount + notSameWorkCount)

    return 1 - score
  }

  const list: ExerciceDistance[] = exercices.map(ex => ({
    distance: distance(exercice, ex),
    exercice: ex
  }))

  list.sort((a: ExerciceDistance, b: ExerciceDistance) => a.distance - b.distance)
  return list
}


/**
 * Sort exercices by level similarities
 */
export const exercicesDistanceByLevel = (exercice: Exercice, exercices:Exercice[]): ExerciceDistance[] => {

  const MAX_LEVEL_DISTANCE = 3
  const distance = (a: Exercice, b:Exercice) => {
    return Math.min(Math.abs(a.level - b.level), MAX_LEVEL_DISTANCE) / MAX_LEVEL_DISTANCE
  }

  const list: ExerciceDistance[] = exercices.map(ex => ({
    distance: distance(exercice, ex),
    exercice: ex
  }))

  list.sort((a: ExerciceDistance, b: ExerciceDistance) => a.distance - b.distance)
  return list
}

// export function getExerciceLevel (exercice: Exercice, allExercices: Exercice[]): number {
//   const recurs = (exercice: Exercice): number => {
//     const easier = allExercices.find(ex => ex.links && ex.links.find(harder => harder.name === exercice.name))
//     if (easier) {
//       const harderData = easier.links && easier.links.find(harder => harder.name === exercice.name)
//       return recurs(easier) * (harderData?.condition ? Number(harderData?.condition) : 1)
//     }

//     return 1
//   }

//   return recurs(exercice)
// }

// export function getValuesPoints (model: ExerciceModel, values: number[], medium: number[]): number {
//   switch (model) {
//     case ExerciceModel.Reps : // 10 reps = 1 pts
//       return (values[0] || 0) / medium[0];
//     case ExerciceModel.RepsLeftRight : // 2x 10 reps = 2 pts
//       return (values[0] || 0) / medium[0] + (values[1] || 0) / medium[1];
//     case ExerciceModel.Isometric : // 20s = 1 pts
//       return (values[0] || 0) / medium[0];
//     case ExerciceModel.IsometricLeftRight :  // 2x 20s = 2 pts
//       return (values[0] || 0) / medium[0] + (values[1] || 0) / medium[1];
//     // case ExerciceModel.Aerobic : 
//     //   return ((values[0] || 0) / medium[0] + (values[1] || 0) / medium[1]) / 2; // 30s + 30 reps = 1pts
//     case ExerciceModel.Run : 
//       return 1
//     case ExerciceModel.None : 
//       return 0
//   }
// }

export function getUnit (model: ExerciceModel): string {
  switch (model) {
    case ExerciceModel.Reps :
      return 'reps';
    case ExerciceModel.RepsAlter :
      return 'reps';
    case ExerciceModel.RepsLeftRight :
      return 'reps';
    case ExerciceModel.Isometric :
      return 'sec';
    case ExerciceModel.IsometricLeftRight :
      return 'sec';
    case ExerciceModel.Run : 
      return '?'
    case ExerciceModel.None : 
      return 'sec'
  }
}

export function isTimeUnit (model: ExerciceModel) {
  switch (model) {
    case ExerciceModel.Isometric :
    case ExerciceModel.IsometricLeftRight :
      return true;
    default : 
      return false;
  }
}

export function getScore (exercice: Exercice, value: number): number {
  const md = exercice.value
  const diff = exercice.difficulty
  switch (exercice.model) {
    case ExerciceModel.Reps : // 10 reps, difficulty 1 = 1 pts
      return (value / md) * diff;
    case ExerciceModel.RepsAlter :  // 2x 20s, difficulty 1 = 1 pts
      return (value / md) * diff;
    case ExerciceModel.RepsLeftRight : // 2x 10 reps, difficulty 1 = 1 pts
      return (value / md) * diff;
    case ExerciceModel.Isometric : // 20s, difficulty 1 = 1 pts
      return (value / md) * diff;
    case ExerciceModel.IsometricLeftRight :  // 2x 20s, difficulty 1 = 1 pts
      return (value / md) * diff;
    // case ExerciceModel.Aerobic :  // 30s + 30 reps, difficulty 1 = 1 pts
    //   return ((value / md + v1 / md[1]) / 2) * diff;
    case ExerciceModel.Run : 
      return 1 * diff
    case ExerciceModel.None : 
      return 0
  }
}

export function valueFromScore (exercice: Exercice, score: number) {
  const md = exercice.value
  const diff = exercice.difficulty
  switch (exercice.model) {
    case ExerciceModel.Reps :
      return score * md / diff;
    case ExerciceModel.RepsAlter :
      return score * md / diff;
    case ExerciceModel.RepsLeftRight :
      return score * md / diff
    case ExerciceModel.Isometric :
      return score * md * 2 / diff;
    case ExerciceModel.IsometricLeftRight :
      return score * md * 2 / diff
    // case ExerciceModel.Aerobic :
    //   // v0 / v1 = md / md[1]
    //   // v1 = v0 * md[1] / md
    //   const v0 = score * md / diff
    //   return [v0, v0 * md[1] / md]
    case ExerciceModel.Run : 
      return 0
    case ExerciceModel.None : 
      return 0
  }
}

export function getIncreaseAchievment (exercice: Exercice, previousValue: number, newValue: number): number {
  const objectiveValue = getIncrease(exercice, previousValue, 1.1)
  const previousPts = getScore(exercice, previousValue)
  const objectivePts = getScore(exercice, objectiveValue)
  const newPts = getScore(exercice, newValue)
  const increaseAchievment = (newPts - previousPts) / (objectivePts - previousPts)
  return isNaN(increaseAchievment) || !isFinite(increaseAchievment) ? 0 : increaseAchievment;
}

export function getIncrease (exercice: Exercice, value: number, multiplicator: number = 1.1): number {

  switch (exercice.model) {
    case ExerciceModel.Reps :
      return Math.ceil(value * multiplicator)
    case ExerciceModel.RepsAlter :
      return Math.ceil(value * multiplicator)
    case ExerciceModel.RepsLeftRight :
      return Math.ceil(value * multiplicator)
    case ExerciceModel.Isometric :
      return round(value * multiplicator, 5)
    case ExerciceModel.IsometricLeftRight :
      return round(value * multiplicator, 5)
    // case ExerciceModel.Aerobic :

    //   const velocity = (values[0] || exercice.medium[0]) / (values[1] || exercice.medium[1])
    //   const velocityGoal = exercice.medium[0] / exercice.medium[1]
    //   const doubleMultiplicator = (multiplicator - 1) * 2 + 1

    //   const velocityDistance = velocity / velocityGoal - 1
    //   const DISTANCE_MAX = 0.35
    //   let time, reps
    //   if (velocityDistance > DISTANCE_MAX) {
    //     reps = values[0] || exercice.medium[0]
    //     time = round((values[1] || exercice.medium[1]) * doubleMultiplicator, 5)
    //   } else if (velocityDistance < -DISTANCE_MAX) {
    //     reps = Math.ceil((values[0] || exercice.medium[0]) * doubleMultiplicator)
    //     time = values[1] || exercice.medium[1]
    //   } else {
    //     reps = Math.ceil((values[0] || exercice.medium[0]) * multiplicator)
    //     time = round((values[1] || exercice.medium[1]) * multiplicator, 5)
    //   }
      
    //   return [ reps, time ]

    case ExerciceModel.Run : 
      // TODO
      // if (!values[0]) {
      //   return [null, (values[1] || 0) + 0.8]
      // } else if (!values[1]) {
      //   return [Math.min(values[0] + 5, 30)]
      // } else if (values[1] && values[0] > 25) {
      //   return [ 30, values[1] + 0.1 ]
      // } else {
      //   return [ values[0] + 5, (values[0] + 5) * values[1] / values[0] ]
      // }
      return 0
    
    case ExerciceModel.None :
      return 0
  }
}

export function exerciceDifficultyToExerciceLevel (difficulty: number) {
  const MIN_DIFFICULTY = 1
  const BASE_LEVEL = 1

  // log2(1) = 0
  return Math.max(0, Math.log2(difficulty + 1 - MIN_DIFFICULTY) * 2 + BASE_LEVEL)
}
