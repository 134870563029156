<template>
  <!-- <div
    class="absolute top-0 left-0 w-full h-full bg-gradient-to-tr from-primary to-secondary text-primary-content"
  ></div> -->

  <main class="page-container relative flex flex-col flex-1">
    <Logo class="mb-14 md:hidden"></Logo>
    <div class="m-auto max-w-sm">
      <div
        v-for="(notif, i) of notifs"
        :key="JSON.stringify(notif)"
        class="flex items-center mb-3"
        :class="`appear-top-${i}`"
      >
        <div class="pr-3">
          <UserAvatar :src="notif.user.avatar"></UserAvatar>
        </div>
        <p>
          <span
            v-html="notif.resume"
            class="relative leading-5 block whitespace-normal"
          ></span>
          <span class="block opacity-50 text-xs mt-0 first-letter:uppercase">{{
            notif.date
          }}</span>
        </p>
      </div>
    </div>
  </main>
</template>

<script lang="ts" setup>
import { getNotifs } from "../services/api";
import { computed, ref } from "vue";
import Logo from "../components/ui/Logo.vue";
import UserAvatar from "../components/user/UserAvatar.vue";
import { getNotifText } from "../trads/fr/notifs";
import { displayPastDateTime } from "@/helpers/time";

const notifsSrc = ref<Awaited<ReturnType<typeof getNotifs>>["notifs"]>([]);

const notifs = computed(() =>
  notifsSrc.value.map((notifSrc) => ({
    user: notifSrc.user,
    name: notifSrc.name,
    data: notifSrc.data,
    date: `${displayPastDateTime(new Date(notifSrc.date).getTime())}`,
    resume: getNotifText(notifSrc),
  }))
);

getNotifs().then((n) => (notifsSrc.value = n.notifs));
</script>

<style lang="scss" scoped></style>
