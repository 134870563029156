<template>
  <div class="text-right">
    <p class="text-xs mb-2 italic">
      <span v-if="real.length - todo.length"
        >{{ real.length - todo.length }} /
      </span>
      {{ real.length }} exercices
    </p>
    <ul class="relative w-full flex rounded-xl text-xs overflow-hidden mb-5">
      <span
        class="absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-r from-base-100 via-base-100 opacity-50 to-transparent"
      ></span>
      <li
        v-for="(set, index) of real"
        :data-content="iconSetPassed(set)"
        class="relative flex-1 text-center py-1"
        :class="{
          'bg-success': isDone(set) || isTop(set) || isTry(set),
          'bg-error': isFailed(set),
          // 'bg-[#000]': isTodo(set),
        }"
        :key="set.id"
      >
        <span v-if="isTodo(set)" class="opacity-75">
          {{ index + 1 }}
        </span>
        <BonusStar
          v-else
          :bonus="set.bonus"
          class="inline-block align-bottom"
        />
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { getExercice } from "@/helpers/exercice";
import type { PropType } from "vue";
import { computed } from "vue";
import { ExerciceModel } from "../../../../backend/src/types/Exercice";
import type { Set } from "../../../../backend/src/types/Set";
import { SetStatus } from "../../../../backend/src/types/Set";
import BonusStar from "../../components/set/BonusStar.vue";

const props = defineProps({
  sets: {
    type: Array as PropType<Set[]>,
    default() {
      return [];
    },
  },
});

const real = computed(() =>
  props.sets.filter(
    (set) => getExercice(set.exercice)?.model !== ExerciceModel.None
  )
);
const todo = computed(() =>
  real.value.filter((set) => set.status === SetStatus.Todo)
);

const isTodo = (set: Set) => {
  return set.status === SetStatus.Todo;
};

const isFailed = (set: Set) => {
  return set.bonus <= 0 && !isTodo(set);
};

const isTry = (set: Set) => {
  return set.bonus > 0 && set.bonus < 5 && !isTodo(set);
};

const isDone = (set: Set) => {
  return set.bonus >= 5 && set.bonus <= 10 && !isTodo(set);
};

const isTop = (set: Set) => {
  return set.bonus > 10 && !isTodo(set);
};

const iconSetPassed = (set: Set) => {
  if (isTodo(set)) {
    return ``;
  } else if (isFailed(set)) {
    return `✕`;
  } else if (isTry(set)) {
    return `!`;
  } else if (isDone(set)) {
    return `✓`;
  }

  return `★`;
};
</script>

<style lang="scss" scoped></style>
