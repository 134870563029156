<template>
  <!-- <SimpleBackground /> -->
  <template v-if="!isLoading">
    <!-- <div class="absolute top-0 left-0 w-full h-full bg-cover bg-center" :style="{ backgroundImage: `url(https://img.wallpapersafari.com/desktop/1920/1080/27/63/K89E4N.jpg)` }"></div> -->

    <Teleport to="#bg-container">
      <BgTriangles color1="--b1" color2="--b3" />
    </Teleport>

    <div class="flex md:flex-row flex-1">
      <nav
        v-if="$route.meta.menu"
        class="md:w-64 md:flex md:flex-col md:mb-[15vh]"
      >
        <router-link to="/" class="hidden mb-14 md:mb-auto md:block">
          <Logo class="scale-75 origin-left"></Logo>
        </router-link>
        <Menu class="md:mb-auto"></Menu>
      </nav>

      <div
        class="w-full mx-auto flex flex-col relative md:max-h-screen md:overflow-y-auto"
      >
        <Header :prev="parentRoute" v-if="$route?.meta?.title">{{
          $route?.meta?.title
        }}</Header>
        <RouterView />
      </div>
    </div>

    <MobileKeyboardFix></MobileKeyboardFix>
    <EventsLayer v-if="$route.meta.menu"></EventsLayer>
  </template>

  <div v-else>
    <Loader class="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-full" />
  </div>

  <Toasts></Toasts>

  <Update></Update>
</template>

<script lang="ts" setup>
import { ref, onUnmounted } from "vue";
import { refresh } from "./services/api";
import EventsLayer from "./components/EventsLayer.vue";
import Menu from "./components/nav/Menu.vue";
import Update from "./components/Update.vue";
import MobileKeyboardFix from "./components/MobileKeyboardFix.vue";
import { useRoute, useRouter } from "vue-router";
import emitter from "./services/emitter";
import { Roles, RouteName } from "./types/route";
import loader from "./helpers/loader";
import { useMeStore } from "./store/meStore";
// import SimpleBackground from "./components/ui/SimpleBackground.vue";
import Loader from "./components/Loader.vue";
import { useSportStore } from "./store";
import Header from "./components/ui/Header.vue";
import { computed } from "@vue/reactivity";
import BgTriangles from "./components/ui/BgTriangles.vue";
import Logo from "./components/ui/Logo.vue";
import Toasts from "./components/ui/Toasts.vue";
import { useCreateToast } from "./store/apiToastStore";

const router = useRouter();
const route = useRoute();
const isReady = ref(false);
const sportStore = useSportStore();
const meStore = useMeStore();
const isLoading = ref(false);
const createToast = useCreateToast();

createToast(loader(refresh(sportStore), { isEnded: isReady, isLoading }), {
  progress: "Chargement de vos données",
  success: "Données chargées",
  fail: "Erreur de chargement de vos données",
});

const onDisconnected = () => {
  meStore.disconnect();
  if (route.meta.auth !== Roles.Public) {
    router.push({ name: RouteName.Login });
  }
};
emitter.on("api:disconnected", onDisconnected);
onUnmounted(() => {
  emitter.off("api:disconnected", onDisconnected);
});

const parentRoute = computed(() =>
  router
    .getRoutes()
    .find(({ children }) =>
      children?.find((child) => child.name === route.name)
    )
);
</script>

<style lang="scss"></style>
