import { Equipment, equipmentList } from "../types/Equipment";
import type { Exercice } from "../types/Exercice";
import { parseExpression } from "./parse";

export const filterConditionByEquipments = (condition: string, equipments: {
  count: number;
  name: Equipment;
}[]): boolean => {
  const scope = {} as { [key in Equipment]: number; }
  equipmentList.forEach(equipment => {
    scope[equipment] = 0
  })
  equipments.forEach(equipment => {
    scope[equipment.name] = equipment.count
  })
  return parseExpression(condition, scope)
}

export const getEquipedExercices = (exercices: Exercice[], equipments: {
  count: number;
  name: Equipment;
}[]): Exercice[] => {
  return exercices.filter(ex => filterConditionByEquipments(ex.condition, equipments))
}
