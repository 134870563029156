export function displayPastDateTime(ms: number) {
  const now = new Date();
  const last = new Date(ms);
  const daysCount = (now.getTime() - ms) / (1000 * 3600 * 24);
  const isSameDay = last.toLocaleDateString() === now.toLocaleDateString();
  const isSameWeek =
    Math.abs(now.getTime() - ms) / (1000 * 60 * 60 * 24 * 6) < 1;
  const isSameYear = now.getFullYear() === last.getFullYear();
  const isSameMonth = now.getMonth() === last.getMonth() && isSameYear;

  if (daysCount * 3600 * 24 < 60) {
    return `à l'instant`;
  } else if (isSameDay) {
    return `il y a ${getBigChronoStringUnit(now.getTime() - ms)}`;
  } else if (isSameWeek) {
    return `${last.toLocaleString("fr", { weekday: "long" })} à ${getHoursMin(
      ms
    )}`;
  } else if (isSameMonth) {
    return `${last.toLocaleString("fr", {
      weekday: "long",
      day: "numeric",
    })} à ${getHoursMin(ms)}`;
  } else if (isSameYear) {
    return `${last.toLocaleString("fr", {
      weekday: "long",
      day: "numeric",
      month: "long",
    })} à ${getHoursMin(ms)}`;
  } else {
    return `${last.toLocaleString("fr", {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    })} à ${getHoursMin(ms)}`;
  }
}

export function displayTime(
  ms: number,
  { noDay, noSec }: { noDay?: boolean; noSec?: boolean } = {}
) {
  const sec = Math.floor(ms / 1000);
  const mn = Math.floor(sec / 60);
  const hours = Math.floor(mn / 60);
  const days = Math.floor(hours / 24);

  let out = "";
  if (days && !noDay) out = days + "jours";
  if (hours % 24) out += " " + (hours % 24) + "h";
  if (mn % 60) out += " " + (mn % 60) + "min ";
  if (sec % 60 && hours < 1 && !noSec) out += " " + (sec % 60) + "sec";

  return out;
}

export function getTime(ms: number) {
  const sec = Math.floor(ms / 1000);
  const mn = Math.floor(sec / 60);
  const hours = Math.floor(mn / 60);
  const days = Math.floor(hours / 24);

  return {
    ms: ms % 1000,
    sec: sec % 60,
    mn: mn % 60,
    hours: hours % 24,
    days,
  };
}

export function displayShortTime(ms: number) {
  const sec = Math.floor(ms / 1000);
  const mn = Math.floor(sec / 60);
  const hours = Math.floor(mn / 60);
  const days = Math.floor(hours / 24);

  let out = "";
  if (days) out = days + "j";
  if (hours % 24) out += (hours % 24) + "h";
  if (mn % 60) out += (mn % 60) + "min";
  4;
  // if (sec % 60 && hours < 1) out += (sec % 60) + ""

  return out;
}

export function displayDate(ms: number) {
  const today = new Date();
  const date = new Date(ms);
  const sameYear = date.getFullYear() === today.getFullYear();
  const sameMonth = date.getMonth() === today.getMonth();
  const sameWeek =
    Math.abs(today.getTime() - ms) / (1000 * 60 * 60 * 24 * 6) < 1;

  const options: Intl.DateTimeFormatOptions = { weekday: "long" };
  if (!sameYear) {
    options.year = "numeric";
  }
  if (!sameMonth) {
    options.month = "long";
  }
  if (!sameWeek) {
    options.day = "numeric";
  }

  return date.toLocaleDateString("fr-FR", options);
}

export function displayRangeDates(msA: number, msB: number) {
  const today = new Date();
  const dateA = new Date(msA);
  const dateB = new Date(msB);
  const sameYearA = dateA.getFullYear() === dateB.getFullYear();
  const sameYearB = dateB.getFullYear() === today.getFullYear();
  const sameMonthA = dateA.getMonth() === dateB.getMonth();
  // const sameMonthB = dateB.getMonth() === today.getMonth();
  // const sameWeekA = Math.abs(today.getTime() - msA) / (1000 * 60 * 60 * 24 * 6) < 1
  // const sameWeekB = Math.abs(today.getTime() - msA) / (1000 * 60 * 60 * 24 * 6) < 1

  const optionsA: Intl.DateTimeFormatOptions = { day: "numeric" };
  if (!sameYearA) {
    optionsA.year = "2-digit";
  }
  if (!sameMonthA) {
    optionsA.month = "short";
  }

  const optionsB: Intl.DateTimeFormatOptions = { day: "numeric" };
  if (!sameYearB) {
    optionsB.year = "2-digit";
  }
  optionsB.month = "short";

  return `${dateA.toLocaleDateString(
    "fr-FR",
    optionsA
  )} - ${dateB.toLocaleDateString("fr-FR", optionsB)}`;
}

export function displayFullDate(ms: number) {
  const today = new Date();
  const date = new Date(ms);
  const sameYear = date.getFullYear() === today.getFullYear();
  const sameMonth = date.getMonth() === today.getMonth();
  // const sameWeek = Math.abs(today.getTime() - ms) / (1000 * 60 * 60 * 24 * 6) < 1

  const options: Intl.DateTimeFormatOptions = { day: "numeric" };
  if (!sameYear) {
    options.year = "2-digit";
  }
  if (!sameMonth) {
    options.month = "short";
  }

  return date.toLocaleDateString("fr-FR", options);
}

// export function displayMonth(ms: number) {
//   const today = new Date()
//   const date = new Date(ms)
//   const sameYear = date.getFullYear() === today.getFullYear()
//   const sameMonth = date.getMonth() === today.getMonth()

//   const options: Intl.DateTimeFormatOptions = { month: "long" }
//   if (!sameYear) { options.year = "numeric" }

//   return date.toLocaleDateString("fr-FR", options)
// }

export function getChronoData(ms: number) {
  const millisec = ms % 1000;
  const seconds = Math.floor(ms / 1000) % 60;
  const minutes = Math.floor(ms / 60000);
  return {
    mn: minutes,
    sec: seconds,
    ms: millisec,
  };
}

export function getChronoString(ms: number) {
  let mn = Math.floor(ms / 60000).toFixed(0);
  let sec = (Math.floor(ms / 1000) % 60).toFixed(0);
  if (mn.length < 2) mn = "0" + mn;
  if (sec.length < 2) sec = "0" + sec;
  return `${mn}:${sec}`;
}

export function getChronoStringUnit(ms: number) {
  const { mn, sec } = getChronoData(ms);
  const times = [];
  if (mn) {
    times.push(`${mn} min`);
  }
  if (sec) {
    times.push(`${sec} sec`);
  }
  if (times.length < 1) {
    times.push("0 sec");
  }
  return times.join(" ");
}

export function getBigChronoStringUnit(ms: number) {
  const { mn } = getChronoData(ms);
  if (mn > 60) {
    return `${Math.floor(mn / 60)}h`;
  }
  if (mn) {
    return `${mn % 60}min`;
  }
}

export function getHoursMin(ms: number) {
  const [h, min] = new Date(ms).toLocaleTimeString("fr").split(":");
  return `${h}:${min}`;
}
