import type { RouteRecordRaw } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/Login.vue";
import Home from "../views/Home.vue";
import SessionWorkouts from "../views/session/SessionWorkouts.vue";
import WorkoutResume from "../views/session/WorkoutResume.vue";
import SessionDo from "../views/session/SessionDo.vue";
import WorkoutCustom from "../views/session/WorkoutCustom.vue";
import SessionResults from "../views/session/SessionResults.vue";
import admin from "./admin";
import { RouteName, Roles } from "../types/route";
import option from "./option";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: RouteName.Home,
    component: Home,
    meta: {
      menu: true,
      auth: Roles.User,
      title: "",
    },
  },
  {
    path: "/connexion",
    name: RouteName.Login,
    component: Login,
    meta: {
      menu: false,
      auth: Roles.Public,
      title: "",
    },
  },
  {
    path: "/session",
    name: RouteName.Session,
    component: SessionWorkouts,
    meta: {
      menu: true,
      auth: Roles.User,
      title: "Séances",
    },
    children: [
      {
        path: "/session/resume/:workout/:description?",
        name: RouteName.WorkoutResume,
        component: WorkoutResume,
        meta: {
          menu: true,
          auth: Roles.User,
          title: "Séance",
        },
      },
      {
        path: "/session/create/:description?",
        name: RouteName.WorkoutCustom,
        component: WorkoutCustom,
        meta: {
          menu: true,
          auth: Roles.User,
          title: "Séance personnalisée",
        },
      },
      {
        path: "/session/:workout",
        name: RouteName.SessionDo,
        component: SessionDo,
        meta: {
          menu: false,
          auth: Roles.User,
          title: "",
        },
      },
      {
        path: "/session/results/:workout/:description?",
        name: RouteName.SessionResults,
        component: SessionResults,
        meta: {
          menu: false,
          auth: Roles.User,
          title: "Résultats",
        },
      },
    ],
  },
  // {
  //   path: "/admin/sponsor",
  //   name: RouteName.AdminSponsor,
  //   component: () =>
  //     import(/* webpackChunkName: "admin" */ "../views/admin/Sponsor.vue"),
  //   meta: {
  //     menu: false,
  //     auth: Roles.Admin
  //   }
  // },
  // children: [
  //   {
  //     path: "/sets/:id/:description?",
  //     name: RouteName.Set,
  //     component: Set,
  //     meta: {
  //       menu: true,
  //       auth: Roles.User
  //     }
  //   },
  //   {
  //     path: "/admin/sponsor",
  //     name: RouteName.AdminSponsor,
  //     component: () =>
  //       import(/* webpackChunkName: "admin" */ "../views/admin/Sponsor.vue"),
  //     meta: {
  //       menu: false,
  //       auth: Roles.Admin
  //     }
  //   },
  // ]
  // },
  {
    // Temp page for display exercices
    path: "/exercice/:name",
    name: RouteName.Exercice,
    component: () =>
      import(/* webpackChunkName: "exercice" */ "../views/Exercice.vue"),
    meta: {
      menu: false,
      auth: Roles.Public,
      title: ":name",
    },
  },
  {
    path: "/classement/:date?",
    name: RouteName.Ranking,
    component: () =>
      import(/* webpackChunkName: "ranking" */ "../views/Ranking.vue"),
    meta: {
      menu: true,
      auth: Roles.User,
      title: "Classement",
    },
  },
  {
    path: "/lootbox",
    name: RouteName.Lootbox,
    component: () =>
      import(/* webpackChunkName: "exercice" */ "../views/Lootbox.vue"),
    meta: {
      menu: true,
      auth: Roles.User,
      title: "Récompenses",
    },
  },
  {
    path: "/options",
    name: RouteName.Options,
    component: () =>
      import(/* webpackChunkName: "options" */ "../views/options/Options.vue"),
    meta: {
      menu: true,
      auth: Roles.User,
      title: "Options",
    },
    children: option,
  },
  {
    path: "/inscription/:token",
    name: RouteName.Subscribe,
    component: () =>
      import(/* webpackChunkName: "inscription" */ "../views/Subscribe.vue"),
    meta: {
      menu: false,
      auth: Roles.User,
      title: "Inscription",
    },
  },
  ...admin,

  // {
  //   path: "/admin/exercices/:name?",
  //   name: RouteName.AdminExercicesEdit,
  //   component: () =>
  //     import(/* webpackChunkName: "admin" */ "../views/admin/ExerciceEdit.vue"),
  // },
  // {
  //   path: "/about",
  //   name: RouteName.About,
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
