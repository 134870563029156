
export const enum NotifName {
  RankUp = "rank-up",
  SequenceEnded = "sequence-ended",
}

export type NotifRow = {
  no_id: number;
  us_avatar: string;
  us_emoji: string;
  us_nickname: string;
  no_name: NotifName;
  no_data: string;
  no_created_at: string;
};

export type Notif = {
  id: number;
  user: {
    nickname: string,
    avatar: string,
    emoji: string,
  },
  name: NotifName;
  data: Record<string, unknown>;
  date: number;
}
  