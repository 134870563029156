import type { UserEvents } from "../../../../backend/src/types/UserEvent";
import { EventName } from "../../../../backend/src/types/UserEvent";
import { fetchApi } from "../../services/api";
import exerciceTexts from "./exercices";
import programs from "./programs";
import type { Router } from "vue-router";
import { RouteName } from "../../types/route";
import type { useSportStore } from "@/store";
import { ProgramId } from "../../../../backend/src/types/Program";

export type EventListInput<EN extends EventName> = {
  router: Router;
  store: ReturnType<typeof useSportStore>;
  event: UserEvents[EN];
  currentEventID: number;
};

export const enum EventListCtaDisplay {
  Positive,
  Neutral,
  Negative,
  Important,
}

export const enum EventCharacter {
  ChunLee,
  Faith,
  Male1,
}

type EventTrad<EN extends EventName> = {
  name: EN;
  character: EventCharacter;
  message: (options: EventListInput<EN>) => string;
  ctas: Array<{
    display: EventListCtaDisplay;
    label: string;
    callback: (options: EventListInput<EN>) => Promise<unknown>;
  }>;
};

function getCta<EN extends EventName>(
  label: string,
  callback?: (options: EventListInput<EN>) => Promise<unknown>,
  display: EventListCtaDisplay = EventListCtaDisplay.Neutral
) {
  return {
    display,
    label,
    callback: async (options: EventListInput<EN>): Promise<unknown> => {
      await fetchApi(options.store, "events/do", {
        event: { id: options.event.id },
      });

      if (callback) {
        await callback(options);
      }

      return 1;
    },
  };
}

const defineEvent = <Name extends EventName>(
  event: EventTrad<Name>
): EventTrad<Name> => event;

const events = [
  defineEvent({
    name: EventName.UnlockHarder,
    character: EventCharacter.ChunLee,
    message(options): string {
      const newExercice = options.event.data.exercice;
      const trad = exerciceTexts.find(
        (exerciceText) => exerciceText.id === newExercice.id
      );
      return `Hey !<br>
      Bravo pour tes efforts 👏 !<br>
      Tu viens de débloquer l'exercice <strong>${trad?.title}</strong> !`;
    },
    ctas: [getCta(`continuer`)],
  }),
  defineEvent({
    name: EventName.UnlockHarder,
    character: EventCharacter.ChunLee,
    message(options): string {
      const newExercice = options.event.data.exercice;
      const trad = exerciceTexts.find(
        (exerciceText) => exerciceText.id === newExercice.id
      );
      return `Hey !<br>
      Bravo pour tes efforts 👏 !<br>
      Tu viens de débloquer l'exercice <strong>${trad?.title}</strong> !`;
    },
    ctas: [getCta(`continuer`)],
  }),
  defineEvent({
    name: EventName.UnlockEndSeries,
    character: EventCharacter.ChunLee,
    message(options): string {
      const newExercice = options.event.data.exercice;
      const trad = exerciceTexts.find(
        (exerciceText) => exerciceText.id === newExercice.id
      );
      return `Génial 😄 !<br>
      Tu viens de terminer une série !<br>
      Tu gagnes l'exercice <strong>${trad?.title}</strong> !`;
    },
    ctas: [getCta(`continuer`)],
  }),
  defineEvent({
    name: EventName.UnlockBonus,
    character: EventCharacter.Male1,
    message(options): string {
      const newExercice = options.event.data.exercice;
      const trad = exerciceTexts.find(
        (exerciceText) => exerciceText.id === newExercice.id
      );
      return `Tu viens de débloquer l'exercice bonus <strong>${trad?.title}</strong> 👏&nbsp;!<br>
      <em>Si tu réalises les exercices bonus qui te sont proposés, ils sont ajoutés à ta collection&nbsp;!
      Tous les exercices que tu as acquis peuvent t'être proposés durant tes séances.</em>`;
    },
    ctas: [getCta(`continuer`)],
  }),
  defineEvent({
    name: EventName.FirstProgramSubscribed,
    character: EventCharacter.Faith,
    message(): string {
      return `Je viens de t'ajouter quelques exercices à exécuter,
      tu vas pouvoir commencer ton entrainement.<br>
      <em>Pour cela, rends toi dans la section "séance"</em><br>
      Ne me déçois pas`;
    },
    ctas: [
      getCta(`continuer`, (options) =>
        options.router.push({ name: RouteName.Session })
      ),
    ],
  }),
  defineEvent({
    name: EventName.FirstDisplayHome1,
    character: EventCharacter.ChunLee,
    message(): string {
      return `Bienvenue parmi nous !<br>
        Ici tu vas t'entretenir tout en t'amusant ! 😊`;
    },
    ctas: [getCta(`continuer`)],
  }),
  defineEvent({
    name: EventName.FirstDisplayHome2,
    character: EventCharacter.Male1,
    message(): string {
      return `Pour commencer tu peux t'inscrire à un <strong>programme</strong>.
      Il te permettra d'accéder à des séances de sport.`;
    },
    ctas: [
      getCta(`continuer`, (options) =>
        options.router.push({ name: RouteName.Programs })
      ),
    ],
  }),
  // defineEvent({
  //   name: EventName.FirstDisplayBonus,
  //   character: EventCharacter.Faith,
  //   message(options): string {
  //     const set = options.event.data.set;
  //     const trad = exerciceTexts.find(
  //       (exerciceText) => exerciceText.id === set.exercice
  //     );
  //     // const newExercice = (options.event.data).exercice;
  //     // const trad = exerciceTexts.find(exerciceText => exerciceText.name === newExercice.name);
  //     return `L'exercice <strong>${trad?.title}</strong> est un exercice bonus !<br>
  //     Tu ne le possèdes pas encore mais fais-le maintenant pour l'obtenir comme exercice récurrent.`;
  //   },
  //   ctas: [getCta(`continuer`)],
  // }),
  defineEvent({
    name: EventName.SequenceOvertime,
    character: EventCharacter.Faith,
    message(): string {
      return `Tu as dépassé le temps de pause maximum autorisé lors de ta dernière séance...<br>
      Si tu veux continuer tu dois en recommencer une nouvelle.`;
    },
    ctas: [getCta(`continuer`)],
  }),
  defineEvent({
    name: EventName.SequenceQuit,
    character: EventCharacter.ChunLee,
    message(options): string {
      const trad = programs.find(
        (program) => program.id === options.event.data.program
      );
      return `Tu viens quitter la séance <strong>${trad?.title}</strong>.<br>
      Tu pourra la reprendre sauf si tu laisses écouler trop de temps !`;
    },
    ctas: [getCta(`continuer`)],
  }),
  defineEvent({
    name: EventName.AvatarRevealed,
    character: EventCharacter.ChunLee,
    message(options): string {
      return `Félicitation 😄 tu viens de débloquer l'avatar
      <img width="64" height="64" src="/img/avatars/${options.event.data.avatar.src}" style="display: inline-block;">`;
    },
    ctas: [
      getCta(
        `mettre à jour`,
        (options) => options.router.push({ name: RouteName.Avatars }),
        EventListCtaDisplay.Important
      ),
      getCta(`continuer`),
    ],
  }),
  defineEvent({
    name: EventName.UnlockChallengeProgram,
    character: EventCharacter.ChunLee,
    message(options): string {
      return `Tu viens de débloquer le programme <strong>${
        programs.find((p) => (p.id = ProgramId.Custom))?.title
      }</strong> !<br>
      Ce programme te permet de composer des séances avec les exercices que tu veux et de défier tes amis !`;
    },
    ctas: [
      getCta(
        `mettre à jour`,
        (options) => options.router.push({ name: RouteName.Programs }),
        EventListCtaDisplay.Important
      ),
      getCta(`continuer`),
    ],
  }),
  defineEvent({
    name: EventName.EmojiRevealed,
    character: EventCharacter.ChunLee,
    message(options): string {
      return `Félicitation !<br>
      Tu viens de débloquer l'emoji
      <img width="16" height="16" src="/img/emojis/${options.event.data.emoji.src}" style="border-radius: 100%; display: inline-block;">`;
    },
    ctas: [
      getCta(
        `mettre à jour`,
        (options) => options.router.push({ name: RouteName.Emojis }),
        EventListCtaDisplay.Important
      ),
      getCta(`continuer`),
    ],
  }),
];

export default events;
