<template>
  <img
    v-if="enabled"
    :src="link"
    :width="size"
    :height="size"
    class="inline-block"
  />
</template>

<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps({
  src: {
    type: String,
    default: "",
  },
  original: {
    type: String,
    default: "",
  },
  size: {
    type: Number,
    default: 24,
  },
});

const enabled = computed(() => !!(props.src || props.original));

const link = computed(
  () =>
    `/img/emojis/${
      props.src ||
      `${props.original?.codePointAt(0)?.toString(16)}.svg` ||
      "default.svg"
    }`
);
</script>

<style lang="scss" scoped></style>
