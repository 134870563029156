import type {
  ExerciceId,
  ExerciceSmall,
} from "../../../backend/src/types/Exercice";
import exerciceTexts, { type ExerciceTrad } from "../trads/fr/exercices";
import exercices from "../assets/exercices.json";

export const getExercice = (id: ExerciceId): ExerciceSmall | undefined =>
  exercices.find((ex) => ex.id === id) as ExerciceSmall | undefined;

export const getExerciceTrad = (id: ExerciceId): ExerciceTrad | undefined =>
  exerciceTexts.find((ex) => ex.id === id) as ExerciceTrad | undefined;

export const getExerciceFull = (id: ExerciceId) => {
  const trad = getExerciceTrad(id);
  const exercice = getExercice(id);
  return trad && exercice
    ? ({ ...exercice, ...trad } as ExerciceSmall & ExerciceTrad)
    : undefined;
};
