import type { Set } from "../../../backend/src/types/Set";
import { defineStore } from "pinia";
import type { Workout } from "../../../backend/src/types/Workout";
import { ref } from "vue";
import { computed } from "@vue/reactivity";
import { programNameList } from "../../../backend/src/types/Program";

export const useSetsStore = defineStore("sets", () => {
  const workouts = ref<Workout[]>([]);
  const sets = ref<Set[]>([]);

  const setsByProgramsWorkouts = computed(() =>
    programNameList.map((program) => ({
      program,
      workouts: workouts.value
        .filter((workout) => workout.program === program)
        .map((workout) => ({
          workout: workout.id,
          sets: sets.value.filter((set) => set.workout === workout.id),
        })),
    }))
  );

  return {
    workouts,
    sets,
    setsByProgramsWorkouts,
  };
});
