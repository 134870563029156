import type { ExerciceId } from "./Exercice";

export const enum SetStatus {
  Todo = "TODO",
  Done = "DONE",
  Reject = "REJECT",
}

export const enum SetType {
  Recovery = 1,   //  50%
  Decrease = 2,   //  80%
  Highscore = 3,  // 100%
  Increase = 4,   // 110% (Standard)
  Limit = 5,      // MAX% (Test)
  Pause = 10,
}

export const SetTypeMultiply = {
  [SetType.Recovery]: 0.5,
  [SetType.Decrease]: 0.8,
  [SetType.Highscore]: 1,
  [SetType.Increase]: 1.1,
  [SetType.Limit]: 2,
}

export const enum SetData {
  Left = "LEFT",
  Right = "RIGHT",
}

export type SetRow = {
  se_id: number;
  ex_id: ExerciceId;
  us_id: number;
  se_value: number;
  se_score: number;
  se_bonus: number;
  se_time: number;
  se_goal: number;
  se_status: SetStatus;
  se_type: SetType;
  se_data: SetData | null;
  wo_id: number;
  se_updated_at?: string;
};

export type Set = {
  id: number;
  exercice: ExerciceId;
  status: SetStatus,
  type: SetType,
  value: number;
  score: number;
  bonus: number;
  time: number;
  goal: number;
  workout: number;
  data: SetData | null;
  doneDate?: string;
};

export type SetInput = { userId: number } & Omit<Set, "id">

export type SetStats = {
  id: number,
  maxValue: number,
  unlock?: {
    success: true,
    next: ExerciceId,
    goal: number
  } | {
    success: false,
    goal: number,
    next: 0
  }
}

export type HistoryWorkout = {
  programID: number,
  bonus: number,
  count: number,
  from: number,
  score: number,
  workout: number,
  to: number,
  time: number,
}

export type HistoryWeek = {
  rank: number,
  date: number,
  workouts: HistoryWorkout[]
}

export type SetAbstractExercice = { exercice: ExerciceId, type: Exclude<SetType, SetType.Pause>, data?: SetData }
export type SetAbstractPause = { exercice: ExerciceId, type: SetType.Pause, time: number }
export type SetAbstract = SetAbstractExercice | SetAbstractPause
