<template>
  <div class="inline-block p-6 bg-base-100 text-base-content rounded-box">
    <!-- Progress -->
    <teleport to="#bg-container">
      <div
        class="absolute top-0 left-0 h-full w-full overflow-x-hidden"
        :style="{ transform: `translateX(${progress * 100 - 100}vw)` }"
      >
        <div
          class="absolute top-0 left-0 h-full w-full bg-gradient-to-br from-primary to-primary-focus"
          :style="{ transform: `translateX(${100 - progress * 100}vw)` }"
        >
          <BgTriangles color1="--pf" color2="--p" />
        </div>
      </div>
    </teleport>

    <!-- Objective -->
    <slot></slot>

    <!-- Chrono -->
    <template v-if="!isInAdditionalPause">
      <div class="flex flex-col justify-center items-center">
        <div class="text-center">Préparation</div>
        <div class="text-4xl font-extrabold text-center relative">
          {{ timeSecDisplay }}&nbsp;sec
        </div>
        <div class="text-center">Prepare toi</div>

        <div class="mt-6">
          <button @click="$emit('cancel')" class="btn btn-warning btn-sm mt-6">
            Annuler
          </button>
        </div>
      </div>
    </template>

    <!-- Pause -->
    <template v-else-if="timeMsDisplay < 0">
      <div class="flex flex-col justify-center items-center">
        <div class="text-center">Pause activée</div>
        <div class="text-4xl font-extrabold text-center">
          {{ timeSecDisplay }}&nbsp;sec
        </div>
        <div v-if="!freePause" class="text-center">temps additionnel</div>

        <button @click="$emit('play')" class="btn btn-warning btn-sm mt-6">
          Arrêter la pause
        </button>
      </div>
    </template>

    <!-- Additional time -->
    <template v-else>
      <div class="flex flex-col justify-center items-center">
        <div class="text-center">Temps additionnels</div>
        <div class="text-4xl font-extrabold text-center">
          {{ timeSecDisplay }}&nbsp;sec
        </div>
        <div v-if="!freePause" class="text-center block">
          <small class="italic leading-tight block mt-2">
            Si vous passez trop de temps,<br class="sm:hidden" />
            votre série sera rejetée
          </small>
        </div>

        <button @click="$emit('play')" class="btn btn-success btn-wide mt-6">
          Commencer les exercices
        </button>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { computed, onUnmounted, ref, watch } from "vue";
import audioManager, { SoundName } from "../../services/audioManager";
import BgTriangles from "../ui/BgTriangles.vue";

// import RadialProgress from "./RadialProgress.vue";

const emit = defineEmits(["start", "overtime", "cancel", "play"]);

const props = defineProps({
  duration: {
    type: Number,
    required: true,
  },
  freePause: {
    type: Boolean,
    required: true,
  },
  isUserPause: {
    type: Boolean,
    required: true,
  },
});

const startTime = Date.now();
const time = ref(startTime);
const additionalPauses = ref([] as { from: number; to?: number }[]);

watch(
  () => props.isUserPause,
  (isUserPause: boolean) => {
    if (isInAdditionalPause.value) {
      const from =
        additionalPauses.value[additionalPauses.value.length - 1].from;
      const to = Date.now() + 3000 > from ? Date.now() + 3000 : from;
      additionalPauses.value[additionalPauses.value.length - 1].to = to;
    } else {
      additionalPauses.value.push({
        from: Math.max(Date.now(), startTime + props.duration - 1),
      });
    }
  }
  // { immediate: true }
);

const isInAdditionalPause = computed(
  () =>
    additionalPauses.value.length > 0 &&
    additionalPauses.value[additionalPauses.value.length - 1].to === undefined
);

const endPause = computed(() =>
  Math.max(
    props.duration + startTime,
    ...additionalPauses.value
      .filter(({ to }) => to !== undefined)
      .map(({ to }) => to || 0)
  )
);

const timeMsDisplay = computed(() => {
  if (isInAdditionalPause.value) {
    return time.value - (props.duration + startTime);
  } else {
    return time.value - endPause.value;
  }
});

const timeSecDisplay = computed(() => {
  return Math.floor(timeMsDisplay.value / 1000);
});

const progress = computed(() =>
  Math.max(
    0,
    Math.min(1, (props.duration + timeMsDisplay.value) / props.duration)
  )
);

watch(timeSecDisplay, (val) => {
  if (isInAdditionalPause.value && val > 0) {
    emit("overtime", timeMsDisplay.value);
    return;
  }
  if (!isInAdditionalPause.value) {
    if (val < 0 && val > -4) {
      audioManager.play(SoundName.BeepCountdown);
    }
    // else if (val === 0) {
    //   audioManager.play(SoundName.BeepStart);
    // }
  }
});

// Timer
const updateTimer = () => {
  cancelAnimationFrame(animationFrame);
  animationFrame = requestAnimationFrame(updateTimer);

  time.value = Date.now();

  if (!isInAdditionalPause.value && time.value >= endPause.value) {
    cancelAnimationFrame(animationFrame);
    audioManager.play(SoundName.BeepStart);
    emit("start", endPause.value - startTime);
  }
};
let animationFrame = requestAnimationFrame(updateTimer);
onUnmounted(() => cancelAnimationFrame(animationFrame));
</script>

<style lang="scss" scoped></style>
