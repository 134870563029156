<template>
  <ul
    class="menu left-0 fixed bottom-0 justify-center flex-none w-full px-3 bg-neutral-focus text-neutral-content z-30 md:relative menu-horizontal md:menu-vertical md:bg-transparent md:text-base-content"
  >
    <li :class="{ bordered: $route.name === 'Home' }">
      <router-link :to="{ name: RouteName.Home }">
        <div class="inline-block w-6 h-6 stroke-current">
          <Emoji original="🏠" />
        </div>
        <div class="hidden md:block">Accueil</div>
      </router-link>
    </li>
    <li :class="{ bordered: $route.name === 'Ranking' }">
      <router-link :to="{ name: RouteName.Ranking }">
        <div class="inline-block w-6 h-6 stroke-current">
          <Emoji original="🏆" />
        </div>
        <div class="hidden md:block">Classement</div>
      </router-link>
    </li>
    <li :class="{ bordered: $route.path.indexOf('/session') === 0 }">
      <router-link :to="{ name: RouteName.Session }">
        <div class="inline-block w-6 h-6 stroke-current">
          <Emoji original="🏁" />
        </div>
        <div class="hidden md:block">Séance</div>
      </router-link>
    </li>
    <li :class="{ bordered: $route.name === RouteName.Lootbox }">
      <router-link :to="{ name: RouteName.Lootbox }">
        <div
          class="inline-flex justify-center items-center w-6 h-6 stroke-current indicator"
        >
          <span
            v-if="lootCount"
            class="indicator-item badge badge-accent badge-xs"
            >{{ lootCount }}</span
          >
          <Emoji original="🎁" />
        </div>
        <div class="hidden md:block">Récompenses</div>
      </router-link>
    </li>
    <li :class="{ bordered: $route.path.indexOf('/options') === 0 }">
      <router-link :to="{ name: RouteName.Options }">
        <div
          class="inline-flex justify-center items-center w-6 h-6 stroke-current indicator"
        >
          <span
            v-if="optionCount"
            class="indicator-item badge badge-accent badge-xs"
            >{{ optionCount }}</span
          >
          <Emoji original="⚙️" />
        </div>
        <div class="hidden md:block">Options</div>
      </router-link>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { useLootboxesStore } from "../../store/lootboxStore";
import { computed } from "vue";
import { RouteName } from "../../types/route";
import Emoji from "../ui/Emoji.vue";
import { useFriendsStore } from "@/store/friendsStore";

// ("🏅🏆🎴📑");

const lootboxesStore = useLootboxesStore();
const friendsStore = useFriendsStore();

const lootCount = computed(() => lootboxesStore.$state.list.length);
const optionCount = computed(() => friendsStore.requests.length);
</script>

<style lang="scss" scoped></style>
