import { defineStore } from "pinia";
import { ref } from "vue";

type State = "success" | "fail" | "progress";

export type Toast = {
  id: number;
  state: State;
  messages: {
    success: string;
    fail: string;
    progress: string;
  };
};

export const useToastStore = defineStore("apiNotif", () => {
  let count = 0;
  const list = ref<Toast[]>([]);

  const add = (event: Omit<Toast, "id" | "state">) => {
    const fullEvent = {
      ...event,
      state: "progress" as State,
      id: ++count,
    };

    list.value.push(fullEvent);
    return fullEvent.id;
  };

  const update = (id: number, state: State) => {
    const apiNotif = list.value.find((apiNotif) => apiNotif.id === id);
    if (apiNotif) {
      apiNotif.state = state;
      const length = apiNotif.messages[state].length;
      switch (state) {
        case "fail":
        case "success":
          setTimeout(() => remove(id), length * 100);
          break;
      }
    }
  };

  const remove = (id: number) => {
    const index = list.value.findIndex((apiNotif) => apiNotif.id === id);
    if (index > -1) {
      list.value.splice(index, 1);
      return true;
    }
    return false;
  };

  return {
    list,
    add,
    update,
    remove,
  };
});

export const useCreateToast = () => {
  const apiNotif = useToastStore();
  return async <Data>(
    promise: Promise<Data>,
    messages: {
      progress: string;
      success: string;
      fail: string;
    } = {
      progress: "Chargement",
      success: "Chargé",
      fail: "Erreur",
    }
  ) => {
    const id = apiNotif.add({ messages });
    try {
      await promise;
      apiNotif.update(id, "success");
    } catch (error) {
      apiNotif.update(id, "fail");
      throw error;
    }
  };
};
