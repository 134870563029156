import { RouteName, Roles } from "../types/route";

export default [
  {
    path: "/options/historique/:date?/:workout?",
    name: RouteName.History,
    component: () =>
      import(/* webpackChunkName: "options" */ "../views/options/History.vue"),
    meta: {
      menu: true,
      auth: Roles.User,
      title: "Historique",
    },
  },
  {
    path: "/options/profil",
    name: RouteName.Profile,
    component: () =>
      import(/* webpackChunkName: "options" */ "../views/options/Profile.vue"),
    meta: {
      menu: true,
      auth: Roles.User,
      title: "Profil",
    },
  },
  {
    path: "/options/amis",
    name: RouteName.Friends,
    component: () =>
      import(/* webpackChunkName: "options" */ "../views/options/Friends.vue"),
    meta: {
      menu: true,
      auth: Roles.User,
      title: "Amis",
    },
  },
  {
    path: "/options/profil/avatars",
    name: RouteName.Avatars,
    component: () =>
      import(/* webpackChunkName: "options" */ "../views/options/Avatars.vue"),
    meta: {
      menu: true,
      auth: Roles.User,
      title: "Avatars",
    },
  },
  {
    path: "/options/profil/emojis",
    name: RouteName.Emojis,
    component: () =>
      import(/* webpackChunkName: "options" */ "../views/options/Emojis.vue"),
    meta: {
      menu: true,
      auth: Roles.User,
      title: "Emojis",
    },
  },
  {
    path: "/options/materiel",
    name: RouteName.Equipments,
    component: () =>
      import(
        /* webpackChunkName: "options" */ "../views/options/Equipments.vue"
      ),
    meta: {
      menu: true,
      auth: Roles.User,
      title: "Matériel",
    },
  },
  {
    path: "/options/programmes/:description?",
    name: RouteName.Programs,
    component: () =>
      import(/* webpackChunkName: "options" */ "../views/options/Programs.vue"),
    meta: {
      menu: true,
      auth: Roles.User,
      title: "Programmes",
    },
  },
  {
    path: "/options/exercices/:exercice?/:type?",
    name: RouteName.Exercices,
    component: () =>
      import(
        /* webpackChunkName: "options" */ "../views/options/Exercices.vue"
      ),
    meta: {
      menu: true,
      auth: Roles.User,
      title: "Exercices",
    },
  },
  {
    path: "/options/notifications",
    name: RouteName.Notifications,
    component: () =>
      import(
        /* webpackChunkName: "options" */ "../views/options/Notifications.vue"
      ),
    meta: {
      menu: true,
      auth: Roles.User,
      title: "Options",
    },
  },
  {
    path: "/options/graphic",
    name: RouteName.Graphic,
    component: () =>
      import(/* webpackChunkName: "options" */ "../views/options/Graphic.vue"),
    meta: {
      menu: true,
      auth: Roles.User,
      title: "Graphisme",
    },
  },
];
