<template>
  <!-- <SequenceCoverSmall :sets="sets"></SequenceCoverSmall> -->

  <RankBar
    v-if="enableRank"
    :from="fromXp"
    :experience="experience"
    class="mb-4 mt-10"
  ></RankBar>

  <table class="table w-full table-zebra text-base-content">
    <tbody>
      <template v-for="({ set, stat }, index) of mixedList">
        <LineResult
          v-if="index < currentDisplay"
          :set="set"
          :unlock-success="stat?.unlock?.success"
          :unlock-goal="stat?.unlock?.goal"
          :unlock-exercice="stat?.unlock?.next"
          :display="index < startAnimate"
          @onReady="setReady"
          :key="set.id"
        ></LineResult>
      </template>
    </tbody>
  </table>

  <!-- <div class="grid grid-cols-2 gap-2 my-2 md:grid-cols-2 lg:grid-cols-3">
    <template v-for="({ set, stat }, index) of mixedList">
      <SetResult
        v-if="index < currentDisplay"
        :set="set"
        :unlock-success="stat?.unlock?.success"
        :unlock-goal="stat?.unlock?.goal"
        :unlock-exercice="stat?.unlock?.next"
        :display="index < currentAnimate"
        @onReady="setReady"
        :key="set.id"
      ></SetResult>
    </template>
  </div> -->

  <Modal
    :isOpen="!!$route.params.description"
    :close="{ name: RouteName.WorkoutResume, params: { id: $route.params.id } }"
    :isLarge="true"
  >
    <h3 class="text-lg font-bold">
      {{ getExerciceTrad(Number($route.params.description))?.title }}
    </h3>
    <ExerciceDescription
      :id="Number($route.params.description)"
    ></ExerciceDescription>
  </Modal>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import {
  ExerciceId,
  ExerciceModel,
} from "../../../../backend/src/types/Exercice";
import type { Set } from "../../../../backend/src/types/Set";
import type { SetStats } from "../../../../backend/src/types/Set";
import { RouteName } from "../../types/route";
import { getStats } from "../../services/api";
import exerciceTexts from "../../trads/fr/exercices";
import Modal from "../ui/Modal.vue";
import ExerciceDescription from "../ExerciceDescription.vue";
import RankBar from "../user/RankBar.vue";
import { useMeStore } from "../../store/meStore";
import LineResult from "../set/LineResult.vue";
import { getExercice } from "@/helpers/exercice";

const meStore = useMeStore();

const props = defineProps({
  workout: {
    type: Number,
    required: true,
  },
  enableRank: {
    type: Boolean,
    default: false,
  },
});

const currentAnimate = ref(1);
const startAnimate = ref(1);
const results = ref<{ sets: Set[]; stats: SetStats[] }>({
  sets: [],
  stats: [],
});

watch(
  () => props.workout,
  () => getStats(props.workout).then((data) => (results.value = data)),
  { immediate: true }
);

const getExerciceTrad = (id: ExerciceId) =>
  exerciceTexts.find((ex) => ex.id === id);
const setReady = () => {
  currentAnimate.value++;
  setTimeout(() => startAnimate.value++, 500);
};

const currentDisplay = ref(0);
let displayTo: ReturnType<typeof setTimeout>;
const incrementDisplay = () => {
  clearTimeout(displayTo);
  if (mixedList.value.length < 1) {
    displayTo = setTimeout(incrementDisplay, 17);
  } else if (currentDisplay.value <= mixedList.value.length) {
    currentDisplay.value++;
    displayTo = setTimeout(incrementDisplay, 1000);
  }
};

onMounted(incrementDisplay);

onUnmounted(() => {
  clearTimeout(displayTo);
});

const sets = computed(() =>
  results.value.sets.filter(
    (set) => getExercice(set.exercice)?.model !== ExerciceModel.None
  )
);

const mixedList = computed(() =>
  sets.value
    .filter((set) => getExercice(set.exercice)?.model !== ExerciceModel.None)
    .map((set) => ({
      set,
      stat: results.value.stats.find((stat) => stat.id === set.id),
    }))
);

const fromXp = computed(
  () =>
    meStore.$state.experience -
    sets.value
      .filter((set) => getExercice(set.exercice)?.model !== ExerciceModel.None)
      .reduce((all, set) => all + set.score, 0)
);

const experience = computed(() => meStore.$state.experience);
</script>

<style lang="scss" scoped></style>
