import { ref } from "vue";

/**
 * Easing equation function for an exponential (2^t) easing out: decelerating from zero velocity.
 */
// function easeOutExpo(time: number): number {
//   return time >= 1 ? 1 : 1 * 1.001 * (-Math.pow(2, -10 * time) + 1);
// }

function easeInOutSin(time: number): number {
  return -(Math.cos(Math.PI * time) - 1) / 2;
}

export const useTween = ({
  onFinish = () => 1,
  duration = 1000,
}: {
  onFinish?: () => unknown;
  duration: number;
}) => {
  let raf: number;
  const value = ref(0);

  return {
    value,
    stop: () => cancelAnimationFrame(raf),
    start: () => {
      const start = Date.now();
      const onRaf = () => {
        cancelAnimationFrame(raf);
        const time = Date.now() - start;
        if (time <= 0) {
          value.value = 0;
          raf = requestAnimationFrame(onRaf);
        } else if (time >= duration) {
          value.value = 1;
          onFinish();
        } else {
          const val = easeInOutSin(time / duration);
          value.value = Math.min(val, 1);
          raf = requestAnimationFrame(onRaf);
        }
      };

      raf = requestAnimationFrame(onRaf);
    },
  };
};
