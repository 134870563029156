import {
  ExerciceSubject,
  ExerciceAlter,
} from "../../../../backend/src/types/Exercice";

export type ExerciceSubjectsTrad = {
  [key in ExerciceSubject]: string;
};

export const exerciceSubjectsTrad: ExerciceSubjectsTrad = {
  [ExerciceSubject.MuscleGlobal]: "global",

  [ExerciceSubject.MuscleUpperPectoralisMajor]: "pectoral suppérieur",
  [ExerciceSubject.MuscleLowerPectoralisMajor]: "pectoral inférieur",

  [ExerciceSubject.MuscleNeck]: "nuque",
  [ExerciceSubject.MuscleTrapezius]: "trapèze",
  [ExerciceSubject.MuscleRhomboids]: "rhomboïdes",
  [ExerciceSubject.MuscleInfraspinatus]: "infra-épineux",
  [ExerciceSubject.MuscleLatissimusDorsi]: "grand dorsal",
  [ExerciceSubject.MuscleLumbar]: "lombaires",

  [ExerciceSubject.MusclePosteriorDeltoid]: "arrière de l'épaule",
  [ExerciceSubject.MuscleAnteriorDeltoid]: "devant de l'épaule",
  [ExerciceSubject.MuscleLateralDeltoid]: "coté de l'épaule",

  [ExerciceSubject.MuscleBicep]: "biceps",
  [ExerciceSubject.MuscleTriceps]: "triceps",
  [ExerciceSubject.MuscleForeArms]: "avant bras",
  [ExerciceSubject.MuscleOblique]: "obliques",
  [ExerciceSubject.MuscleUpperAbdominals]: "abdominaux du haut",
  [ExerciceSubject.MuscleLowerAbdominals]: "abdominaux du bas",

  [ExerciceSubject.MuscleQuadriceps]: "quadriceps",
  [ExerciceSubject.MuscleGlutes]: "fessiers",
  [ExerciceSubject.MuscleHamstring]: "ischio-jambier",
  [ExerciceSubject.MuscleAdductor]: "adducteur",
  [ExerciceSubject.MuscleIliotibialTract]: "extérieur des cuisses",

  [ExerciceSubject.MuscleCalves]: "molets",

  [ExerciceSubject.Material]: "materiel",

  [ExerciceSubject.Family]: "famille",
};

export type ExerciceAlterTrad = {
  [key in ExerciceAlter]: string;
};
export const exerciceAlterTrad: ExerciceAlterTrad = {
  [ExerciceAlter.WorkPause]: "pause",
  [ExerciceAlter.WorkAerobic]: "endurance",
  [ExerciceAlter.WorkFlexibility]: "assouplissement",
  [ExerciceAlter.WorkRelaxation]: "relaxation",
  [ExerciceAlter.WorkStrength]: "force",
  [ExerciceAlter.WorkIsometric]: "isométrie",
  [ExerciceAlter.WorkInterval]: "intervales",
  [ExerciceAlter.FamilyWorkout]: "renforcement",
  [ExerciceAlter.FamilyYoga]: "yoga",
  [ExerciceAlter.FamilyMartialArt]: "arts martiaux",
  [ExerciceAlter.FamilyPilates]: "pilates",
  [ExerciceAlter.MaterialFixedBar]: "bare fixe",
  [ExerciceAlter.MaterialLargeFixedBar]: "bare fixe large",
  [ExerciceAlter.MaterialRunningShoe]: "chaussure de course",
};
