
export type EquipmentRow = {
  eq_name: string;
  use_count: number;
};

export const enum Equipment {
  ChinUpBar = "chinUpBar",  // 
  PullUpBar = "pullUpBar",  // large
  Stool = "stool",
  Chair = "chair",
  DiningTable = "diningTable",
  Wall = "wall",
  Towel = "towel",
  SlidingFloor = "slidingFloor",
}

export const equipmentList = [
  Equipment.Chair,
  Equipment.ChinUpBar,
  Equipment.DiningTable,
  Equipment.PullUpBar,
  Equipment.Stool,
  Equipment.Wall,
  Equipment.Towel,
  Equipment.SlidingFloor
]
