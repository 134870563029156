import { defineStore } from "pinia";
import type { Friend } from "../../../backend/src/types/Friend";

export type FriendsState = {
  requests: Friend[];
};

export const useFriendsStore = defineStore({
  // name of the store
  // it is used in devtools and allows restoring state
  id: "friends",

  // a function that returns a fresh state
  state: (): FriendsState => ({
    requests: [],
  }),

  // optional getters
  getters: {},

  // optional actions
  actions: {},
});
