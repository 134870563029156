<template>
  <main class="w-full h-screen relative">
    <transition name="fade" mode="out-in">
      <SetDo
        v-if="setData.set"
        :set="setData.set"
        :double="setData.double"
        :pause="setData.pause"
        :key="setData.set.id"
      ></SetDo>
    </transition>
  </main>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, watch } from "vue";
import {
  SetStatus,
  SetType,
  type Set,
} from "../../../../backend/src/types/Set";
import { useSportStore } from "../../store";
import SetDo from "../../components/set/SetDo.vue";
import { useRoute } from "vue-router";
import insomniacScreen from "../../helpers/insomniacScreen";
import UseSequenceNotFound from "./UseSequenceNotFound";

const sportStore = useSportStore();
const route = useRoute();

UseSequenceNotFound();

onMounted(() => insomniacScreen.awakeMode());
onUnmounted(() => insomniacScreen.normalMode());

const getSets = () =>
  sportStore.sets.sets.filter(
    (set) => set.workout === Number(route.params.workout)
  );

const sets = computed<Set[]>(getSets);
const setData = computed(() => {
  const setIndex = sets.value.findIndex(
    (set) => set.status === SetStatus.Todo && set.type !== SetType.Pause
  );
  const set = sets.value[setIndex];

  const getDouble = () => {
    const doubles = sets.value.filter((s) => s.exercice === set?.exercice);
    if (doubles.length > 1) {
      const index = doubles.indexOf(set);
      if (index % 2 === 1) {
        return doubles[index - 1];
      }
    }
    return null;
  };

  const pause =
    sets.value[setIndex - 1]?.type === SetType.Pause
      ? sets.value[setIndex - 1]
      : { time: 5 };

  return {
    set,
    pause,
    double: getDouble(),
  };
});
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
