import type { Router } from "vue-router";
import type { Set } from "../../../backend/src/types/Set";
import { SetStatus, SetType } from "../../../backend/src/types/Set";
import { RouteName } from "../types/route";
import { rejectWorkout } from "../services/api";
import type { useSportStore } from "../store";

export const checkOvertime = async ({
  more = 0,
  sets,
  sportStore,
  router,
}: {
  more?: number;
  sets: Set[];
  sportStore: ReturnType<typeof useSportStore>;
  router: Router;
}) => {
  const count = sets.filter(
    (set) => set.status === SetStatus.Done && set.type === SetType.Pause
  ).length;

  if (count < 1) {
    return;
  }

  const timeObjective = sets
    .filter((set) => set.type === SetType.Pause)
    .reduce((total, set) => total + set.goal * 1000, 0);
  const timeMaximum = timeObjective * 5;

  const currentTime =
    more +
    sets
      .filter(
        (set) => set.status === SetStatus.Done && set.type === SetType.Pause
      )
      .reduce((total, set) => total + set.time * 1000, 0);

  if (currentTime > timeMaximum) {
    const set = sets[0] as Set;
    const { workout } = set;
    await rejectWorkout(workout, sportStore, true);
    router.push({ name: RouteName.Session });
  }
};
