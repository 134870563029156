<template>
  <div
    v-if="exercice"
    class="card bg-base-300 text-base-content image-full border-2"
  >
    <!-- Background -->
    <figure class="absolute top-0 left-0 w-full h-full">
      <div
        :style="{ backgroundImage: `url(${exercice.cover})` }"
        class="bg-cover bg-center top-0 left-0 w-full h-full absolute"
      ></div>
    </figure>

    <div class="card-body relative transition-opacity">
      <!-- Title -->
      <h2 class="card-title block text-[#FFF]">
        <span class="mr-2">
          <span class="first-letter:uppercase">{{ exercice.title }}</span>
          <slot name="title-after"></slot>
        </span>
        <Level class="level mr-2" :value="exercice.level" />
        <span class="badge">{{ type }}</span>
      </h2>

      <!-- Paragraph -->
      <p v-if="$slots.secondary" class="my-5">
        <slot name="secondary"></slot>
      </p>

      <!-- CTA -->
      <div class="card-actions justify-center">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, type PropType } from "vue";
import Level from "./Level.vue";
import type { ExerciceId } from "../../../../backend/src/types/Exercice";
import { ExerciceSubject } from "../../../../backend/src/types/Exercice";
import { exerciceAlterTrad } from "../../trads/fr/exerciceSubjectsAlters";
import { getExerciceFull } from "@/helpers/exercice";

const props = defineProps({
  id: {
    type: Number as PropType<ExerciceId>,
    required: true,
  },
});

const exercice = computed(() => getExerciceFull(props.id));

// const work = computed(() => {
//   return exercice.value?.infos?.find(alter => alter.subject === ExerciceSubject.MuscleGlobal)?.alter as string || ""
// })

// const borderColor = computed(() => {
//   return ["#000000", "#40b548", "#0560e2", "#ff8500", "#fc1f88", "#330000"][(exercice.value?.level || 0)];
// });

const type = computed(() => {
  const type = exercice.value?.infos?.find(
    (info) => info.subject === ExerciceSubject.MuscleGlobal
  )?.alter;
  if (type) {
    return exerciceAlterTrad[type];
  }

  return "";
});
</script>

<style lang="scss" scoped></style>
