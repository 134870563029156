<template>
  <span class="badge badge-info rounded-r-none pr-1">Objectif</span
  ><strong class="badge badge-info rounded-l-none badge-outline pl-1">{{
    value
  }}</strong>
</template>

<script lang="ts" setup>
import { computed, type PropType } from "vue";
import type { ExerciceModel } from "../../../../backend/src/types/Exercice";
import type { Set } from "../../../../backend/src/types/Set";
import { useSportStore } from "../../store";
import { getChronoString } from "@/helpers/time";
import { isTimeUnit } from "../../../../backend/src/helpers/exercices";

const props = defineProps<{ set: Set }>();

const sportStore = useSportStore();

const getModel = (set: Set) =>
  sportStore.exercices.fullExercices.find(({ id }) => id === set.exercice)
    ?.model as ExerciceModel;

const value = computed(() =>
  isTimeUnit(getModel(props.set))
    ? getChronoString(props.set.value * 1000)
    : `${props.set.value} reps`
);
</script>

<style lang="scss" scoped></style>
