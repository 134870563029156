<template>
  <select v-model="selectedFriend" class="select select-primary">
    <option :value="0" disabled>Défier un ami ou une amie</option>
    <option :value="friend.id" v-for="friend of restFriends" :key="friend.id">
      {{ friend.nickname }}
    </option>
  </select>

  <template v-if="modelValue.length">
    <table class="table table-zebra w-full text-base-content">
      <tbody>
        <tr
          v-for="friend of modelValue"
          :key="friend.nickname"
          :class="`appear-top-0`"
        >
          <td>
            <UserDisplay
              :nickname="friend.nickname"
              :experience="friend.experience"
              :avatar="friend.avatar"
              :emoji="friend.emoji"
              class="user"
            ></UserDisplay>
          </td>
          <td class="text-right">
            <button
              @click="removeFriend(friend.nickname)"
              class="btn btn-error btn-xs"
            >
              Retirer
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </template>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { getFriends } from "@/services/api";
import type { Friend } from "../../../../backend/src/types/Friend";
import UserDisplay from "../../components/user/UserDisplay.vue";

const emit = defineEmits(["update:modelValue"]);
const props = defineProps<{ modelValue: Friend[] }>();

const friends = ref<Friend[]>([]);
const restFriends = computed(() =>
  friends.value.filter(
    (fr) => !props.modelValue.find((f) => f.nickname === fr.nickname)
  )
);

getFriends(friends.value);

const selectedFriend = ref(0);

watch(selectedFriend, (friendID: number) => {
  const friend = friends.value.find((fr) => fr.id === friendID);
  if (friend) {
    const list = JSON.parse(JSON.stringify(props.modelValue)) as Friend[];
    list.push(friend);
    selectedFriend.value = 0;
    emit("update:modelValue", list);
  }
});

const removeFriend = (friendNickname: string) => {
  const list = JSON.parse(JSON.stringify(props.modelValue)) as Friend[];
  const index = list.findIndex((fr) => friendNickname === fr.nickname);
  if (index > -1) {
    list.splice(index, 1);
    emit("update:modelValue", list);
  }
};
</script>

<style lang="scss" scoped></style>
