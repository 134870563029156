import { defineStore } from "pinia";
import { EventStatus } from "../../../backend/src/types/UserEvent";
import type { UserEvent } from "../../../backend/src/types/UserEvent";

export type EventsState = { list: Array<UserEvent> };
// <
//   "events",
//   EventsState,
//   {
//     displayed: () => UserEvent | null;
//   },
//   Record<string, never>
// >
export const useEventsStore = defineStore("events", {
  // name of the store
  // it is used in devtools and allows restoring state
  // id: "events",

  // a function that returns a fresh state
  state: (): EventsState => ({
    list: [],
  }),

  // optional getters
  getters: {
    displayed(this): UserEvent | null {
      return (
        this.list.filter((event) => event.status === EventStatus.New)[0] || null
      );
    },
  },

  actions: {
    addEvent(this, event: UserEvent): void {
      this.list.unshift(event);
    },
  },
});
