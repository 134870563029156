<template>
  <!-- <Teleport to="#bg-container"> -->
  <div
    ref="container"
    class="absolute top-0 left-0 w-full h-full overflow-hidden appear-opacity"
  >
    <!-- <div
        class="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-primary to-secondary"
      ></div> -->
  </div>
  <!-- </Teleport> -->
</template>

<script lang="ts" setup>
import emitter from "@/services/emitter";
import { onMounted, ref } from "vue";
import { getTriangleBackground } from "./triangleBackground";

const container = ref<HTMLDivElement | null>(null);
let svg: SVGViewElement | null;

const props = defineProps({
  color1: {
    type: String,
    required: true,
  },
  color2: {
    type: String,
    required: true,
  },
  accent: {
    type: Number,
    default: 1,
  },
});

const updateBg = async () => {
  if (svg) {
    svg.parentElement?.removeChild(svg);
  }

  const color1 = getComputedStyle(document.body).getPropertyValue(props.color1);
  const color2 = getComputedStyle(document.body).getPropertyValue(props.color2);

  const newSvg = getTriangleBackground(color1, color2, props.accent);
  container.value?.appendChild(newSvg);
  svg = newSvg;
};

onMounted(updateBg);
emitter.on("theme:changed", updateBg);
</script>

<style lang="scss" scoped>
:deep(svg) {
  width: 100%;
  height: 110%;
  position: relative;
  // opacity: 0.5;
  // object-fit: cover;
  // // object-position: center;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translateX(-50%) translateY(-45%);

  // path {
  //   stroke: hsl(var(--s));
  // }
}
</style>
