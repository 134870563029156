export const enum ProgramId {
  FastStrength = 1,
  MiddleStrength = 2,
  Custom = 3,
  Challenge = 4,
}

export const programNameList = [
  ProgramId.FastStrength,
  ProgramId.MiddleStrength,
  ProgramId.Custom,
  ProgramId.Challenge,
]

export type ProgramRow = {
  pr_id: ProgramId;
  usp_enabled: boolean;
};

export interface Program {
  id: ProgramId;
  enabled: boolean;
}
