<template>
  <main class="page-container gap-4 flex flex-col">
    <p class="m-3 appear-top-1">
      Prépare toi pour ta séance.<br />
      Et dès que tu es prêt lance la !<br />
      <span class="italic">
        Attention certains exercices peuvent nécessiter du matériel, prépare-le
        à l'avance.
      </span>
    </p>

    <template v-for="(set, i) of setResumeList" :key="set.id">
      <!-- <div v-if="set.model === ExerciceModel.None" class="divider">
        {{ set.time }}&nbsp;sec
      </div> -->

      <Exercice :id="set.exercice" :class="`appear-top-${Math.min(i + 2, 10)}`">
        <template v-if="set.data" v-slot:title-after>
          <span v-if="set.data === SetData.Left"> (gauche)</span>
          <span v-else-if="set.data === SetData.Right"> (droite)</span>
        </template>

        <template v-slot:secondary>
          <div class="text-right">
            <ObjectiveBadge :set="set"></ObjectiveBadge>
          </div>
        </template>

        <div class="flex flex-col items-center">
          <router-link
            :to="{
              name: RouteName.WorkoutResume,
              params: { id: $route.params.id, description: set.exercice },
            }"
            class="btn btn-outline btn-info btn-sm btn-wide"
          >
            description
          </router-link>
        </div>

        <div
          v-if="set.status === SetStatus.Done"
          class="absolute top-0 left-0 w-full h-full bg-neutral-content flex items-center justify-center bg-opacity-75"
        >
          <span
            class="text-4xl text-neutral font-black uppercase -rotate-6 opacity-70"
          >
            Exercice réalisé
          </span>
        </div>
      </Exercice>
    </template>

    <div class="text-center my-8">
      <button
        @click="rejectSession()"
        class="btn btn-error btn-sm appear-top-10 m-2"
      >
        Refuser
      </button>
      <router-link
        :to="{ name: RouteName.SessionDo, params: { id: $route.params.id } }"
        class="btn btn-wide btn-primary btn-xl appear-top-10"
      >
        Commencer
      </router-link>
    </div>
  </main>

  <Modal
    :isOpen="!!$route.params.description"
    :close="{ name: RouteName.WorkoutResume, params: { id: $route.params.id } }"
    :isLarge="true"
  >
    <h3 class="text-lg font-bold">{{ exercice?.title }}</h3>
    <ExerciceDescription
      :id="Number($route.params.description)"
    ></ExerciceDescription>
  </Modal>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { ExerciceModel } from "../../../../backend/src/types/Exercice";
import { SetStatus } from "../../../../backend/src/types/Set";
import { SetData } from "../../../../backend/src/types/Set";
import { useSportStore } from "../../store";
import Exercice from "../../components/exercices/Exercice.vue";
import ExerciceDescription from "../../components/ExerciceDescription.vue";
import Modal from "../../components/ui/Modal.vue";
import { RouteName } from "../../types/route";
import { useRoute, useRouter } from "vue-router";
import ObjectiveBadge from "../../components/set/ObjectiveBadge.vue";
import { getExercice, getExerciceFull } from "@/helpers/exercice";
import UseSequenceNotFound from "./UseSequenceNotFound";
import { rejectWorkout } from "@/services/api";

const sportStore = useSportStore();
const route = useRoute();
const router = useRouter();

UseSequenceNotFound();

const rejectSession = async () => {
  await rejectWorkout(Number(route.params.workout), sportStore);
  await router.push({
    name: RouteName.Session,
  });
};

const setResumeList = computed(() => {
  return sportStore.sets.sets
    .filter((set) => set.workout === Number(route.params.workout))
    .filter((set) => getExercice(set.exercice)?.model !== ExerciceModel.None);
});

const exercice = computed(() =>
  getExerciceFull(Number(route.params?.description))
);
</script>

<style lang="scss" scoped></style>
