<template>
  <div class="flex items-center space-x-3">
    <UserAvatar :src="avatar" :size="64" :rank="rank" :alt="nickname" />
    <div>
      <div class="font-bold text-base leading-4">
        {{ nickname }} <Emoji v-if="emoji" :src="emoji" class="ml-1" />
      </div>
      <div v-if="rank" class="text-xs opacity-60">Niveau {{ rank }}</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "@vue/reactivity";
import type { PropType } from "vue";
import { xpToRank } from "../../../../backend/src/helpers/user";
import UserAvatar from "./UserAvatar.vue";
import Emoji from "../ui/Emoji.vue";

const props = defineProps({
  experience: {
    type: Number as PropType<number | undefined>,
    default: undefined,
  },
  avatar: {
    type: String,
    default: "",
  },
  emoji: {
    type: String,
    default: "",
  },
  nickname: {
    type: String,
    default: "",
  },
});

const rank = computed(() => Math.floor(xpToRank(props.experience || 0)));
// const realExperience = computed(() => Math.floor(props.experience || 0))
</script>

<style lang="scss" scoped></style>
