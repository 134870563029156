<template>
  <div class="h-96" v-if="isKeyboardOpen"></div>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const isKeyboardOpen = ref(false);
const isMobile = "ontouchstart" in document.documentElement;

if (isMobile) {
  const onFocus = (event: FocusEvent) => {
    const target = event.target as HTMLElement | null;
    switch (target?.tagName) {
      case "INPUT":
        if ((target as unknown as { type: string })?.type === "checkbox") {
          return;
        }
        break;
      case "TEXTAREA":
      case "SELECT":
        isKeyboardOpen.value = true;
    }
  };

  const onBlur = () => {
    isKeyboardOpen.value = false;
  };

  onMounted(() => {
    document.body.addEventListener("focus", onFocus, true);
    document.body.addEventListener("blur", onBlur, true);
  });

  onUnmounted(() => {
    document.body.removeEventListener("focus", onFocus, true);
    document.body.removeEventListener("blur", onBlur, true);
  });

  watch(() => route.path, onBlur);
}
</script>

<style lang="scss" scoped></style>
