<template>
  <component v-if="exerciceTrad.description" :is="exerciceTrad.description" />
  <template v-else>
    <img
      :src="exerciceTrad.cover"
      loading="lazy"
      :alt="exerciceTrad.title"
      class="my-5"
    />
    <p>{{ exerciceTrad.resume }}</p>
  </template>
</template>

<script lang="ts" setup>
import { computed, type PropType } from "vue";
import { getExerciceTrad } from "@/helpers/exercice";
import type { ExerciceTrad } from "@/trads/fr/exercices";
import type { ExerciceId } from "../../../backend/src/types/Exercice";

const defaultExerciceTrad: ExerciceTrad = {
  id: 0,
  title: "Exercice not found",
  resume: "Exercice not found, please contact the administrator",
  cover: "not-found",
};

const props = defineProps<{ id: ExerciceId }>();

const exerciceTrad = computed(
  () => getExerciceTrad(props.id) || defaultExerciceTrad
);
</script>

<style lang="scss" scoped>
:deep(img) {
  max-width: 100%;
}
</style>
