import type { useSportStore } from "@/store";
import type { Payload } from "../../../backend/src/types/Payload";

export function doActions<T>(
  payload: Payload & T,
  store: ReturnType<typeof useSportStore>
) {
  if (!payload.success) {
    // if (!store.route || store.route.name !== "Login") {
    //   store.router.push({ name: RouteName.Login });
    // }
  } else {
    // if (!store.route || store.route.name === "Login") {
    //   store.router.push({ name: RouteName.Home });
    // }
    if (payload.sets) {
      store.sets.$patch({ sets: payload.sets });
    }
    if (payload.workouts) {
      store.sets.$patch({ workouts: payload.workouts });
    }
    if (payload.server.timestamp) {
      store.global.setServerTime(payload.server.timestamp);
    }
    if (payload.programs) {
      store.programs.$patch({ list: payload.programs });
    }
    if (payload.exercices) {
      store.exercices.$patch({ list: payload.exercices });
    }
    if (payload.me) {
      store.me.$patch(payload.me);
    }
    if (payload.events) {
      store.events.$patch({ list: payload.events });
    }
    if (payload.friends) {
      store.friends.$patch({ requests: payload.friends.requests });
    }
    if (payload.lootboxes) {
      store.lootboxes.$patch({ list: payload.lootboxes });
    }
    // if (store.route.name === "Login") {
    //   store.router.push({ name: RouteName.Home });
    // }
  }

  return payload;
}
