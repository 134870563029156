<template>
  <div
    class="badge"
    :class="{
      'badge-success': shade === 1,
      'badge-info': shade === 2,
      'badge-warning': shade === 3,
      'badge-error': shade === 4,
    }"
  >
    {{ name }}
  </div>

  <!-- <div class="star align-bottom">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 300 300"
      xml:space="preserve"
      class="w-7 h-7"
    >
      <path
        :class="{
          'fill-success': shade === 1,
          'fill-info': shade === 2,
          'fill-warning': shade === 3,
          'fill-error': shade === 4,
        }"
        d="M297.5,115.2c-2-6.2-7.4-10.8-13.9-11.7L202,91.6l-36.5-74c-2.9-5.9-8.9-9.6-15.4-9.6s-12.6,3.7-15.5,9.6L98,91.6 l-81.6,11.9c-6.5,0.9-11.9,5.5-13.9,11.7c-2,6.2-0.3,13.1,4.4,17.7l59.1,57.6l-14,81.3c-1.1,6.5,1.5,13,6.9,16.9 c5.3,3.9,12.4,4.4,18.2,1.3l73-38.4l73,38.4c5.9,3.1,12.9,2.5,18.2-1.3c5.3-3.9,8-10.4,6.9-16.9l-14-81.3l59.1-57.6
      C297.9,128.3,299.5,121.5,297.5,115.2z"
      />
      
    </svg>
    
    <span class="star-text">{{ value }}</span>
  </div> -->
</template>

<script lang="ts" setup>
import { computed } from "vue";

// function lerpColor(a: string, b: string, amount: number) {
//   const ah = parseInt(a.replace(/#/g, ''), 16)
//   const ar = ah >> 16, ag = ah >> 8 & 0xff, ab = ah & 0xff
//   const bh = parseInt(b.replace(/#/g, ''), 16)
//   const br = bh >> 16, bg = bh >> 8 & 0xff, bb = bh & 0xff
//   const rr = ar + amount * (br - ar)
//   const rg = ag + amount * (bg - ag)
//   const rb = ab + amount * (bb - ab)
//   return '#' + ((1 << 24) + (rr << 16) + (rg << 8) + rb | 0).toString(16).slice(1);
// }

const props = defineProps({
  value: {
    default: 0,
    type: Number,
  },
});

const LOOP_COUNT = 3;

const shade = computed(() => {
  return Math.ceil(props.value / LOOP_COUNT);
});

const name = computed(() => {
  return (
    String.fromCharCode(64 + shade.value) +
    (((props.value - 1) % LOOP_COUNT) + 1)
  );
});

// const color = computed(() => {
//   // const colors = ["#40b548", "#0560e2", "#ff8500", "#fc1f88", "#330000"];
//   // https://coolors.co/palettes/trending
//   // const colors = ["#4CC9F0", "#4895EF", "#4361EE", "#3F37C9", "#3A0CA3", "#480CA8", "#560BAD", "#7209B7", "#B5179E", "#F72585"];
//   // const colors = ["#f94144","#f3722c","#f8961e","#f9c74f","#90be6d","#43aa8b","#577590"].reverse()

//   const colors = [
//     "#e02938",
//     "#9336bb",
//     "#555be5",
//     "#3d73e5",
//     "#298ae5",
//     "#0dbbdc",
//     "#0eccc1",
//     "#1cdc97",
//     "#26e67b",
//   ].reverse();
//   return colors[Math.round(props.value - 1)]; // colors[Math.round((props.value - 1) * colors.length / 9)];
// });
</script>

<style lang="scss" scoped>
.star {
  display: inline-block;
  width: 28px;
  height: 30px;
  position: relative;
  vertical-align: text-bottom;

  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.star-text {
  position: absolute;
  font-size: 16px;
  padding-top: 0.15em;
  color: #fff;
}
.star-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
