import { useSportStore } from "@/store";
import { RouteName } from "@/types/route";
import { watch } from "vue";
import { useRoute, useRouter } from "vue-router";

export default () => {
  const sportStore = useSportStore();
  const route = useRoute();
  const router = useRouter();

  const getSets = () =>
    sportStore.sets.sets.filter(
      (set) => set.workout === Number(route.params.workout)
    );

  watch(
    () => sportStore.sets.sets.length > 0 && getSets().length < 1,
    (isEnded) => {
      if (isEnded && route.params.workout) {
        router.replace({
          name: RouteName.SessionResults,
          params: {
            workout: Number(route.params.workout),
          },
        });
      }
    },
    { immediate: true }
  );
};
