import { ProgramId } from "../../../../backend/src/types/Program";

export default [
  {
    id: ProgramId.FastStrength,
    title: "rapide et intense",
    resume: "Très brève et intensive séance de renforcement musculaire.",
    strength: 60,
    flexibility: 10,
    cardio: 20,
    duration: 10,
    cover: "/img/program/program-bicycle.jpg",
  },
  {
    id: ProgramId.MiddleStrength,
    title: "renforcement sérieux",
    resume: "Courte et intensive séance de renforcement musculaire.",
    strength: 60,
    flexibility: 10,
    cardio: 30,
    duration: 20,
    cover: "/img/program/program-cardio.jpg",
  },
  {
    id: ProgramId.Custom,
    title: "sur mesure",
    resume:
      "Séance à créer, exercice par exercice, et à partager avec ses amis.",
    strength: -1,
    flexibility: -1,
    cardio: -1,
    duration: -1,
    cover: "/img/program/program-climber.jpg",
  },
  {
    id: ProgramId.Challenge,
    title: "défi",
    resume: "Défi créé par {author}.",
    strength: -1,
    flexibility: -1,
    cardio: -1,
    duration: -1,
    cover: "/img/program/program-crunch.jpg",
  },
];
