import type { Program } from "../../../backend/src/types/Program";
import { defineStore } from "pinia";

export type ProgramsState = { list: Array<Program> };

export const useProgramsStore = defineStore({
  // name of the store
  // it is used in devtools and allows restoring state
  id: "programs",

  // a function that returns a fresh state
  state: () => ({
    list: [] as Program[],
  }),

  // optional getters
  getters: {
    // doubleCount() {
    //   return this.counter * 2
    // },
    // // use getters in other getters
    // doubleCountPlusOne() {
    //   return this.doubleCount * 2 + 1
    // },
  },

  // optional actions
  actions: {
    // reset() {
    //   // `this` is the store instance
    //   this.counter = 0
    // },
  },
});
