<template>
  <div
    v-if="event && eventTrad"
    class="fixed bottom-0 left-0 right-0 top-0 overflow-hidden bg-gradient-to-tr from-[#000000B0] to-[#000000E0] z-50"
  >
    <img
      v-if="character === EventCharacter.ChunLee"
      class="h-[125%] absolute bottom-0 right-0 translate-x-[30%] translate-y-[30%]"
      src="../assets/img/characters/chun-lee.png"
      alt="Chun Lee"
    />
    <img
      v-else-if="character === EventCharacter.Faith"
      class="h-[125%] absolute bottom-0 right-0 translate-x-[0%] translate-y-[30%]"
      src="../assets/img/characters/mirrors-edge-faith-connors.png"
      alt="Faith Connors"
    />
    <img
      v-else-if="character === EventCharacter.Male1"
      class="h-[140%] absolute bottom-0 right-0 translate-x-[5%] translate-y-[30%]"
      src="../assets/img/characters/mirrors-edge-male.png"
      alt="Male"
    />

    <div
      class="absolute left-[5%] right-[5%] bottom-[5vw] text-lg p-4 bg-neutral-content rounded-3xl sm:left-[10%] sm:right-[10%] sm:bottom-[10vw]"
    >
      <div v-html="message" class="text-neutral"></div>

      <div class="text-right">
        <button
          v-for="(cta, index) of eventTrad.ctas"
          @click="
            event &&
              cta.callback({
                router: $router,
                event: event as never,
                store,
                currentEventID: event.id,
              })
          "
          :key="message + index"
          class="btn mt-4 ml-4"
          :class="{
            'btn-success': cta.display === EventListCtaDisplay.Positive,
            'btn-error': cta.display === EventListCtaDisplay.Negative,
            'btn-accent': cta.display === EventListCtaDisplay.Important,
            'btn-info': cta.display === EventListCtaDisplay.Neutral,
          }"
        >
          {{ cta.label }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import eventList, {
  EventCharacter,
  EventListCtaDisplay,
} from "../trads/fr/eventList";
import { useSportStore } from "../store";
import { useRouter } from "vue-router";

const store = useSportStore();
const router = useRouter();
const event = computed(() => store.events.displayed);
const eventTrad = computed(() =>
  eventList.find((ev) => ev.name === store.events.displayed?.name)
);

const character = computed(() => eventTrad.value?.character);
const message = computed(
  () =>
    eventTrad.value?.message({
      router,
      event: event.value as never,
      store,
      currentEventID: Number(event.value?.id),
    }) || ""
);
</script>

<style scoped lang="scss"></style>
