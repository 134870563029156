<template>
  <main
    v-if="$route.name === RouteName.Session"
    class="page-container flex-1 flex flex-col justify-center gap-5"
  >
    <template
      v-for="({ workouts, program }, i) of setsStore.setsByProgramsWorkouts"
      :key="program"
    >
      <template v-for="{ workout } of workouts" :key="workout">
        <Workout
          :program="program"
          :workout="workout"
          :class="`appear-top-${Math.min(i + 1, 10)}`"
        >
          <router-link
            :to="{
              name: RouteName.WorkoutResume,
              params: {
                workout: workout,
              },
            }"
            class="btn btn-wide btn-primary"
          >
            Voir
          </router-link>
        </Workout>
      </template>
    </template>

    <Workout
      v-if="isCustomEnabled"
      :program="ProgramId.Custom"
      :workout="-1"
      :class="`appear-top-${Math.min(
        setsStore.setsByProgramsWorkouts.length + 1,
        10
      )}`"
    >
      <router-link
        :to="{
          name: RouteName.WorkoutCustom,
        }"
        class="btn btn-wide btn-secondary"
      >
        Créer
      </router-link>
    </Workout>

    <p v-if="!hasSubscribe" class="empty-message">
      Inscrit toi à un
      <router-link :to="{ name: RouteName.Programs }"
        >programme d'entrainement</router-link
      >
      pour qu'on te propose des exercices.
    </p>
  </main>
  <RouterView v-else />
</template>

<script lang="ts" setup>
import { computed, watch } from "vue";
import { useSetsStore } from "../../store/setsStore";
import { useProgramsStore } from "../../store/programsStore";
import { RouteName } from "../../types/route";
import { checkOvertime } from "../../helpers/set";
import { useSportStore } from "../../store";
import { useRouter } from "vue-router";
import { SetStatus } from "../../../../backend/src/types/Set";
import { useServerDate } from "../../services/time";
import Workout from "../../components/set/Workout.vue";
import { ProgramId } from "../../../../backend/src/types/Program";

const setsStore = useSetsStore();
const sportStore = useSportStore();
const router = useRouter();
const programsStore = useProgramsStore();
const serverDate = useServerDate();

const hasSubscribe = computed(
  () =>
    programsStore.$state.list.filter((program) => program.enabled).length > 0
);
const isCustomEnabled = computed(
  () =>
    programsStore.$state.list.find((program) => program.id === ProgramId.Custom)
      ?.enabled
);

watch(
  () => `${setsStore.sets}-${setsStore.workouts}`,
  () => {
    setsStore.setsByProgramsWorkouts.forEach(({ workouts }) => {
      workouts.forEach(({ sets }) => {
        const more = sets.reduceRight(
          (val, set) =>
            val
              ? val
              : set.status === SetStatus.Done
              ? serverDate().getTime() - new Date(set.doneDate || "").getTime()
              : 0,
          0
        );
        checkOvertime({ sets, router, sportStore, more });
      });
    });
  },
  { immediate: true }
);
</script>

<style lang="scss" scoped>
::v-deep(.steps .step) {
  min-width: auto;
}

::v-deep(.steps .step[data-content]:after) {
  color: #fff;
}
</style>
