<template>
  <teleport to="body" v-if="isOpen">
    <!-- <router-link
      v-if="close"
      :to="close"
      class="fixed bg-base-200 text-base-content top-0 left-0 w-screen h-screen cursor-pointer opacity-[0.98] z-40"
    ></router-link>

    <div
      v-else
      class="fixed bg-base-200 text-base-content top-0 left-0 w-screen h-screen cursor-pointer opacity-[0.98] z-40"
    ></div> -->

    <input :checked="isOpenDelay" type="checkbox" class="modal-toggle" />
    <div class="modal">
      <router-link
        v-if="close"
        :to="close"
        class="absolute top-0 left-0 w-full h-full"
      ></router-link>
      <div class="modal-box relative" :class="{ 'w-11/12 max-w-5xl': isLarge }">
        <router-link
          v-if="close"
          :to="close"
          for="my-modal-3"
          class="btn btn-sm btn-circle absolute right-2 top-2"
          >✕</router-link
        >
        <!-- <Header v-if="close" :next="close">
          {{ title }}
        </Header> -->
        <!-- <div class="relative mx-5 py-10 flex flex-col flex-1"> -->
        <slot></slot>
        <!-- </div> -->
      </div>
    </div>
  </teleport>
</template>

<script lang="ts" setup>
import { onUnmounted, ref, watch } from "vue";
import type { PropType } from "vue";
import type { RouteLocationRaw } from "vue-router";

const props = defineProps({
  close: {
    type: Object as PropType<RouteLocationRaw | null>,
    default: null,
  },
  isOpen: {
    type: [Boolean, null] as PropType<boolean | null>,
    default: null,
  },
  isLarge: {
    type: Boolean,
    default: false,
  },
});

const isOpenDelay = ref(props.isOpen || false);

watch(
  () => props.isOpen,
  () => {
    if (props.isOpen) {
      requestAnimationFrame(() => (isOpenDelay.value = true));
      document.body.classList.add("overflow-y-hidden");
    } else {
      isOpenDelay.value = false;
      document.body.classList.remove("overflow-y-hidden");
    }
  }
);

onUnmounted(() => {
  document.body.classList.remove("overflow-y-hidden");
});

// const isOpenPrivate = ref(false);
// const isOpenFinal = computed(() =>
//   props.isOpen !== null ? props.isOpen : isOpenPrivate.value);

// const testHash = () => {
//   isOpenPrivate.value = location.hash === "#" + props.hashName;
// };

// onMounted(() => {
//   window.addEventListener("hashchange", testHash, false);
// });

// onUnmounted(() => {
//   window.removeEventListener("hashchange", testHash);
// });

// testHash();

// const close = () => {
//   if (props.isOpen !== null) {
//     emit('close')
//   } else {
//     window.location.hash = ``
//   }
// };
</script>

<style lang="scss" scoped>
:deep(img) {
  max-width: 100%;
}
</style>
