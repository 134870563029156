import { defineStore } from "pinia";
import { xpToRank } from "../../../backend/src/helpers/user";

export type UserState = {
  email: string;
  nickname: string;
  firstname: string;
  lastname: string;
  avatar: string;
  emoji: string;
  experience: number;
  points: {
    month: number;
    week: number;
    day: number;
  };
};

export const useMeStore = defineStore({
  // name of the store
  // it is used in devtools and allows restoring state
  id: "me",

  // a function that returns a fresh state
  state: (): UserState => ({
    email: "",
    nickname: "",
    firstname: "",
    lastname: "",
    avatar: "",
    emoji: "",
    experience: 0,
    points: {
      month: 0,
      week: 0,
      day: 0,
    },
  }),

  // optional getters
  getters: {
    rank(): number {
      return xpToRank(this.experience);
    },
    // doubleCount() {
    //   return this.counter * 2
    // },
    // // use getters in other getters
    // doubleCountPlusOne() {
    //   return this.doubleCount * 2 + 1
    // },
  },

  // optional actions
  actions: {
    disconnect() {
      this.$patch({
        email: "",
        nickname: "",
        firstname: "",
        lastname: "",
        avatar: "",
        emoji: "",
        experience: 0,
        points: {
          month: 0,
          week: 0,
          day: 0,
        },
      });
    },
    // setMe(me: User) {
    //   this.email = me.email;
    //   this.nickname = me.nickname;
    //   this.firstname = me.firstname;
    //   this.lastname = me.lastname;
    //   this.description = me.description;
    // },
    // reset() {
    //   // `this` is the store instance
    //   this.counter = 0
    // },
  },
});
