import { useSetsStore } from "./setsStore";
import { useMeStore } from "./meStore";
import { useProgramsStore } from "./programsStore";
import { useRoute, useRouter } from "vue-router";
import { useEventsStore } from "./eventsStore";
import { useGlobalStore } from "./globalStore";
import { useFriendsStore } from "./friendsStore";
import { useExercicesStore } from "./exercicesStore";
import { useLootboxesStore } from "./lootboxStore";

export function useSportStore() {
  return {
    events: useEventsStore(),
    global: useGlobalStore(),
    router: useRouter(),
    route: useRoute(),
    sets: useSetsStore(),
    friends: useFriendsStore(),
    me: useMeStore(),
    programs: useProgramsStore(),
    exercices: useExercicesStore(),
    lootboxes: useLootboxesStore(),
  };
}
