import { mli } from "./curve"

export const enum BonusMultiplicators {
  Min = 0,
  Goal = 1,
  Great = 1.5,
  Impressive = 3
}

export const enum Bonus {
  Min = 0,
  Goal = 10,
  Great = 20,
  Impressive = 30
}

export const calculateGoalBonus = (bonus: number) => {
  if (bonus >= Bonus.Impressive) {
    return Bonus.Impressive
  } else if (bonus >= Bonus.Great) {
    return Bonus.Great
  } else if (bonus >= Bonus.Goal) {
    return Bonus.Goal
  } else {
    return Bonus.Min
  }
}

export const calculateBonusFromValue = (value: number, goal: number) =>
  mli({
    x: value,
    min: Bonus.Min,
    max: Bonus.Impressive,
    steps: [
      { x: goal * BonusMultiplicators.Min, y: Bonus.Min },
      { x: goal * BonusMultiplicators.Goal, y: Bonus.Goal },
      { x: goal * BonusMultiplicators.Great, y: Bonus.Great },
      { x: goal * BonusMultiplicators.Impressive, y: Bonus.Impressive },
    ]
  }) || 0
