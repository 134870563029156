<template>
  <main class="page-container gap-8 flex flex-col">

    <div class="card bg-neutral text-neutral-content rounded-box place-items-center">
      <div class="card-body w-full">

        <p v-if="!list.length" class="m-3 appear-top-1">
          Crée une séance avant de la démarrer.
        </p>
        <template v-else>
          <h2 class="title-2">
            {{ realExercices.length }} exercice{{ realExercices.length > 1 ? 's' : '' }}
          </h2>
        </template>

        <template v-for="(set, i) of list" :key="i">
    
          <Exercice
            v-if="set.type !== SetType.Pause"
            :id="set.exercice"
          >
            <template v-if="set.data" v-slot:title-after>
              <span v-if="set.data === SetData.Left"> (gauche)</span>
              <span v-else-if="set.data === SetData.Right"> (droite)</span>
            </template>
    
            <template v-slot:secondary>
              <div class="inline-flex justify-end w-full items-center gap-2">
                <select v-model="set.type" class="select select-sm select-primary">
                  <option :value="SetType.Recovery">
                    Tranquille
                  </option>
                  <option :value="SetType.Decrease">
                    Normal
                  </option>
                  <option :value="SetType.Highscore">
                    Maximum
                  </option>
                  <option :value="SetType.Increase">
                    Dépassement de soi
                  </option>
                  <option :value="SetType.Limit">
                    Au dela des limites
                  </option>
                </select>
                <label class="label" for="difficulty">
                  <span class="label-text cursor-pointer">
                    <sup>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        class="stroke-base-content w-5 h-5 inline-block"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                      </svg>
                    </sup>
                  </span>
                </label>
                <!-- <ObjectiveBadge :set="set"></ObjectiveBadge> -->
              </div>
            </template>
    
            <div class="flex flex-row items-center gap-2">
              <router-link
                :to="{
                  name: RouteName.WorkoutCustom,
                  params: { description: set.exercice },
                }"
                class="btn btn-outline btn-info btn-sm"
              >
                description
              </router-link>
            </div>
              <button 
                class="btn btn-outline btn-error btn-sm"
                @click="removeSet(set)"
              >
                retirer
              </button>
          </Exercice>
    
          <div v-else class="divider">
            <label class="input-group input-group-xs w-auto">
              <span>Pause</span>
              <input v-model="set.time" type="number" step="5" min="0" placeholder="time" class="input input-bordered text-right w-16 input-xs" />
              <span>sec</span>
            </label>
          </div>
    
        </template>

        <div class="text-right mt-5">
          <select v-model="selectedExercice" class="select select-primary">
            <option :value="0" disabled>Ajouter un exercice</option>
            <option :value="exercice.id" v-for="exercice of exercices" :key="exercice.id">
              {{ exercice.title }}
            </option>
          </select>
        </div>

      </div>
    </div>


    <div v-if="list.length" class="card bg-neutral text-neutral-content rounded-box place-items-center">
      <div class="card-body w-full">
        <div class="text-center flex flex-col gap-5">
          <ShareFriends v-model="friends"></ShareFriends>
        </div>
      </div>
    </div>


    <div class="text-center">
      <button
        class="btn btn-wide btn-primary btn-xl appear-top-10"
        @click="generate"
      >
        Valider la séance
      </button>
    </div>

  </main>

  <Modal
    :isOpen="!!$route.params.description"
    :close="{ name: RouteName.WorkoutCustom }"
    :isLarge="true"
  >
    <h3 class="text-lg font-bold">{{ exercice?.title }}</h3>
    <ExerciceDescription
      :id="Number($route.params.description)"
    ></ExerciceDescription>
  </Modal>

  <Teleport to="body">
    <input type="checkbox" id="difficulty" class="modal-toggle" />
    <label for="difficulty" class="modal cursor-pointer">
      <label class="modal-box relative" for="">
        <label
          for="difficulty"
          class="btn btn-sm btn-circle absolute right-2 top-2"
          >✕</label
        >
        <p class="py-4">
          <em class="block mb-4">
            Le niveau de difficulté d'un exercice est calculé par rapport à votre record sur cet exercice.
          </em>
          <ul>
            <li class="mb-4">
              <h2 class="text-xl">Tranquille</h2>
              La moitié du maximum que vous arrivez à faire sur cet exercice.
              C'est léger et permet de se chauffer.
            </li>
            <li class="mb-4">
              <h2 class="text-xl">Normal</h2>
              80% du maximum que vous arrivez à faire sur cet exercice.
              Cela peut être fait quotidiennement sans sursolliter les muscles.
            </li>
            <li class="mb-4">
              <h2 class="text-xl">Maximum</h2>
              Maximum que vous arrivez à faire sur cet exercice.
            </li>
            <li class="mb-4">
              <h2 class="text-xl">Dépassement de soi</h2>
              110% de votre maximum sur cet exercice.
              Cela pousse à se dépasser.
            </li>
            <li>
              <h2 class="text-xl">Au dela des limites</h2>
              Pousse à établir un nouveau record.
              Il n'y a pas de limites et il faut faire le maximum jusqu'à ne plus avoir d'énergie.
            </li>
          </ul>
        </p>
      </label>
    </label>
  </Teleport>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { ExerciceId } from "../../../../backend/src/types/Exercice";
import { SetType, type SetAbstract, type SetAbstractExercice, SetData } from "../../../../backend/src/types/Set";
import Exercice from "../../components/exercices/Exercice.vue";
import ExerciceDescription from "../../components/ExerciceDescription.vue";
import Modal from "../../components/ui/Modal.vue";
import { RouteName } from "../../types/route";
import { useRoute } from "vue-router";
import { getExercice, getExerciceFull } from "@/helpers/exercice";
import { createWorkout, refresh } from "@/services/api";
import { useExercicesStore } from "@/store/exercicesStore";
import { isModelLeftRight } from "../../../../backend/src/helpers/exercices";
import type { Friend } from "../../../../backend/src/types/Friend";
import ShareFriends from "./ShareFriends.vue";
import { useSportStore } from "@/store";
import router from "@/router";
import { getProgramStats } from "@/helpers/workout";

const route = useRoute();
const exercicesStore = useExercicesStore();
const friends = ref<Friend[]>([])
const sportStore = useSportStore()

const selectedExercice = ref(0)
const exercices = computed(() => exercicesStore.fullExercices)
const list = ref<SetAbstract[]>([])

// const isPause = (exerciceID: ExerciceId) => {
//   return getExercice(exerciceID)?.model === ExerciceModel.None
// }

const addSet = (exerciceID: ExerciceId) => {

  if (list.value.length) {
    list.value.push({
      exercice: ExerciceId.Pause,
      type: SetType.Pause,
      time: 20
    })
  }
  const data = isModelLeftRight(getExercice(exerciceID)?.model) ? SetData.Left : undefined
  list.value.push({
    exercice: exerciceID,
    type: SetType.Decrease,
    data
  })

  if (data) {
    list.value.push({
      exercice: ExerciceId.Pause,
      type: SetType.Pause,
      time: 20
    })
    list.value.push({
      exercice: exerciceID,
      type: SetType.Decrease,
      data: SetData.Right
    })
  }
}

const removeSet = (set: SetAbstractExercice) => {
  const index1 = list.value.findIndex(s => s === set)
  if (index1 > -1) {
    list.value.splice(index1, 1)
    list.value.splice(index1 > 0 ? index1 - 1 : 0, 1)
    if (set.data) {
      const otherData = set.data === SetData.Left ? SetData.Right : SetData.Left
      const index2 = list.value.findIndex(s => s.exercice === set.exercice && s.type === set.type && s.data === otherData)
      list.value.splice(index2, 1)
      list.value.splice(index2 > 0 ? index2 - 1 : 0, 1)
    }
  }
}

const realExercices = computed(() => list.value
  .filter((set) => set.exercice !== ExerciceId.Pause &&
    ((set as SetAbstractExercice).data === undefined || (set as SetAbstractExercice).data === SetData.Left)))

watch(selectedExercice, (exerciceID: ExerciceId | 0) => {
  if(exerciceID) {
    addSet(exerciceID)
    selectedExercice.value = 0
  }
})

const generate = async () => {
  const workout = await createWorkout(list.value, friends.value.map(({ nickname }) => nickname))
  await refresh(sportStore)
  router.push({
    name: RouteName.WorkoutResume,
    params: { workout }
  })
}

const exercice = computed(() =>
  getExerciceFull(Number(route.params?.description))
);

</script>

<style lang="scss" scoped></style>
