export const enum RouteName {
  Home = "Home",
  Login = "Login",
  Session = "Session",
  WorkoutResume = "WorkoutResume",
  WorkoutCustom = "WorkoutCustom",
  SessionResults = "SessionResults",
  SessionDo = "SessionDo",
  Exercice = "Exercice",
  Ranking = "Ranking",
  History = "History",
  Options = "Options",
  Profile = "Profile",
  Friends = "Friends",
  Avatars = "Avatars",
  Emojis = "Emojis",
  Equipments = "Equipments",
  Programs = "Programs",
  Exercices = "Exercices",
  Lootbox = "Lootbox",
  Notifications = "Notifications",
  Subscribe = "Subscribe",
  Graphic = "Graphic",
  Admin = "Admin",
  AdminSponsor = "AdminSponsor",
  AdminSeries = "AdminSeries",
  AdminExercices = "AdminExercices",
  AdminExercicesTree = "AdminExercicesTree",
  AdminExercicesSkills = "AdminExercicesSkills",
  AdminExercicesReps = "AdminExercicesReps",
  AdminExerciceSimilarity = "AdminExerciceSimilarity",
  AdminExerciceUnlock = "AdminExerciceUnlock",
  AdminExerciceSearch = "AdminExerciceSearch",
  AdminExerciceTry = "AdminExerciceTry",
  AdminStyle = "AdminStyle",
  AdminAvatars = "AdminAvatars",
}

export const enum Roles {
  Public,
  User,
  Admin,
}
