<template>
  <TransitionGroup name="notif" tag="ul" class="toast toast-end">
    <li
      class="alert bg-neutral min-w-[50vw]"
      v-for="notif of apiNotif.$state.list"
      :key="notif.id"
    >
      <div>
        <svg
          v-if="notif.state === 'success'"
          xmlns="http://www.w3.org/2000/svg"
          class="stroke-success flex-shrink-0 h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <svg
          v-if="notif.state === 'progress'"
          xmlns="http://www.w3.org/2000/svg"
          class="stroke-info flex-shrink-0 h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <svg
          v-if="notif.state === 'fail'"
          xmlns="http://www.w3.org/2000/svg"
          class="stroke-error flex-shrink-0 h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <span class="text-neutral-content">
          {{ notif.messages[notif.state] }}
        </span>
      </div>
    </li>
  </TransitionGroup>
</template>

<script lang="ts" setup>
import { useToastStore } from "@/store/apiToastStore";

const apiNotif = useToastStore();
</script>

<style scoped lang="scss">
.notif-move, /* apply transition to moving elements */
.notif-enter-active,
.notif-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}

// .notif-enter-from,
.notif-leave-to {
  opacity: 0;
  // transform: translateY(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.notif-leave-active {
  // position: absolute;
}
</style>
