<template>
  <svg
    ref="star"
    :class="{ [color]: true, 'is-animated': animated }"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 300 300"
    xml:space="preserve"
    :width="width"
    :height="height"
    alt="Étoile"
  >
    <path
      d="M297.5,115.2c-2-6.2-7.4-10.8-13.9-11.7L202,91.6l-36.5-74c-2.9-5.9-8.9-9.6-15.4-9.6s-12.6,3.7-15.5,9.6L98,91.6 l-81.6,11.9c-6.5,0.9-11.9,5.5-13.9,11.7c-2,6.2-0.3,13.1,4.4,17.7l59.1,57.6l-14,81.3c-1.1,6.5,1.5,13,6.9,16.9 c5.3,3.9,12.4,4.4,18.2,1.3l73-38.4l73,38.4c5.9,3.1,12.9,2.5,18.2-1.3c5.3-3.9,8-10.4,6.9-16.9l-14-81.3l59.1-57.6
    C297.9,128.3,299.5,121.5,297.5,115.2z"
    />
  </svg>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import {
  Bonus,
  calculateGoalBonus,
} from "../../../../backend/src/helpers/bonus";

const props = defineProps({
  bonus: {
    type: Number,
    default: 0,
  },
  animated: {
    type: Boolean,
    default: true,
  },
  width: {
    type: String,
    default: "16px",
  },
  height: {
    type: String,
    default: "16px",
  },
});

const colors = {
  [Bonus.Min]: "grey",
  [Bonus.Goal]: "bronze",
  [Bonus.Great]: "silver",
  [Bonus.Impressive]: "gold",
};

const color = computed(() => colors[calculateGoalBonus(props.bonus)]);

const star = ref<SVGElement | null>(null);

onMounted(() => {
  if (star.value) {
    star.value.setAttribute(
      "style",
      `animation-delay: ${(Math.random() * 6).toFixed(2)}s;`
    );
  }
});
</script>

<style lang="scss" scoped>
.grey {
  fill: #333c4d;
}

.bronze {
  fill: goldenrod;
}

.silver {
  fill: deeppink;
  // filter: drop-shadow( 0 0 3px rgb(251, 255, 0));
}

.gold {
  fill: rgb(255, 255, 255);
  filter: drop-shadow(0 0 4px white) drop-shadow(0 0 8px deeppink);
  &.is-animated {
    animation: spin 5s infinite;
  }
}

// @for $i from 1 through 3 {
//   .gold:nth-child(#{$i}n) {
//     animation-delay: #{$i * 0.15}s;
//   }
// }

@keyframes spin {
  0% {
    transform: rotate(-72deg);
  }
  10% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>
