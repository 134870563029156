import { createApp } from "vue";
import { createPinia } from "pinia";
import "./style/index.scss";
import App from "./App.vue";
import router from "./router";
import Loader from "./components/Loader.vue";
import Worker from "./worker?worker"; // &inline
import { updateTheme } from "./helpers/theme";

updateTheme();

createApp(App)
  .use(router)
  .component("Loader", Loader)
  .use(createPinia())
  .mount("#app");

if (window.Worker) {
  new Worker();
  // worker.postMessage('test')
}
