export const enum ExerciceLink {
  Harder = "HARDER",
  Level = "LEVEL",
}

export const enum ExerciceModifier {
  Banned = "BANNED",
}

export const enum ExerciceId {
  ArcherPushUps = 1,
  Sprawls = 2,
  ChairDips = 3,
  ChinUp = 4,
  DiamondPushUps = 5,
  GluteBridge = 6,
  GoodMorning = 7,
  HipThrust = 8,
  InvertedRows = 9,
  JumpingJack = 10,
  KneeDiamondPushUps = 11,
  KneePushUps = 12,
  LyingReversePlank = 13,
  MountainClimber = 14,
  OneArmPushUps = 15,
  Pause = 16,
  Plank = 17,
  PushUps = 18,
  ReverseCrunch = 19,
  ReversePlank = 20,
  Running = 21,
  SeatedOverheadDumbbellTriceps = 22,
  SidePlank = 23,
  SidePlankLift = 24,
  SingleLegHipThrust = 25,
  SplitSquat = 26,
  Squats = 27,
  SupermanA = 28,
  SupermanHandsNeck = 29,
  SupermanLegOnly = 30,
  SupermanW = 31,
  SupermanY = 32,
  WallPushUps = 33,
  WideDeclinePushUps = 34,
  WideKneePushUp = 35,
  WidePullUps = 36,
  WidePushUps = 37,
  ProneSnowAngel = 38,
  PistolSquat = 39,
  BulgarianSplitSquat = 40,
  Crunch = 42,
  ChairDipsKneeBent = 43,
  HighKneeRun = 45,
  HangingKneeRaises = 46,
  ElevatedPushUps = 47,
  SquatJump = 48,
  WallSit = 49,
  ChairPistolSquat = 50,
  HinduPushUps = 52,
  DiveBomberPushUps = 53,
  SlidingFloorPullup = 54,
  WallPlank = 55,
}

export type ExerciceRow = {
  ex_id: number;
  ex_model: string;

  ex_value: number;
  ex_variants_group: number | null;
  ex_bonus: string;
  ex_time: number;
  ex_level: number;
  ex_difficulty: number;
  ex_condition: string;

  exh_2_id?: number;
  exh_condition?: string;
  exh_link?: ExerciceLink;
  ex_enable: boolean;

  exis_name?: ExerciceSubject;
  exia_name?: ExerciceAlter;
  exi_value?: number;
};

export type ExerciceInfo = {
  subject: ExerciceSubject;
  alter: ExerciceAlter;
  value: number;
}

export interface ExerciceSmall {
  id: number;
  model: ExerciceModel;
  value: number;
  time: number;
  level: number;

  infos: ExerciceInfo[];
  // links: { name: string /* exercice more hard */, condition: string, replace: boolean }[];
};

export interface Exercice extends ExerciceSmall {
  variantsGroup: number | null;
  difficulty: number;
  enable: boolean;
  condition: string;
  links: { id: number /* exercice more hard */, condition: string, replace: boolean }[];
};

export const enum ExerciceModel {
  Reps = "REPS",
  RepsLeftRight = "REPS-LR",
  RepsAlter = "REPS-ALTER",
  Isometric = "ISO",
  IsometricLeftRight = "ISO-LR",
  Run = "RUN",
  None = "NONE",
}

export const exerciceModels = [
  ExerciceModel.Reps,
  ExerciceModel.RepsAlter,
  ExerciceModel.RepsLeftRight,
  ExerciceModel.Isometric,
  ExerciceModel.IsometricLeftRight,
  ExerciceModel.Run,
  ExerciceModel.None
]

export const enum ExerciceSubject {
  MuscleGlobal = "global",

  MuscleUpperPectoralisMajor = "upper-pectoralis-major",
  MuscleLowerPectoralisMajor = "lower-pectoralis-major",
  MuscleNeck = "neck",
  MuscleTrapezius = "trapezius",
  MuscleRhomboids = "rhomboids",
  MuscleInfraspinatus = "infraspinatus",
  MusclePosteriorDeltoid = "posterior-deltoid",
  MuscleAnteriorDeltoid = "anterior-deltoid",
  MuscleLateralDeltoid = "lateral-deltoid",
  MuscleBicep = "bicep",
  MuscleTriceps = "triceps",
  MuscleForeArms = "fore-arms",
  MuscleLatissimusDorsi = "latissimus-dorsi",
  MuscleOblique = "oblique",
  MuscleUpperAbdominals = "upper-abdominals",
  MuscleLowerAbdominals = "lower-abdominals",
  MuscleQuadriceps = "quadriceps",
  MuscleGlutes = "glutes",
  MuscleHamstring = "hamstring",
  MuscleAdductor = "adductor",
  MuscleIliotibialTract = "iliotibial-tract",
  MuscleCalves = "calves",
  MuscleLumbar = "lumbar",

  Material = "material",

  Family = "family",
}

export const exerciceSubjectMuscles = [
  ExerciceSubject.MuscleGlobal,
  ExerciceSubject.MuscleUpperPectoralisMajor,
  ExerciceSubject.MuscleLowerPectoralisMajor,
  ExerciceSubject.MuscleNeck,
  ExerciceSubject.MuscleTrapezius,
  ExerciceSubject.MuscleRhomboids,
  ExerciceSubject.MuscleInfraspinatus,
  ExerciceSubject.MusclePosteriorDeltoid,
  ExerciceSubject.MuscleAnteriorDeltoid,
  ExerciceSubject.MuscleLateralDeltoid,
  ExerciceSubject.MuscleBicep,
  ExerciceSubject.MuscleTriceps,
  ExerciceSubject.MuscleForeArms,
  ExerciceSubject.MuscleLatissimusDorsi,
  ExerciceSubject.MuscleOblique,
  ExerciceSubject.MuscleUpperAbdominals,
  ExerciceSubject.MuscleLowerAbdominals,
  ExerciceSubject.MuscleQuadriceps,
  ExerciceSubject.MuscleGlutes,
  ExerciceSubject.MuscleHamstring,
  ExerciceSubject.MuscleAdductor,
  ExerciceSubject.MuscleIliotibialTract,
  ExerciceSubject.MuscleCalves,
  ExerciceSubject.MuscleLumbar,
]

export const enum ExerciceAlter {
  WorkAerobic = "aerobic",  // endurance
  // WorkAnaerobic = "anaerobic", // force
  WorkFlexibility = "flexibility", // souplesse
  WorkRelaxation = "relaxation", // relaxation
  WorkStrength = "strength", // force
  WorkPause = "pause", // repos
  WorkIsometric = "isometric",
  WorkInterval = "interval",

  FamilyWorkout = "workout",
  FamilyYoga = "yoga",
  FamilyMartialArt = "martial-art",
  FamilyPilates = "pilates",

  MaterialFixedBar = "fixed-bar",
  MaterialLargeFixedBar = "large-fixed-bar",
  MaterialRunningShoe = "running-shoe",
}

export const exerciceAlterWork = [
  ExerciceAlter.WorkAerobic,
  ExerciceAlter.WorkFlexibility,
  ExerciceAlter.WorkStrength,
  ExerciceAlter.WorkIsometric,
  ExerciceAlter.WorkInterval,
  ExerciceAlter.WorkPause,
]

export const exerciceAlterFamily = [
  ExerciceAlter.FamilyWorkout,
  ExerciceAlter.FamilyYoga,
  ExerciceAlter.FamilyMartialArt,
  ExerciceAlter.FamilyPilates
]

export const exerciceAlterMaterial = [
  ExerciceAlter.MaterialFixedBar,
  ExerciceAlter.MaterialLargeFixedBar,
  ExerciceAlter.MaterialRunningShoe
]
